import React from 'react';

import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

import { PopupState as PopupStateCore } from 'material-ui-popup-state/core';
import { withAccess } from 'presentation/hooks/access';
import { Container } from './styles/StyledPopover';

const CssPopover = withStyles({
  root: {
    '& .MuiPopover-paper': {
      // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px 0px 8px 8px',
    },
  },
})(Popover);

interface ownProps {
  children: JSX.Element;
  content: JSX.Element;
  elevation?: number;
  originX?: 'left' | 'center' | 'right';
  originY?: 'top' | 'center' | 'bottom';
  positionX?: 'left' | 'center' | 'right';
  positionY?: 'top' | 'center' | 'bottom';
  closePopoverWhenOptionClicked?: boolean;
}

export interface PopoverPopupStateRef extends HTMLDivElement {
  close: () => void;
}

const PopoverPopupState = React.forwardRef<PopoverPopupStateRef, ownProps>(
  (
    {
      children,
      content,
      elevation = 0,
      originX = 'center',
      originY = 'bottom',
      positionX = 'center',
      positionY = 'top',
      closePopoverWhenOptionClicked = true,
    },
    ref,
  ): JSX.Element => {
    return (
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState: PopupStateCore) => {
          return (
            <div>
              <Container {...bindTrigger(popupState)} ref={ref}>
                {children}
              </Container>
              <CssPopover
                style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: originY,
                  horizontal: originX,
                }}
                transformOrigin={{
                  vertical: positionY,
                  horizontal: positionX,
                }}
                elevation={elevation}
              >
                {React.cloneElement(content, {
                  onClick: closePopoverWhenOptionClicked
                    ? popupState.close
                    : undefined,
                })}
              </CssPopover>
            </div>
          );
        }}
      </PopupState>
    );
  },
);

export default withAccess(PopoverPopupState);
