import { theme } from '@wisecare-tech/design-system-web';
import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 586px;
  border: 1px solid #dedede;
  background-color: #ffff;
  border-radius: 8px;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #dedede;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 700;
`;

export const SubTitle = styled.small`
  color: #656a6e;
`;

export const Code = styled.div`
  padding: 24px;
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  gap: 16px;
  border-top: 1px solid #dedede;
`;

export const Pre = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
  font-weight: 400;

  color: ${theme.colors.black[4]};

  border-radius: 6px;

  padding: 1rem;
  background-color: ${theme.colors.white[1]};
  border: 1px solid ${theme.colors.white[7]};

  max-height: 200px;
  overflow-y: auto;
  text-overflow: ellipsis;
`;
