import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 499px;
  border: 1px solid #dedede;
  background-color: #ffff;
  border-radius: 8px;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #dedede;
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #222529;
`;

export const Information = styled.div`
  padding: 24px 24px 8px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InformationItem = styled.div`
  display: flex;
  gap: 4px;
  align-items: flex-start;
  text-align: center;
`;

export const InformationItemTitle = styled.strong``;

export const InformationItemTextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: pre-wrap;
`;

type InformationItemTextProps = {
  divider?: string;
};

export const InformationItemText = styled.span<InformationItemTextProps>`
  display: flex;
  width: fit-content;
  white-space: nowrap;

  &:not(:last-child)::after {
    content: '${props => props.divider ?? ' / '}';
    margin-left: ${props => (props.divider?.includes(',') ? '0' : '4px')};
    margin-right: 4px;
  }
`;

export const Schedule = styled.div`
  display: flex;
  padding: 0 24px 24px 24px;
  flex-direction: column;
  gap: 8px;
  max-height: 250px;
  overflow-y: auto;
`;

export const ScheduleItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #dedede;
`;

export const Footer = styled.footer`
  border-top: 1px solid #dedede;
  padding: 16px 24px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
