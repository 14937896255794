import { Avatar, theme, Typography } from '@wisecare-tech/design-system-web';
import React from 'react';
import { OptionItem } from './optionItem';
import {
  AvatarContainer,
  OptionsListContainer,
  OrgInfoContainer,
  SideMenuContainer,
} from './styles';

export const SideMenu: React.FC = () => {
  return (
    <SideMenuContainer>
      <AvatarContainer>
        <Avatar name="Oi" size="lg" />
        <OrgInfoContainer>
          <Typography variant="t3_16semibold">WisecareLTDA</Typography>
          <Typography variant="b1_16regular" color={theme.colors.black[4]}>
            Organização
          </Typography>
        </OrgInfoContainer>
      </AvatarContainer>
      <OptionsListContainer>
        <OptionItem label="Dados gerais" />
        <OptionItem label="Administradores" />
        <OptionItem label="Setores" />
        <OptionItem label="Configurações" />
        <OptionItem label="SAC - Atende Fácil" active />
        <OptionItem label="Faturamento" />
      </OptionsListContainer>
    </SideMenuContainer>
  );
};
