import React from 'react';
import { ItemSkeleton as StItemSkeleton } from '../styles';

export const ItemSkeleton: React.FC = () => {
  return (
    <StItemSkeleton>
      <span />
    </StItemSkeleton>
  );
};
