import { CreateTopicConfig as UsecaseRemoteCreateTopicConfig } from 'domain/usecases/sacConfig/remote';
import { makeApiUrl, makeHttpClient } from 'main/factories/http';

import { RemoteCreateTopicConfig } from 'data/repository/sacConfig';

/**
 * send request via API.
 */
export const makeRemoteCreateTopicConfig = (): UsecaseRemoteCreateTopicConfig =>
  new RemoteCreateTopicConfig(makeApiUrl('/sac/topics'), makeHttpClient());
