/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { Typography, IconButton, Icon } from '@wisecare-tech/design-system-web';

import { makeRemoteSendConferenceFiles } from 'main/factories/usecases/conferenceFiles/SendConferenceFilesFactory';
import { toast } from 'react-toastify';
import { ListSacRequestById } from 'domain/usecases/sacRequest/remote/ListSacRequestById';
import { makeRemoteListSacRequestById } from 'main/factories/usecases/sacRequest/ListSacRequestByIdFactory';
import { ListConferenceFiles } from 'domain/usecases/conferenceFiles/remote';
import { makeRemoteListConferenceFiles } from 'main/factories/usecases/conferenceFiles/ListConferenceFilesFactory';
import { makeRemoteDeleteConferenceFiles } from 'main/factories/usecases/conferenceFiles/DeleteConferenceFilesFactory';
import { makeRemoteDownloadConferenceFiles } from 'main/factories/usecases/conferenceFiles/DownloadConferenceFilesFactory';
import {
  Container,
  Header,
  UploadFiles,
  InputFile,
  Label,
  Documents,
  DocumentsList,
  DocumentsItem,
  DocumentsInfo,
  Actions,
  DocsEmpty,
  DocsEmptyIcon,
} from './StyledSuportDocuments';

interface iSupportDocuments {
  onClose: () => void;
}

export const SupportDocuments: React.FC<iSupportDocuments> = ({ onClose }) => {
  const [requestDetails, setRequestDetails] =
    useState<ListSacRequestById.Model>();

  const [documents, setDocuments] = useState<ListConferenceFiles.Model>();

  const requestId = new URLSearchParams(window.location.search).get('sr');

  useEffect(() => {
    if (!requestId) return;

    makeRemoteListSacRequestById()
      .listById({
        request: requestId,
        token: new URLSearchParams(window.location.search).get('ak') || '',
      })
      .then(response => {
        setRequestDetails(response);
      })
      .catch(() => {
        toast.error('Erro ao carregar detalhes da solicitação');
      });
  }, [requestId]);

  useEffect(() => {
    if (!requestDetails?.conference) return;

    makeRemoteListConferenceFiles()
      .list({
        short: requestDetails?.conference?.short,
        token: new URLSearchParams(window.location.search).get('ak') || '',
      })
      .then(response => {
        setDocuments(response);
      })
      .catch(() => {
        toast.error('Erro ao listar arquivos');
      });
  }, [requestDetails?.conference]);

  const { short } = requestDetails?.conference || {};

  /* Upload files and send to the server */
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files;

    const formData = new FormData();

    if (file) {
      const files = Array.from(file);

      files.forEach((item: File) => {
        formData.append('files', item);
      });

      makeRemoteSendConferenceFiles()
        .send({
          short: String(short),
          body: formData,
          token: new URLSearchParams(window.location.search).get('ak') || '',
        })
        .then(() => {
          if (!requestDetails?.conference) return;

          makeRemoteListConferenceFiles()
            .list({
              short: requestDetails?.conference?.short,
              token:
                new URLSearchParams(window.location.search).get('ak') || '',
            })
            .then(response => {
              setDocuments(response);
            })
            .catch(() => {
              toast.error('Erro ao listar arquivos');
            });
        })
        .catch(() => {
          toast.error('Erro ao enviar arquivo');
        });
    }
  };

  /* Render icon based to type */
  const renderDocumentOfType = (mimeType: string) => {
    if (mimeType.includes('image')) {
      return <Icon name="image" color="blue-7" size="h3" />;
    }
    if (mimeType.includes('application/pdf')) {
      return <Icon name="picture_as_pdf" color="blue-7" size="h3" />;
    }
    if (mimeType.includes('audio')) {
      return <Icon name="audio_file" color="blue-7" size="h3" />;
    }
    if (mimeType.includes('video')) {
      return <Icon name="video_file" color="blue-7" size="h3" />;
    }
    return null;
  };

  /* Delete document of conference */
  const deleteDocument = (id: string) => {
    if (!id) return;
    if (!short) return;

    makeRemoteDeleteConferenceFiles()
      .delete({
        file: id,
        short,
        token: new URLSearchParams(window.location.search).get('ak') || '',
      })
      .then(() => {
        toast.success('Arquivo deletado com sucesso');

        makeRemoteListConferenceFiles()
          .list({
            short,
          })
          .then(response => {
            setDocuments(response);
          })
          .catch(() => {
            toast.error('Erro ao listar arquivos');
          });
      })
      .catch(() => {
        toast.error('Erro ao deletar arquivo');
      });
  };

  /* View uploaded document */
  const viewDocument = (id: string) => {
    if (!id) return;
    if (!short) return;

    makeRemoteDownloadConferenceFiles()
      .download({
        file: id,
        short,
        token: new URLSearchParams(window.location.search).get('ak') || '',
      })
      .then(response => {
        window.open(response.url, '_blank');
      })
      .catch(() => {
        toast.error('Erro ao baixar arquivo');
      });
  };

  /* Verify quantity of documents */
  const renderQuantityOfDocuments = () => {
    if (!documents?.records.length) return 'Nenhum documento adicionado';

    if (documents?.records.length > 1) {
      return `${documents?.records.length} documentos adicionados`;
    }

    return `${documents?.records.length} documento adicionado`;
  };

  return (
    <Container>
      <Header>
        <div />
        <Typography variant="t3_16semibold">Documentos de apoio</Typography>

        <IconButton
          icon="close"
          variant="primary"
          size="sm"
          onClick={onClose}
        />
      </Header>

      <UploadFiles>
        <Label htmlFor="file">
          <Icon name="add" color="blue-7" />
          <Typography variant="t4_16medium" style={{ color: '#0565D9' }}>
            Adicionar arquivos
          </Typography>
        </Label>
        <InputFile type="file" id="file" multiple onChange={handleFileChange} />

        <Typography variant="b1_16regular" style={{ color: '#656A6E' }}>
          Adicione imagens, áudios, vídeos, ou documentos. Tamanho máximo: 500
          MB.
        </Typography>
      </UploadFiles>

      <Documents>
        {!documents?.records.length ? (
          <DocsEmpty>
            <DocsEmptyIcon>
              <Icon name="file_present" color="white-7" size="h3" />
            </DocsEmptyIcon>
            <Typography variant="b1_16regular" style={{ color: '#656A6E' }}>
              Este atendimento não possui documentos de apoio.
            </Typography>
          </DocsEmpty>
        ) : (
          <>
            {documents?.records.length > 0 && (
              <Typography variant="b1_16regular" style={{ color: '#656A6E' }}>
                {renderQuantityOfDocuments()}
              </Typography>
            )}
            <DocumentsList>
              {documents?.records.map(document => (
                <DocumentsItem key={document?.id}>
                  {renderDocumentOfType(document?.mimeType)}
                  <DocumentsInfo>
                    <Typography variant="b3_14medium">
                      {document?.filename}
                    </Typography>
                    <Typography
                      variant="b4_14regular"
                      style={{ color: '#656A6E' }}
                    >
                      Adicionado por:{' '}
                      {document?.sentBy?.fullname ??
                        new URLSearchParams(window.location.search).get('u')}
                    </Typography>
                  </DocumentsInfo>
                  <Actions>
                    <IconButton
                      icon="visibility"
                      variant="primary"
                      size="sm"
                      onClick={() => viewDocument(String(document?.id))}
                    />
                    <IconButton
                      icon="delete"
                      variant="destructive"
                      color="red-7"
                      size="sm"
                      onClick={() => deleteDocument(String(document?.id))}
                    />
                  </Actions>
                </DocumentsItem>
              ))}
            </DocumentsList>
          </>
        )}
      </Documents>
    </Container>
  );
};
