import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { LeftSacSpecialist as UsecaseRemoteLeftSacSpecialist } from 'domain/usecases/sacSpecialist/remote';
// import { LeftSacSpecialist as UsecaseReduxLeftSacSpecialist } from 'domain/usecases/sacSpecialist/redux';

import { RemoteLeftSacSpecialist } from 'data/repository/sacSpecialist';
// import { ReduxLeftSacSpecialist } from 'data/store/reducer/sacSpecialist/usecases';

/**
 * send request via API.
 */
export const makeRemoteLeftSacSpecialist = (): UsecaseRemoteLeftSacSpecialist =>
  new RemoteLeftSacSpecialist(
    makeApiUrl('/sac/specialists/{userId}/LEFT'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxLeftSacSpecialist =
  (): UsecaseReduxLeftSacSpecialist =>
    new ReduxLeftSacSpecialist(); */
