/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import {
  Icon,
  IconButton,
  SortButton,
  Typography,
} from '@wisecare-tech/design-system-web';
import Actions from 'presentation/components/Actions';
import { iStore } from 'domain/interfaces/models';
import { IconCamAtendeFacil } from 'presentation/base/icons';
import { iListSacRequest } from 'domain/usecases/sacRequest/remote';
import { formatISODateHour } from 'utils/tableDateHour';
import { makeRemoteAcceptSacRequest } from 'main/factories/usecases/sacRequest/AcceptSacRequestFactory';
import { toast } from 'react-toastify';
import { Information } from '../styles';

export const columns: ColumnDef<iListSacRequest>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    cell: ({ row }) => {
      const data = row.getValue('id') as iListSacRequest['id'];

      return <div>{data}</div>;
    },
  },
  {
    accessorKey: 'created',
    header: 'Solicitado em',
    cell: ({ row }) => {
      const data = row.getValue('created') as iListSacRequest['created'];

      const dateHour = formatISODateHour(data);

      return (
        <Information>
          <Typography variant="b4_14regular">{dateHour.date}</Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {dateHour.hour}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorFn: row => row,
    header: 'Solicitante',
    cell: ({ cell }) => {
      const data = cell.getValue() as iListSacRequest;

      return (
        <Information>
          <Typography variant="b4_14regular">{data?.name ?? ''}</Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {data?.phone ?? ''}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorFn: row => row,
    header: 'Contato',
    cell: ({ cell }) => {
      const data = cell.getValue() as iListSacRequest;

      return (
        <Information>
          <Typography variant="b4_14regular">{data?.phone}</Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {data?.email}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'descr',
    header: 'Assunto',
    cell: ({ row }) => {
      const data = row.getValue('descr') as iListSacRequest['descr'];

      return <div>{data}</div>;
    },
  },
  {
    id: 'actions',
    enableHiding: true,
    cell: ({ row }) => {
      const value = row.original;

      // const professional_id = getProfessionalInfo();

      // const handleGoToDetails = (id: number, edit = false) => {
      //   if (edit) {
      //     history.push(`/diagnosis/${id}`, {
      //       edit,
      //     });
      //     return;
      //   }

      //   history.push(`/diagnosis/${id}`);
      // };

      // const store: iStore = storeDev.getState();
      // const { role } = store.auth.selectUser;

      const redirect = (request: number) => {
        makeRemoteAcceptSacRequest()
          .accept({ request })
          .then(res => {
            window.open(
              `/preconf?t=${res.roomName}&sr=${request}&sessionName=${res.sessionName}`,
              '_blank',
            );
          })
          .catch(() => {
            toast.error('Erro ao aceitar solicitação.');
          });
      };

      return (
        <IconButton
          icon="videocam"
          variant="primary"
          onClick={() => redirect(value.id)}
        />
      );
    },
  },
];
