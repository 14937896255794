import { Button, theme } from '@wisecare-tech/design-system-web';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100%;
  max-height: auto;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  overflow: hidden;

  & > form {
    height: 100%;
  }
`;

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1366px) {
    flex-direction: row;
    padding-inline: 4.5rem;
    gap: 1.5rem;
    height: 100%;
  }
`;

export const ActionButtonNewHotlineContainer = styled.div`
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;

  display: none;

  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

export const CreateHotlineButton = styled(Button)`
  margin: 0 auto;
  margin-bottom: 1.5rem;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const SubHeader = styled.div`
  width: 100%;
  background-color: #fdfdfd;
  color: #656a6e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;

  margin-bottom: 1rem;
  border-block: 1px solid #dedede;

  padding: 1rem 1.5rem;

  #subheader_title {
    flex: 1;
    display: flex;
    justify-content: center;
    margin-right: 2rem;
  }

  @media screen and (min-width: 1366px) {
    & > #button-back {
      display: none;
    }
  }
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 1.5rem;
  padding-block: 1rem;
  background-color: ${theme.colors.white[3]};

  @media screen and (min-width: 1366px) {
    padding-inline: 0;
    padding-top: 0;
  }
`;

export const SacSection = styled(Section)``;

export const EmptyStateSection = styled(Section)`
  flex: 1;

  @media screen and (min-width: 1366px) {
    height: auto;
  }
`;

export const SacActionsButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;
  gap: 1rem;
  border: 1px solid ${theme.colors.white[6]};
  background-color: ${theme.colors.white.DEFAULT};

  @media screen and (min-width: 768px) {
    align-items: start;
    justify-content: start;
  }
`;

export const InfoActionsContainer = styled.div`
  flex-direction: column;
  align-items: start;
  gap: 1rem;

  display: none;

  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

export const SacEmptyStateHotlineContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
  padding-inline: 1rem;
  padding-block: 1.5rem;
  border-radius: 4px;
  background-color: ${theme.colors.white.DEFAULT};
  border: 1px solid ${theme.colors.white[6]};

  @media screen and (min-width: 1366px) {
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    height: 100%;
    padding-block: 4rem;

    #message-action,
    button {
      display: none;
    }
  }
`;

export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  border-radius: 50%;
  aspect-ratio: 1;
  border: 1px solid ${theme.colors.white[6]};

  & > span {
    color: ${theme.colors.white[7]};
  }

  @media screen and (min-width: 1366px) {
    width: 7rem;
    & > span {
      font-size: 3.5rem !important;
    }
  }
`;

export const SideMenuContainer = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-block: 1px solid ${theme.colors.white[6]};
  background-color: ${theme.colors.white.DEFAULT};

  @media screen and (min-width: 1366px) {
    min-width: 17.5rem;
    height: fit-content;
    border-inline: 1px solid ${theme.colors.white[6]};
    border-radius: 0.5rem;
    flex: 1;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const OrgInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const OptionsListContainer = styled.ul`
  display: flex;
  gap: 0.5rem;
  background-color: ${theme.colors.white.DEFAULT};
  border-radius: 4px;

  padding: 0.25rem;

  overflow-y: visible;
  overflow-x: auto;

  scroll-snap-align: center start;
  scroll-snap-type: mandatory;

  @media screen and (min-width: 1366px) {
    flex-direction: column;
    max-width: 28rem;
  }
`;

export const StOptionItem = styled.button<{ active?: boolean }>`
  display: flex;
  width: max-content;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  padding-inline: 0.5rem;
  padding-block: 1rem;

  flex-wrap: nowrap;
  white-space: nowrap;

  cursor: pointer;
  color: ${({ active }) =>
    active ? theme.colors.blue[7] : theme.colors.black[4]};
  background-color: ${({ active }) =>
    active ? theme.colors.blue[1] : 'transparent'};

  &:focus-visible {
    outline: 2px solid ${theme.colors.blue[4]};
    outline-offset: 2px;
    z-index: 2;
  }

  @media screen and (min-width: 1366px) {
    width: 100%;
    justify-content: start;
  }
`;

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.white.DEFAULT};
  border-radius: 4px;
  border: 1px solid ${theme.colors.white[6]};
`;

export const PopoverItem = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.white[4]};
  }

  &:last-child {
    color: ${theme.colors.red[7]};
    & span {
      color: ${theme.colors.red[7]};
    }
    border-top: 1px solid ${theme.colors.white[6]};
  }
`;

export const SkeletonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
`;

const skeletonAnimation = keyframes`
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`;

export const ItemSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  & > span {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 4rem;
    border-radius: 0.5rem;
    background: ${theme.colors.white[3]};
    background-image: linear-gradient(
      45deg,
      ${theme.colors.white[3]},
      ${theme.colors.white[4]},
      ${theme.colors.white[3]}
    );
    background-size: 200%;
    animation: ${skeletonAnimation} 1.5s infinite forwards;
    animation-delay: 0.2s;
  }
`;
