/* eslint-disable react/jsx-wrap-multilines */
import React, { Suspense } from 'react';

import {
  Button,
  Header,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  theme,
  Typography,
} from '@wisecare-tech/design-system-web';
import { Link } from 'react-router-dom';
import { useListTopics } from 'presentation/hooks/sac/listTopics';
import { SacActions } from './actions';
import { HotlineEmptyState } from './emptyState';
import { SideMenu } from './sideMenu';
import {
  ActionButtonNewHotlineContainer,
  Container,
  CreateHotlineButton,
  MainContainer,
  SkeletonContainer,
  SubHeader,
} from './styles';
import { ItemSkeleton } from './tables/itemSkeleton';

const DisabledTable = React.lazy(() => import('./tables/disabled'));
const HotlineTable = React.lazy(() => import('./tables/hotline'));

// TODO: Unused
export const Sac: React.FC = () => {
  const { overview } = useListTopics();

  return (
    <Container>
      <Header
        onLogout={() => console.log('logout')}
        options={[
          {
            title: 'Administração',
            href: '/sac',
          },
        ]}
      />
      <SubHeader>
        <Typography variant="b2_14semibold">AtendeFácil</Typography>
      </SubHeader>
      <MainContainer>
        <SideMenu />
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <SacActions />
          <CreateHotlineButton size="lg" icon="add">
            Criar linha de atendimento
          </CreateHotlineButton>
          <div>
            <Tabs
              defaultValue="line"
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <TabsList
                style={{ border: `1px solid ${theme.colors.white[6]}` }}
              >
                <TabsTrigger value="line">
                  {`Linha de atendimento (${overview.active ?? 0})`}
                </TabsTrigger>
                <TabsTrigger value="disabled">
                  {`Desabilitadas (${overview.inactive ?? 0})`}
                </TabsTrigger>
                <ActionButtonNewHotlineContainer>
                  <Link to="/sac/create/topic">
                    <Button variant="primary" size="sm" icon="add">
                      Criar linha de atendimento
                    </Button>
                  </Link>
                </ActionButtonNewHotlineContainer>
              </TabsList>
              <Suspense
                fallback={
                  <>
                    <SkeletonContainer>
                      {Array.from<number>({ length: overview.active ?? 1 }).map(
                        _ => (
                          <ItemSkeleton key={_} />
                        ),
                      )}
                    </SkeletonContainer>
                  </>
                }
              >
                <TabsContent
                  value="line"
                  style={{ display: 'flex', backgroundColor: '#fdfdfd' }}
                >
                  {overview.active > 0 ? (
                    <HotlineTable />
                  ) : (
                    <HotlineEmptyState />
                  )}
                </TabsContent>
                <TabsContent
                  value="disabled"
                  style={{ display: 'flex', backgroundColor: '#fdfdfd' }}
                >
                  {overview.inactive > 0 ? (
                    <DisabledTable />
                  ) : (
                    <HotlineEmptyState />
                  )}
                </TabsContent>
              </Suspense>
            </Tabs>
          </div>
        </div>
      </MainContainer>
    </Container>
  );
};
