/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  BiMicrophone,
  BiMicrophoneOff,
  BiVideo,
  BiVideoOff,
} from 'react-icons/bi';
import { Button, Input } from 'presentation/components/UI';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { toast } from 'react-toastify';
import { makeRemoteJoinConference } from 'main/factories/usecases/conference/JoinConferenceFactory';
import { useFormik } from 'formik';
import { preConferenceSchema } from 'validation/preconf/PreConferenceValidation';
import { ThemeContext } from 'App';
import { makeReduxListFilteredConference } from 'main/factories/usecases/conference/ListFilteredConferenceFactory';
import Translator from 'presentation/components/i18n/Translator';
import { makeRemoteListSacRequestById } from 'main/factories/usecases/sacRequest/ListSacRequestByIdFactory';
import {
  Container,
  Background,
  Content,
  Camera,
  ButtonsContainer,
  IconBox,
  Text,
  ButtonsContainerFooter,
  CameraVideo,
  Watermark,
  ElipsedText,
} from './styles/styledPreConf';

export interface ParamsState {
  token?: string;
  user?: string;
}

const initialValues = {
  fullName: '',
};

export const PreConf: React.FC = (): JSX.Element => {
  const { user } = useSelector((store: iStore) => store.auth);
  const { search } = useLocation();
  const { push } = useHistory();
  const { theme } = useContext(ThemeContext);

  const videoRef = useRef<HTMLVideoElement>(null);
  const tracksRef = useRef<MediaStreamTrack[]>([]);

  const params = useMemo(
    () => ({
      t: new URLSearchParams(search).get('t') ?? null,
      u: new URLSearchParams(search).get('u') ?? null,
      sr: new URLSearchParams(search).get('sr') ?? null,
      sessionName: new URLSearchParams(search).get('sessionName') ?? null,
    }),
    [search],
  );

  const { values, errors, touched, setFieldValue, handleBlur, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit: (formValues, { validateForm }) => {
        validateForm(formValues);
        push(
          `/join?t=${params?.t}&u=${confName}&c=${isCamOn}&m=${isMicOn}${
            params?.sr ? `&sr=${params.sr}` : ''
          }${params?.sessionName ? `&sessionName=${params.sessionName}` : ''}`,
        );
      },
      validationSchema: preConferenceSchema,
      validateOnChange: true,
    });

  const [isMicOn, setIsMicOn] = useState(true);
  const [isCamOn, setIsCamOn] = useState(true);
  const [confTitle, setConfTitle] = useState('');
  const [confName, setConfName] = useState('');

  const disableVideo = useCallback(() => {
    const video = videoRef.current;

    if (video) {
      video.pause();
      video.srcObject = null;
    }

    tracksRef.current.forEach(track => track.stop());
  }, [videoRef]);

  const getVideo = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({ video: { width: 300, height: 236 } })
      .then(stream => {
        tracksRef.current = stream.getTracks();

        const video = videoRef.current;

        if (video) {
          video.srcObject = stream;
          video.play();

          // As opções abaixo são necessárias para o funcionamento correto no iOS
          video.setAttribute('autoplay', '');
          video.setAttribute('muted', '');
          video.setAttribute('playsinline', '');
        }
      })
      .catch(err => {
        setIsCamOn(false);
        disableVideo();

        toast.error(
          'Falha ao acessar câmera. Verifique se outra aplicação está usando.',
        );
      });
  }, [disableVideo]);

  useEffect(() => {
    if (isCamOn) {
      getVideo();
    } else {
      disableVideo();
    }

    return () => {
      disableVideo();
    };
  }, [disableVideo, getVideo, isCamOn]);

  useEffect(() => {
    makeReduxListFilteredConference().list({
      dataControl: {
        limit: 9999,
      },
    });

    if (params.t && !params.sr) {
      makeRemoteJoinConference()
        .join({
          conferenceShort: params.t,
          userLocator: params.u ?? undefined,
        })
        .then(res => {
          setConfTitle(res.conference?.title ?? '');

          if (res.user?.fullName) {
            setConfName(res.user.fullName);
            setFieldValue('fullName', res.user.fullName);
          } else if (user?.fullName) {
            setConfName(user.fullName);
            setFieldValue('fullName', user.fullName);
          }
        })
        .catch(err => {
          // toast.error('Não foi possível buscar dados da conferência.');
        });
    } else if (params.sr) {
      makeRemoteListSacRequestById()
        .listById({
          request: params.sr,
        })
        .then(res => {
          setConfTitle(res.conference?.short ?? '');

          if (user?.fullName) {
            setConfName(user.fullName);
            setFieldValue('fullName', user.fullName);
          }
        })
        .catch(err => {
          // toast.error('Não foi possível buscar dados da conferência.');
        });
    } else if (user?.fullName) {
      setConfName(user?.fullName);
      setFieldValue('fullName', user?.fullName);
    }
  }, [params, setFieldValue, user?.fullName]);

  return (
    <Container onSubmit={handleSubmit}>
      <Background>
        {theme.images.background.main && (
          <>
            <img
              src={theme.images.background.main}
              alt="Logo"
              style={{ width: '50%', height: '100%' }}
            />
            <img
              src={theme.images.background.main}
              alt="Logo"
              style={{ width: '50%', height: '100%' }}
            />
          </>
        )}
      </Background>
      <Content>
        <Camera>
          {isCamOn ? (
            <CameraVideo ref={videoRef} />
          ) : (
            <Text fontSize="18px" color="#262626">
              Câmera desligada.
            </Text>
          )}
          {theme.images.logo.main && (
            <Watermark src={theme.images.logo.main} alt="Logo" />
          )}
        </Camera>
        <ButtonsContainer>
          <IconBox active={isCamOn} onClick={() => setIsCamOn(prev => !prev)}>
            {isCamOn ? (
              <BiVideo color="#F5F5F5" size="26px" />
            ) : (
              <BiVideoOff color="#F5F5F5" size="26px" />
            )}
          </IconBox>
          <IconBox active={isMicOn} onClick={() => setIsMicOn(prev => !prev)}>
            {isMicOn ? (
              <BiMicrophone color="#F5F5F5" size="26px" />
            ) : (
              <BiMicrophoneOff color="#F5F5F5" size="26px" />
            )}
          </IconBox>
        </ButtonsContainer>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
          <Text color="#262626">{Translator('Reunião')}:</Text>
          <ElipsedText color="#262626" bold>
            {confTitle}
          </ElipsedText>
        </div>
        <Input
          data-testid="input-name"
          label="Seu nome:"
          placeholder="Digite o seu nome aqui"
          backgroundColor="#F5F5F5"
          value={values.fullName}
          onChange={e => {
            setConfName(e.target.value);
            setFieldValue('fullName', e.target.value);
          }}
          style={{ fontSize: '20px' }}
          autoFocus
          onBlur={handleBlur}
          message={touched.fullName ? errors.fullName : ''}
          error={Boolean(touched.fullName && errors?.fullName)}
        />
        <ButtonsContainerFooter>
          <Button
            data-testid="btn-back"
            size="medium"
            variant="secundary"
            onClick={() => push('/home')}
          >
            Voltar
          </Button>
          <Button
            data-testid="btn-join"
            type="submit"
            size="medium"
            onClick={() =>
              push(
                `/join?t=${params?.t}&u=${confName}&c=${isCamOn}&m=${isMicOn}${
                  params?.sr ? `&sr=${params.sr}` : ''
                }${
                  params?.sessionName
                    ? `&sessionName=${params.sessionName}`
                    : ''
                }`,
              )
            }
            disabled={Boolean(errors?.fullName)}
          >
            Entrar na {Translator('reunião')}
          </Button>
        </ButtonsContainerFooter>
      </Content>
    </Container>
  );
};

export default PreConf;
