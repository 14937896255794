import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 530px;
  background: #fff;
  border-radius: 16px;
  border: 1px solid #dedede;
`;

export const Content = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
`;

interface iHeader {
  variant: 'ENABLED' | 'DISABLED';
}
export const Header = styled.div<iHeader>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: ${({ variant }) =>
    variant === 'ENABLED' ? '#E6F0FB' : '#FFEFEA'};
  margin-bottom: 16px;
`;

export const Footer = styled.footer`
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  border-top: 1px solid #dedede;
`;
