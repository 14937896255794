import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import { BadRequestError, Forbidden, UnexpectedError } from 'domain/errors';
import { UpdateTopic } from 'domain/usecases/sacTopic/remote';
import 'infra/global/variables';

export class RemoteUpdateTopic implements UpdateTopic {
  private readonly url: string;

  private readonly httpClient: HttpClient<UpdateTopic.Model>;

  constructor(url: string, httClient: HttpClient<UpdateTopic.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  update = async (params: UpdateTopic.Params): Promise<UpdateTopic.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.path.topic}`,
      method: 'patch',
      body: params.body,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
