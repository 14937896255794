import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import { Forbidden, NotFound, UnexpectedError } from 'domain/errors';
import { GetTopics } from 'domain/usecases/sacTopic/remote';
import 'infra/global/variables';

export class RemoteGetTopics implements GetTopics {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetTopics.Model>;

  constructor(url: string, httClient: HttpClient<GetTopics.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  list = async (params: GetTopics.Params): Promise<GetTopics.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: {
        filters: {
          active: true,
        },
        ...params,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
