import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tabs,
  TabsList,
  TabsTrigger,
} from '@wisecare-tech/design-system-web';
import { iListSacRequest } from 'domain/usecases/sacRequest/remote';
import { makeRemoteListSacRequest } from 'main/factories/usecases/sacRequest/ListSacRequestFactory';
import { History } from 'main/routes';
import CardRequestDetails from 'presentation/components/cardRequestDetails';
import { Header, HeaderBack } from 'presentation/components/header';
import Translator from 'presentation/components/i18n/Translator';
import React, { useEffect, useState } from 'react';
import { finalizedColumns } from './columns/finalizedColumn';
import { columns } from './columns/mainColumn';

import { Body, Container } from './styles';

type Status = 'WAITING' | 'FINISHED';

const AtendeFacilPage: React.FC = () => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [sacList, setSacList] = useState<iListSacRequest[]>([]);
  const [sacFinalizedList, setSacFinalizedList] = useState<iListSacRequest[]>(
    [],
  );
  const [currentSacItem, setCurrentSacItem] = useState<iListSacRequest>();
  const [currentTab, setCurrentTab] = useState<Status>('WAITING');

  const table = useReactTable({
    data: sacList,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const tableFinalized = useReactTable({
    data: sacFinalizedList,
    columns: finalizedColumns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const renderTable = {
    WAITING: table,
    FINISHED: tableFinalized,
  };

  useEffect(() => {
    makeRemoteListSacRequest()
      .list({
        body: {
          filters: {
            status: [currentTab],
          },
          dataControl: {
            limit: 9999,
          },
        },
      })
      .then(res => {
        if (currentTab === 'WAITING') {
          setSacList(res.records);
        } else {
          setSacFinalizedList(res.records);
        }
      })
      .catch(console.error);
  }, [currentTab]);

  return (
    <Container>
      <Header title="teste" />
      <HeaderBack
        onBack={() => History.getHistory().goBack()}
        title={`${Translator('SAC - Atende Fácil')}`}
      />
      <Body>
        <div>
          <Tabs
            defaultValue="submitted"
            style={{
              border: '1px solid #dedede',
              borderRadius: 4,
              borderBottom: 'none',
            }}
          >
            <TabsList>
              <TabsTrigger
                value="submitted"
                onClick={() => {
                  setCurrentTab('WAITING');
                  setCurrentSacItem(undefined);
                }}
              >
                Aguardando
              </TabsTrigger>
              <TabsTrigger
                value="delayed"
                onClick={() => {
                  setCurrentTab('FINISHED');
                  setCurrentSacItem(undefined);
                }}
              >
                Finalizados
              </TabsTrigger>
            </TabsList>
          </Tabs>
          <div style={{ height: 551 }}>
            <Table
              rounded
              style={{
                // border: '1px solid #dedede',
                width: 1000,
                // height: 551,
              }}
            >
              <TableHeader>
                {renderTable[currentTab as Status]
                  .getHeaderGroups()
                  .map(headerGroup => (
                    <>
                      {headerGroup.headers.map(header => (
                        <TableHead key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                        </TableHead>
                      ))}
                    </>
                  ))}
              </TableHeader>

              <TableBody style={{ borderRadius: 8 }}>
                {renderTable[currentTab as Status]
                  .getRowModel()
                  .rows.map(row => (
                    <TableRow
                      key={row.id}
                      onClick={() => setCurrentSacItem(row.original)}
                    >
                      {row.getAllCells().map(cell => (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </div>
        <CardRequestDetails
          cardDetails={currentSacItem}
          cardActions={currentTab !== 'FINISHED'}
        />
      </Body>
    </Container>
  );
};

export default AtendeFacilPage;
