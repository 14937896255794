import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { ThemeContext } from 'App';
import { AfterConference } from 'domain/usecases/conference/remote';
import { makeRemoteAfterConference } from 'main/factories/usecases/conference/AfterConferenceFactory';
import { IconPersonCircle } from 'presentation/base/icons';
import { Button } from 'presentation/components/UI';
import { formattedDate } from 'utils/formattedDate';

import Translator from 'presentation/components/i18n/Translator';
import { makeRemoteListSacRequestById } from 'main/factories/usecases/sacRequest/ListSacRequestByIdFactory';
import {
  Container,
  Header,
  Section,
  LeftContent,
  Title,
  RightContent,
  Content,
  LoginContent,
  LoginText,
  TextBold,
  InfoContent,
  Space,
  SpaceDescription,
} from './styles';

export interface ParamsState {
  m?: boolean;
  c?: boolean;
  u?: string;
  t?: string;
}

const EndConf: React.FC = () => {
  const { search } = useLocation();
  const { push } = useHistory();
  const { theme } = useContext(ThemeContext);

  const params = useMemo(
    () => ({
      m: new URLSearchParams(search).get('m') ?? false,
      c: new URLSearchParams(search).get('c') ?? false,
      t: new URLSearchParams(search).get('t') ?? null,
      u: new URLSearchParams(search).get('u') ?? null,
      ak: new URLSearchParams(search).get('ak') ?? undefined,
      sr: new URLSearchParams(search).get('sr') ?? undefined,
    }),
    [search],
  );

  const { m, c, t, u, ak, sr } = params;

  const [conference, setConference] = useState<AfterConference.Model>(
    {} as AfterConference.Model,
  );

  useEffect(() => {
    if (t && !sr) {
      makeRemoteAfterConference()
        .get({
          short: t,
        })
        .then(res => {
          setConference(res);
        })
        .catch(() => {
          // toast.error('Erro ao carregar a reunião');
        });
    } else if (sr) {
      makeRemoteListSacRequestById()
        .listById({
          request: sr,
          token: ak,
        })
        .then(res => {
          setConference({
            descr: res?.descr,
            owner: res?.specialist?.fullname,
            ownerEmail: res?.specialist?.email,
            scheduled: res?.created,
            title: res?.conference?.short,
          });
        })
        .catch(() => {
          // toast.error('Erro ao carregar a reunião');
        });
    }
  }, [t, sr, ak]);

  return (
    <Container>
      <Header>
        {theme.images.logo.small && (
          <img src={theme.images.logo.small} alt="Logo" />
        )}
      </Header>
      <Section>
        <LeftContent>
          <Title>{Translator('Você saiu da reunião')}</Title>
          {!ak && (
            <Button
              size="large"
              onClick={() => push(`/join?t=${t}&u=${u}&c=${c}&m=${m}`)}
            >
              Voltar para a sala
            </Button>
          )}
          <InfoContent>
            <Space>
              <TextBold>Título: </TextBold>
              <p>{conference?.title}</p>
            </Space>
            <Space>
              <TextBold>Data e Hora: </TextBold>
              <p>
                {conference?.scheduled
                  ? formattedDate(new Date(conference?.scheduled))
                  : conference?.scheduled}
              </p>
            </Space>
            <Space>
              <TextBold>Agendada por: </TextBold>
              <p>{conference?.owner}</p>
            </Space>
            <Space>
              <TextBold>E-mail do organizador: </TextBold>
              <p>{conference?.ownerEmail}</p>
            </Space>
            <SpaceDescription>
              <TextBold>Descrição: </TextBold>
              <p>{conference?.descr?.length ? conference?.descr : '-'}</p>
            </SpaceDescription>
          </InfoContent>
        </LeftContent>
        <RightContent>
          {theme.images.background.main && (
            <img src={theme.images.background.main} alt="Logo" />
          )}
        </RightContent>
      </Section>
    </Container>
  );
};

export default EndConf;
