import { UpdateTopic as UsecaseRemoteUpdateTopic } from 'domain/usecases/sacTopic/remote';
import { makeApiUrl, makeHttpClient } from 'main/factories/http';

import { RemoteUpdateTopic } from 'data/repository/sacTopic';

/**
 * send request via API.
 */
export const makeRemoteUpdateTopic = (): UsecaseRemoteUpdateTopic =>
  new RemoteUpdateTopic(makeApiUrl('/sac/topics'), makeHttpClient());
