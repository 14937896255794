import io, { Socket } from 'socket.io-client';
import { History } from 'main/routes';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';

let socket: typeof Socket | null = null;

export const initializeSocket = (request: number): void => {
  const sacToken = localStorage.getItem('sacToken');
  if (!socket) {
    socket = io(window.config.connection.socketIoUrl, {
      query: { sacToken },
    });
    console.log('>>> Socket initialized with token:', sacToken);

    listening(request);
  } else {
    console.log('>>> Socket already connected possible sync problem');
  }
};

export const getSocket = (): typeof Socket | null => {
  if (!socket) {
    // throw new Error('Socket not initialized. Call initializeSocket first.');
  }
  return socket;
};

export const disconnectSocket = (): void => {
  if (socket) {
    socket.disconnect();
    console.log('>>> Socket disconnected.');
    socket = null;
  }
};

const listening = (request: number): void => {
  if (!socket) {
    throw new Error('Socket not initialized. Call initializeSocket first.');
  }

  socket.on('SAC_SPECIALIST_JOIN', (data: any) => {
    console.log(data);
    console.log(' >>> SAC_SPECIALIST_JOIN');
    makeReduxActiveMessage().active({
      active: 'sacProfessionalReady',
      data: { ...data?.specialist, request },
    });
  });

  socket.on('SAC_SPECIALIST_LEFT', (data: any) => {
    console.log(data);
    console.log(' >>> SAC_SPECIALIST_LEFT');
    History.getHistory().push('/sac/leave');
  });

  socket.on('SAC_SPECIALIST_DROP', (data: any) => {
    console.log(data);
    console.log(' >>> SAC_SPECIALIST_DROP');
  });

  socket.on('SAC_SPECIALIST_TRANSFERED_REQUESTER', (data: any) => {
    console.log('>>> SAC_SPECIALIST_TRANSFERED_REQUESTER: ', data);
    const payload = data?.payload;
    localStorage.setItem('sacToken', payload?.newRequestWebsocketToken);

    History.getHistory().push(
      `/sac/waitingRoom/${payload?.newRequestId}?token=${new URLSearchParams(
        window.location.search,
      ).get('ak')}&u=${new URLSearchParams(window.location.search).get('u')}`,
      {
        request: payload?.newRequestId,
        sacToken: payload?.newRequestWebsocketToken,
      },
    );
  });
};
