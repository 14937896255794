import { Translations } from '.';

const esES: Record<Translations, string> = {
  ' de Abril': ' de Abril',
  ' de Agosto': ' de Agosto',
  ' de Dezembro': ' de Diciembre',
  ' de Fevereiro': ' de Febrero',
  ' de Janeiro': ' de Enero',
  ' de Julho': ' de Julio',
  ' de Junho': ' de Junio',
  ' de Maio': ' de Mayo',
  ' de Março': ' de Marzo',
  ' de Novembro': ' de Noviembre',
  ' de Outubro': ' de Octubre',
  ' de Setembro': ' de Septiembre',
  '+Adicionar': '+Agregar',
  'A sigla deve conter no máximo 32 caracteres.':
    'La sigla debe contener no máximo 32 caracteres.',
  ADDRESS_INCOMPLETE: 'La dirección está incompleta',
  ADDRESS_NOT_CREATED: 'No se pudo crear la dirección',
  ADDRESS_NOT_UPDATED: 'No se pudo actualizar la dirección',
  APPOINTMENT_CONTROL_API_CREATE_ERROR:
    'Problema al enviar solicitudes para crear control de citas',
  APPOINTMENT_CONTROL_API_DELETE_ERROR:
    'Problema al enviar solicitudes para eliminar control de citas',
  APPOINTMENT_CONTROL_API_LIST_ERROR:
    'Problema al enviar solicitudes para listar control de citas',
  AUTHORIZATION_API_COULD_NOT_ACTION_EXISTS:
    'No se pudo verificar si la acción existe en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_CREATE_ACTION:
    'No se pudo crear la acción en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_CREATE_ACTION_TO_ROLE:
    'No se pudo crear una acción para el rol en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_CREATE_RESOURCE:
    'No se pudo crear el recurso en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_CREATE_ROLE:
    'No se pudo crear el rol en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_CREATE_USER:
    'No se pudo crear el usuario en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_GET_USER_ACL:
    'No se pudo obtener el ACL del usuario en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_GIVE_PERMISSION_TO_ROLE:
    'No se pudo dar permiso al rol en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_GIVE_PERMISSION_TO_USER:
    'No se pudo dar permiso al usuario en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_GIVE_ROLE_TO_USER:
    'No se pudo dar el rol al usuario en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_LIST_ACTIONS:
    'No se pudieron listar las acciones de autorización',
  AUTHORIZATION_API_COULD_NOT_LIST_RESOURCES:
    'No se pudieron listar los recursos de autorización',
  AUTHORIZATION_API_COULD_NOT_LIST_ROLES:
    'No se pudieron listar los roles de autorización',
  AUTHORIZATION_API_COULD_NOT_REMOVE_PERMISSION_TO_USER:
    'No se pudo eliminar el permiso del usuario en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_REMOVE_ROLE_PERMISSION:
    'No se pudo eliminar el permiso del rol en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_REMOVE_ROLE_TO_USER:
    'No se pudo eliminar el rol del usuario en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_REMOVE_USER:
    'No se pudo eliminar el usuario del sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_RESOURCE_EXISTS:
    'No se pudo verificar si el recurso existe en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_ROLE_ACTION_EXISTS:
    'No se pudo verificar si la acción del rol existe en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_ROLE_EXISTS:
    'No se pudo verificar si el rol existe en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_ROLE_PERMISSIONS:
    'No se pudieron listar los permisos del rol en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_UPDATE_RESOURCE_TO_RESOURCE:
    'No se pudo actualizar el recurso en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_UPDATE_ROLE:
    'No se pudo actualizar el rol en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_UPDATE_USER:
    'No se pudo actualizar el usuario en el sistema de autorización',
  AUTHORIZATION_API_LOGIN_ERROR:
    'No se pudo iniciar sesión en el sistema de autorización',
  AUTH_ACL_NOT_PROVIDED: 'A ACL do usuário deve ser fornecida para this ação',
  AUTH_CONFIRMATION_TOKEN_INCORRECT: 'Token de confirmación incorrecto',
  AUTH_COULD_NOT_LIST_USER:
    'Não foi possível listar a senha do usuário no serviço de autenticação',
  AUTH_COULD_NOT_RESET_PASSWORD:
    'Não foi possível redefinir a senha do usuário no serviço de autenticação',
  AUTH_EXTERNAL_TOKEN_INVALID: 'Token externo no válido',
  AUTH_FAILED: 'Falha na autenticação',
  AUTH_FORBIDDEN: 'Você não tem permissão para executar essa ação',
  AUTH_MISSING_AUTHORIZATION_HEADER: 'Cabeçalho de autorización ausente',
  AUTH_MULTIPLES_ORG_AVAILABLE: 'Múltiples organizaciones disponibles',
  AUTH_NOT_CREATED_USER:
    'Não foi possível criar usuário no serviço de autenticação',
  AUTH_NOT_DELETED_USER:
    'Não foi possível eliminar usuário no serviço de autenticação',
  AUTH_NOT_UPDATED_USER:
    'Não foi possível atualizar usuário no serviço de autenticação',
  AUTH_PWD_RECOVERY_TOKEN_INCORRECT:
    'Token de recuperación de senha incorrecto',
  AUTH_REFRESH_FAILED: 'No se puede renovar o iniciar sesión',
  AUTH_REFRESH_INVALID_TOKEN: 'Token de renovación inválido',
  AUTH_USER_DOES_NOT_MATCH_WITH_TOKEN:
    'O ID do usuário não corresponde ao token',
  'Aceite os termos de uso e de gravação para iniciar.':
    'Aceite los terminos de uso y de grabación para iniciar.',
  'Aceito os': 'Acepto los',
  'Aceito que o atendimento poderá ser gravado':
    'Acepto que el servicio podrá ser grabado',
  'Aceito que o atendimento poderá ser gravado.':
    'Acepto que el servicio podrá ser grabado.',
  Acessar: 'Acessar',
  'Acessas farmácia': 'Acceso a la farmacia',
  'Acesse o documento': 'Acesse el documento',
  'Acesso para farmácia': 'Acceso para farmacia',
  'Acesso à Receita': 'Acceso à Prescripción',
  Add: 'Add',
  'Adicionar novo usuário': 'Adicionar nuevo usuario',
  'Adicione participantes ou digite um novo e-mail':
    'Agrega participantes o ingresa un nuevo correo electrónico',
  'Adicione um título': 'Agregar un título',
  'Adicione uma descrição': 'Agregar una descripción',
  'Administrador Geral': 'Administradora Geral',
  Administrador: 'Administrador',
  'Administradores de sistema': 'Administradores de sistema',
  Administradores: 'Administradores',
  Administração: 'Administración',
  'Agenda de atendimentos': 'Cronograma de servicio',
  Agenda: 'Agenda',
  Agendadas: 'Agendadas',
  Agendado: 'Programado',
  Agendamentos: 'Agendamientos',
  'Agendar novo atendimento': 'Programar nuevo servicio',
  'Agendar reunião': 'Programar reunión',
  Agendar: 'Programar',
  Agora: 'Ahora',
  'Aguarde nesta tela.': 'Aguarde en ésta tela.',
  'Aguarde o início do atendimento': 'Aguarde el início del servicio',
  'Altere algum campo para atualizar o setor.':
    'Altere algún campo para actualizar el sector.',
  Anotações: 'Notas',
  Anterior: 'Anterior',
  'Antes de entrar na sala de espera': 'Antes de entrar en la sala de espera',
  'Antes de iniciar': 'Antes de iniciar',
  'Ao excluir um usuário, as informações pertinentes a eles como dados de atendimentos, documentos clínicos e de apoio como também as conversas em chat, serão mantidas de acordo com as politicas de privacidade e termos de uso da plataforma V4H atendimento. ':
    'Después de borrar un usuario, las informaciónes pertinentes de la ellos como datos de servicio, documentos clínicos y de apoyo como también las conversaciones en chat, se mantendrá de acuerdo con las políticas de privacidad y terminos de uso de la plataforma V4H servicio. ',
  'Ao remover o usuário, algumas das informações associadas a ele (dados de consultas médicas, documentos clínicos e de suporte, conversas por chat) serão mantidas de acordo com as políticas de privacidade e termos de uso do serviço.':
    'Después de borrar el usuario, algunas de las informaciónes asociadas a ello (datos de consultas médicas, documentos clínicos y de suporte, conversaciones por chat) se mantendrá de acuerdo con las políticas de privaciudad y terminos de uso del servicio.',
  'Apenas esta reunião': 'Solo esta reunión',
  'Armazenamento total': 'Almacenamiento total',
  Armazenamento: 'Almacenamiento',
  'Assessoria de Imprensa': 'Oficina de prensa',
  Assessoria: 'Consejo',
  Assinado: 'Firmado',
  'Assinar Documento': 'Firma Documento',
  'Assinar agora': 'Firma ahora',
  'Assinar depois': 'Firma despues',
  'Assinatura do documento': 'Assinatura del documento',
  Assinatura: 'Assinatura',
  'Atendimento cancelado': 'Servicio cancelado',
  Atendimento: 'Servicio',
  'Atestado Médico': 'Certificado Médico',
  Ativado: 'Habilitado',
  'Atualizar imagem do usuário': 'Actualizar imagen de usuario',
  'Atualizar logo da organização': 'Actualizar logotipo de la organización',
  'Atualizar organização': 'Actualizar organización',
  'Atualizar setor': 'Actualizar sector',
  Avançado: 'Avanzado',
  Avançar: 'Avance',
  'Aviso importante': 'Advertencia importante',
  Avulso: 'Avulso',
  'Ações do atendimento': 'Acciones del servicio',
  Ações: 'Acciones',
  Bairro: 'Distrito',
  'Baixar PDF': 'Descargar PDF',
  'Baixar conferência': 'Descargar conferencia',
  'Baixar documento': 'Descargar documento',
  Blockchain: 'Blockchain',
  'Buscar por...': 'Busquéda por...',
  'CEP inválido, existe(m) digitos faltando':
    'CEP no es válido, hay dígitos faltantes',
  'CEP inválido, número de digitos ultrapassou o limite':
    'CEP no es válido, número de dígitos excedió el límite',
  'CEP inválido.': 'CEP no es válido.',
  CEP: 'Código postal',
  CONFERENCE_ALREADY_STARTED: 'La conferencia ya ha sido iniciada',
  CONFERENCE_BAD_RRULE:
    'No se pudo crear la recurrencia para la regla proporcionada',
  CONFERENCE_COULD_NOT_LIST:
    'No se pudo listar las conferencias en la base de datos',
  CONFERENCE_FILE_COULD_NOT_DELETE:
    'No se pudo eliminar el archivo de la conferencia',
  CONFERENCE_FILE_NOT_CREATED: 'No se pudo crear el archivo de la conferencia',
  CONFERENCE_FILE_NOT_FOUND: 'Archivo de la conferencia no encontrado',
  CONFERENCE_IS_CANCELED: 'La conferencia se ha cancelado o ha terminado',
  CONFERENCE_LIMIT_REACHED: 'Límite alcanzado. Se requiere el pago',
  CONFERENCE_MEMBERS_USER_NOT_FOUND:
    'No se pudo encontrar el usuario del participante',
  CONFERENCE_MEMBER_ALREADY: 'El usuario ya es un participante',
  CONFERENCE_MEMBER_ALREADY_WITH_ROLE:
    'El participante ya ha sido asignado a este rol',
  CONFERENCE_MEMBER_COULD_NOT_LIST:
    'No se pudo listar los participantes en la base de datos',
  CONFERENCE_MEMBER_NOT_CREATED:
    'No se pudo crear el miembro de la conferencia en la base de datos',
  CONFERENCE_MEMBER_NOT_DELETED:
    'No se pudo eliminar al participante de la conferencia en la base de datos',
  CONFERENCE_MEMBER_NOT_FOUND:
    'No se pudo encontrar el miembro de la conferencia',
  CONFERENCE_NOTE_COULD_NOT_LIST:
    'No se pudieron listar las notas de la conferencia en la base de datos',
  CONFERENCE_NOTE_CREATE_ERROR: 'No se pudo crear la nota de la conferencia',
  CONFERENCE_NOTE_NOT_FOUND: 'Nota de la conferencia no encontrada',
  CONFERENCE_NOT_CREATED: 'No se pudo crear la conferencia en la base de datos',
  CONFERENCE_NOT_DELETED:
    'No se pudo eliminar la conferencia en la base de datos',
  CONFERENCE_NOT_FOUND: 'No se pudo encontrar la conferencia',
  CONFERENCE_NOT_STARTED:
    'La conferencia no ha sido iniciada por el propietario',
  CONFERENCE_OWNER_CANNOT_QUIT:
    'El propietario no puede abandonar su propia conferencia',
  CONFERENCE_OWNER_NOT_IN_ORG:
    'El propietario no es miembro de esta organización',
  CONFERENCE_RECORDINGS_NOT_FOUND:
    'No se encontraron grabaciones para esta conferencia',
  CONFERENCE_RECURRENCE_NOT_DELETED:
    'No se pudieron eliminar las recurrencias de la conferencia en la base de datos',
  CONFERENCE_RECURRENCE_NOT_FOUND:
    'No se pudieron encontrar recurrencias de la conferencia en la base de datos',
  CONFERENCE_RECURRENCE_NOT_UPDATED:
    'No se pudieron actualizar las recurrencias de la conferencia en la base de datos',
  CONFERENCE_RECURRENCE_NO_NEW:
    'La regla proporcionada no crea nuevas recurrencias. Por favor, proporcione una regla válida.',
  CONFERENCE_REGISTRY_NOT_FOUND:
    'No se encontró ningún registro para esta conferencia',
  CONFERENCE_SUMMARY_NOT_FOUND:
    'No se pudo encontrar información resumida de la conferencia',
  CONFERENCE_TRANSCRIPTION_NOT_FOUND:
    'No se encontraron transcripciones para esta conferencia',
  CONFERENCE_WAITING_ROOM_DISABLED:
    'La conferencia no tiene sala de espera habilitada',
  'CPF inválido.': 'CPF no es válido.',
  CPF: 'CPF',
  'Cadastrado em': 'Registrado en',
  Cadastrado: 'Registrado',
  Cadastrados: 'Cadastrados',
  'Cadastrar novo documento clínico': 'Registrar nuevo documento clínico',
  'Cadastrar novo organizador': 'Registrar nuevo organizador',
  'Cadastrar novo participante': 'Registrar nuevo participante',
  'Cadastrar novo profissional': 'Registrar nuevo profesional',
  'Cadastro de Pessoa Física': 'Registro de Persona Fisica',
  'Cadastro de conferência': 'Registro de conferencia',
  Cadastro: 'Registro',
  'Campo obrigatório': 'Campo obligatorio',
  Cancelado: 'Cancelado',
  'Cancelar atendimento': 'Cancelar servicio',
  'Cancelar atendimento?': '¿Cancelar servicio?',
  Cancelar: 'Cancelar',
  'Carregando...': 'Cargando...',
  'Centro de Atendimento ao cliente': 'Centro de Servicio al Cliente',
  Cep: 'Cep',
  'Certificado Digital': 'Certificado Digital',
  Chat: 'Chat',
  Cidade: 'Ciudad',
  'Classificação de organizações': 'Clasificación de organizaciones',
  'Classificação de usuários': 'Clasificación de usuarios',
  'Clique para ver as notas de release':
    'Haga clic para ver las notas de lanzamiento',
  Cod: 'Cod',
  'Compartilhar por email': 'Compartir por correo electrónico',
  Complemento: 'Complemento',
  Concluir: 'Concluir',
  'Conferência atualizada com sucesso': 'Conferencia actualizada exitosamente',
  'Configurações da organização atualizadas com sucesso!':
    '¡Configuraciones de la organización actualizadas con éxito!',
  Configurações: 'Configuraciones',
  'Confime sua senha': 'Confirmar la contraseña',
  Confirmado: 'Confirmado',
  'Confirmar nova senha': 'Confirmar nueva contraseña',
  Confirmar: 'Confirmar',
  'Confirmação de conta': 'Confirmacion de cuenta',
  'Confirmação de e-mail': 'Confirmación de correo electrónico',
  'Confirmação do cadastro de usuário': 'Confirmación de registro de usuario',
  'Confirme a senha': 'Confirme de la contraseña',
  'Consultante inválido ou não encontrado.':
    'Consultante no es válido o no encontrado.',
  'Consumo por dia do mês': 'Consumo por día del mes',
  'Consumo por serviço': 'Consumo por servicio',
  'Consumo por setor': 'Consumo por sectores',
  'Consumo por usuário': 'Consumo por usuario',
  Consumo: 'Consumo',
  'Conta confirmada com sucesso!': '¡Cuenta confirmada con éxito!',
  Contato: 'Contacto',
  Contatos: 'Contactos',
  Convidado: 'Visitante',
  Convidados: 'Visitantes',
  'Convidar paciente para participar': 'Invitar al paciente para participar',
  'Convidar profissional externo': 'Invitar el profesional externo',
  'Copiar link de atendimento': 'Copiar enlace de servicio',
  'Copiar link': 'Copiar enlace',
  Cpf: 'Cpf',
  'Criado em': 'Creado en',
  'Criação de Reunião Instantânea': 'Creación de Reunión Instantánea',
  'Criação de organização': 'Creación de organización',
  'Criação de reunião': 'Creación de reuniones',
  'Criação de setor': 'Creación de sector',
  'Criação de usuário': 'Creación de usuario',
  'Código e Senha': 'Código y Contraseña',
  Código: 'Código',
  'Código:': 'Código:',
  DASHBOARD_COULD_NOT_GET: 'No se pudo obtener el panel',
  'DD/MM/AAAA': 'DD/MM/AAAA',
  DESATIVADA: 'DESATIVADA',
  DOCUMENT_CREATOR_CREATE:
    'No se pudo crear el documento en el servicio de creación de documentos',
  DOCUMENT_CREATOR_DOWNLOAD:
    'No se pudo descargar el documento en el servicio de creación de documentos',
  DOCUMENT_CREATOR_LOGIN_ERROR:
    'No se pudo iniciar sesión en el servicio de creación de documentos',
  DOCUMENT_CREATOR_VERIFY:
    'No se pudo verificar el documento en el servicio de creación de documentos',
  'Dados Gerais': 'Datos Generales',
  'Dados Pessoais': 'Datos Personales',
  'Dados Profissionais': 'Datos Profesionales',
  'Dados do paciente': 'Datos del paciente',
  'Dados do perfil': 'Datos del perfil',
  'Dados do profissional de saúde': 'Datos del profesional de la salud',
  'Dados gerais': 'Datos generales',
  'Dados perfil': 'Datos de perfil',
  'Dados profissionais': 'Datos profesionales',
  'Data de Nascimento': 'Fecha de Nacimiento',
  'Data de emissão': 'Fecha de emisión',
  'Data de inclusão': 'Fecha de inclusión',
  'Data de nascimento é obrigatório.': 'Fecha de nacimiento es obligatorio.',
  'Data de nascimento': 'Fecha de nacimiento',
  'Data do Atendimento': 'Data del Servicio',
  'Data do atendimento': 'Data del servicio',
  'Data e Hora': 'Fecha y Hora',
  'Data e hora': 'Fecha y hora',
  'Data inválida': 'Data no es válido',
  'Data inválida.': 'Fecha no es válido.',
  Data: 'Fecha',
  'Deixar a sala': 'Dejar la sala',
  'Deixar sala': 'Dejar sala',
  Dentista: 'Dentista',
  Desativado: 'Deshabilitado',
  'Descrição da reunião': 'Descripción de la reunión',
  Descrição: 'Descripción',
  'Deseja gerar um documento com a transcrição da reunião?':
    '¿Desea generar un documento con la transcripción de la reunión?',
  'Deseja realmente cancelar o atendimento: ':
    'Desea realmente cancelar el servicio: ',
  'Deseja realmente desvincular esse usuário da organização?':
    '¿Realmente desea desvincular a este usuario de la organización?',
  'Deseja realmente desvincular esse usuário do setor?':
    'Deseja realmente desvincular esse usuario del sector?',
  'Deseja realmente excluir a organização':
    '¿Desea realmente eliminar la organización?',
  'Deseja realmente excluir a reunião?':
    '¿Deseas realmente eliminar la reunión?',
  'Deseja realmente excluir o setor?': 'Deseja realmente excluir el sector?',
  'Deseja realmente excluir o usuário': '¿Desea realmente eliminar el usuario?',
  Desligada: 'Desactivada',
  Desligado: 'Apagado',
  'Desvincular Organizador do Setor': 'Desvincular Organizador del Sector',
  'Desvinculo de Usuário da organização':
    'Desvincular usuario de la organización',
  'Desvinculo de usuário do setor': 'Desvinculo de usuario del sector',
  'Detalhes da auditoria': 'Detalles de la auditoría',
  'Detalhes da conferência': 'Detalles de la conferencia',
  'Detalhes da operação': 'Detalles de la operación',
  'Detalhes de organização': 'Detalhes de la organización',
  'Detalhes do atendimento': 'Detalles del servicio',
  'Detalhes do setor': 'Detalles do sector',
  'Detalhes do usuário': 'Detalles de usuario',
  Detalhes: 'Detalles',
  Dia: 'Día',
  'Digite a confirmação da nova senha':
    'Ingresar nueva confirmación de contraseña',
  'Digite a senha atual': 'Introducir la contraseña actual',
  'Digite a senha da receita': 'Escribelo la contraseña de la prescripción',
  'Digite apenas letras maiusculas e minúsculas e espaço.':
    'Escribelo solamente letras mayúsculas y minúsculas y espacio.',
  'Digite nova senha': 'Escribelo nueva contraseña',
  'Digite novamente a nova senha': 'Escribelo de nuevo de la nueva contraseña',
  'Digite o CPF do administrador': 'Escribelo el CPF del administrador',
  'Digite o CPF do organizador': 'Escribelo el CPF del organizador',
  'Digite o CPF do paciente': 'Escribelo el CPF del paciente',
  'Digite o código da receita': 'Escribelo el código de la prescripción',
  'Digite o código do atendimento': 'Escribelo el código del servicio',
  'Digite o código do paciente': 'Escribelo el código del paciente',
  'Digite o e-mail do profissional':
    'Escribelo el correo electrónico del profesional',
  'Digite o nome de um médico': 'Escribelo el nombre de un médico',
  'Digite o nome de um organizador': 'Escribelo el nombre de un organizador',
  'Digite o nome do administrador': 'Escribelo el nombre del administrador',
  'Digite o nome do organizador': 'Ingrese el nombre del organizador',
  'Digite o nome do paciente': 'Escribelo el nombre del paciente',
  'Digite o nome do participante': 'Ingrese el nombre del participante',
  'Digite o nome do profissinal': 'Escribelo el nombre del profesional',
  'Digite o nome do profissional': 'Escribelo el nombre del profesional',
  'Digite o nome ou e-mail de um participante...':
    'Ingrese el nombre o correo electrónico de un participante ...',
  'Digite o número do seu celular': 'Ingresa tu número de celular',
  'Digite o seu email': 'Escribelo tu correo electrónico',
  'Digite o(s) e-mail(s) para convidar':
    'Escribelo o(s) correo electrónico(s) para invitar',
  'Digite o(s) email(s) para convidar':
    'Escribelo o(s) correo eletronico(s) para invitar',
  'Digite seu CEP': 'Escribelo tu CEP',
  'Digite seu bairro': 'Escribelo tu bairro',
  'Digite seu e-mail': 'Escriba su correo electrónico',
  'Digite seu estado': 'Escribelo tu estado',
  'Digite seu nome': 'Escribelo tu nombre',
  'Digite seu país': 'Escribelo tu país',
  'Digite seu sobrenome': 'Escribelo tu apellido',
  'Digite sua Senha': 'Escribelo tu contraseña',
  'Digite sua cidade': 'Escribelo tu ciudad',
  'Digite um complemento': 'Escribelo un complemento',
  'Digite uma senha pessoal': 'Escribelo una contraseña pessoal',
  Disponíveis: 'Disponibles',
  Disponível: 'Disponible',
  'Documento clínico': 'Documento clínico',
  'Documento não enviado': 'Documento no enviado al paciente',
  'Documentos Clínicos': 'Documentos Clínicos',
  'Documentos clínicos': 'Documentos clínicos',
  'Documentos de apoio': 'Documentos de apoyo',
  Domingo: 'Domingo',
  Dosagem: 'Dosis',
  Droga: 'Droga',
  'Duração média': 'Duración media',
  'Duração total': 'Duración total',
  Duração: 'Duración',
  'E-mail confirmado em': 'Correo electrónico confirmado en',
  'E-mail do profissional': 'Correo electrónico del profesional',
  'E-mail enviado com sucesso!': '¡Email enviado con éxito!',
  'E-mail já cadastrado': 'Correo electrónico ya registrado',
  'E-mail reenviado com sucesso!': '¡Correo electrónico reenviado con éxito!',
  'E-mail': 'Correo electrónico',
  'Editar especialidade': 'Editar especialidad',
  'Editar organização': 'Editar organización',
  'Editar reunião recorrente': 'Editar reunión recurrente',
  'Editar reunião': 'Editar reunión',
  'Editar setor': 'Editar sector',
  'Editar usuário': 'Editar usuario',
  'Edição de conferência': 'Edición de conferencia',
  'Em andamento': 'En proceso',
  'Em progresso': 'En progreso',
  'Email inválido.': 'Email no es válido.',
  Email: 'Correo electrónico',
  Emergency: 'Emergencia',
  Endereço: 'Dirección',
  'Entrar com:': 'Entrar com:',
  'Entrar na consulta': 'Entrar en la consulta',
  'Entrar na reunião': 'Entrar en la reunión',
  Entrar: 'Acceder',
  'Enviar ao Paciente': 'Enviar al Paciente',
  'Enviar convite por Whatsapp': 'Enviar invitación por Whatsapp',
  'Enviar documento por e-mail': 'Enviar documento por correo electrónico',
  'Enviar e concluir': 'Enviar y completar',
  Enviar: 'Enviar',
  'Erro ao buscar dados da organização':
    'Error al buscar datos de la organización',
  'Erro ao buscar reunião': 'Error al buscar reunión',
  'Especialidade inválida ou não encontrada':
    'Especialidad inválida o no encontrada',
  Especialidade: 'Especialidad',
  'Esqueceu sua senha?': '¿Olvidaste tu contraseña?',
  'Esta e todas as seguintes': 'Esta y todas las siguientes',
  Estado: 'Estado',
  Estatísticas: 'Estadísticas',
  'Este documento clinico será listado como Documento pendente e não será enviado ao paciente até que a assinatura seja finalizada.':
    'Este documento clinico será listado como Documento pendente y no será enviado al paciente até que la assinatura seja finalizada.',
  'Este número é whatsapp': '¿Este número es whatsapp?',
  Evento: 'Evento',
  'Eventos do atendimento': 'Eventos del servicio',
  'Excluir Receita': 'Borrar Prescripción',
  'Excluir conta': 'Borrar cuenta',
  'Excluir documento': 'Eliminar documento',
  'Excluir especialidade': 'Borrar especialidad',
  'Excluir reunião recorrente': 'Eliminar reunión recurrente',
  'Excluir usuario': 'Borrar usuario',
  'Excluir usuário': 'Borrar usuario',
  'Extrato de consumo detalhado': 'Declaración detallada de consumo',
  FACE_RECOGNITION_API_COULD_NOT_ADD_FILE_TO_FORM: 'Error al leer el archivo',
  FACE_RECOGNITION_API_COULD_NOT_CREATE_SUBJECT: 'No se pudo crear el sujeto',
  FACE_RECOGNITION_API_COULD_NOT_GET_SUBJECT: 'No se pudo obtener el sujeto',
  FACE_RECOGNITION_API_COULD_NOT_SEND_PICTURE: 'No se pudo enviar la imagen',
  FACE_RECOGNITION_API_COULD_NOT_UPDT_PICTURE:
    'No se pudo actualizar la imagen de usuario',
  FACE_RECOGNITION_API_COULD_NOT_VERIFY_FACE:
    'No se pudo verificar la cara del usuario',
  FACE_RECOGNITION_API_COULD_NOT_VERIFY_SUBJECT:
    'No se pudo verificar el sujeto',
  FACE_RECOGNITION_API_NO_FACE_RECORD:
    'El usuario no tiene un registro facial adjunto al perfil',
  FILE_REQUIRED: 'Se requiere archivo para esta acción',
  'Falha no servidor, tente novamente mais tarde.':
    'Falla en servidor, intenta de nuevo más tarde.',
  Faturamento: 'Facturación',
  Fechar: 'Cerrar',
  Feminino: 'Femenino',
  Fim: 'Fim',
  Finalizado: 'Terminado',
  'Finalizar atendimento': 'Cerrar servicio',
  'Formas de convite': 'Formas de invitación',
  Forte: 'Fuerte',
  Fraca: 'Debil',
  Geral: 'General',
  'Gerar documento com a transcrição da reunião':
    'Generar documento con la transcripción de la reunión',
  Gravadas: 'Grabadas',
  Gravado: 'Grabado',
  Gravados: 'Grabados',
  Gravação: 'Grabación',
  'Gravações do atendimento': 'Grabaciones del servicio',
  Gravações: 'Grabaciones',
  Grupo: 'Grupo',
  Gênero: 'Género',
  HEALTHCHECK_FAILED: 'La verificación de salud falló',
  Hoje: 'Hoy',
  'Hora de início': 'Hora de início',
  'Hora de ínicio incorreta': 'Hora de ínicio incorreta',
  'Hora do fim': 'Hora del final',
  'Hora fim': 'Hora final',
  'Hora final incorreta': 'Hora final incorreta',
  'Hora início': 'Hora início',
  Hora: 'Hora',
  'Horas de reuniões': 'Horas de reuniones',
  'Horas gravadas': 'Horas grabadas',
  Horas: 'Horas',
  ID: 'ID',
  Idade: 'Edad',
  'Imagem do usuário alterada com sucesso!':
    '¡La imagen de usuario cambió con éxito!',
  'Informações da reunião': 'Información de la reunión',
  'Informações do usuário atualizadas com sucesso!':
    '¡Información de usuario actualizada con éxito!',
  'Informações do usuário atualizadas com sucesso':
    'Información del usuario actualizada con éxito!',
  'Informações gerais': 'Informaciones generales',
  'Informações históricas': 'Informaciones históricas',
  'Iniciar atendimento': 'Comienza el servicio',
  'Insira a UF do seu documento profissional':
    'Insira de la Estado del tu documento profesional',
  'Insira a data de emissão do seu documento profissional':
    'Insira la fecha de emisión de tu documento profesional',
  'Insira a dosagem': 'Insira la dosis',
  'Insira a posologia da medicação': 'Insira la dosis de la medicação',
  'Insira o CÓDIGO e SENHA disponíveis na receita do paciente':
    'Insira el CÓDIGEl y CONTRASEÑA disponible en la prescripción del paciente',
  'Insira o nome da droga': 'Insira el nombre de la droga',
  'Insira o nome da sua cidade.': 'Insira el nombre de tu ciudad.',
  'Insira o nome do seu bairro.': 'Insira el nombre de tu bairro.',
  'Insira o número de sua residência': 'Insira el número de tu residencia',
  'Insira o número do seu documento profissional':
    'Insira el número del tu documento profesional',
  'Insira o seu endereço.': 'Insira el tu endereço.',
  'Insira o tempo de afastamento': 'Insira el tiempo libre',
  'Insira seu nome completo.': 'Insira tu nombre completo.',
  'Insira uma indicação clínica.': 'Insira una indicación clínica.',
  Instituição: 'Institución',
  Início: 'Início',
  'Ir para sala de espera': 'Ir a la sala de espera',
  'Já existe um setor com este nome.': 'Ya existe un sector con este nombre.',
  'Já existe uma organização com este nome':
    'Ya existe una organización con este nombre',
  LINK_GEN_API_COULD_NOT_CREATE_LINK: 'No se pudo crear enlace',
  LINK_GEN_API_LOGIN_ERROR:
    'No se pudo iniciar sesión en el sistema LinkGenerator',
  Ligada: 'Activado',
  Ligado: 'Activado',
  'Limite de gravações mensais (horas)':
    'Límite de grabaciones mensuales (horas)',
  'Limite de reuniões mensais (horas)': 'Límite de reuniones mensuales (horas)',
  'Limpar todas': 'Limpiar todos',
  'Lista de Documentos clínicos': 'Lista de Documentos clínicos',
  'Lista de Documentos de apoio': 'Lista de Documentos de apoyo',
  'Lista de organizações': 'Lista de organizaciones',
  'Lista de profissionais participantes':
    'Lista de profesionales participantes',
  'Lista de usuários': 'Lista de usuarios',
  'Log de atividade': 'Log de actividad',
  Logados: 'Logados',
  'Login desabilitado com sucesso!': '¡Iniciar sesión deshabilitado con éxito!',
  'Login habilitado com sucesso!': '¡Iniciar sesión habilitado con éxito!',
  'Login habilitado': 'Inicio de sesión habilitado',
  Login: 'Login',
  'Logo da organização alterado com sucesso!':
    '¡El logo de la organización cambió con éxito!',
  Logradouro: 'Lugar público',
  Manutenção: 'Mantenimiento',
  Masculino: 'Masculino',
  'Minha conta': 'Mi cuenta',
  'Minhas reuniões': 'Mis reuniones',
  'Mudança de senha': 'Cambio de contraseña',
  'Mudar senha': 'Cambiar contraseña',
  'Média de consumo diário': 'Media de consumo diario',
  'Média de participantes': 'Media de participantes',
  Médico: 'Médico',
  Médio: 'Mediana',
  Mês: 'Mes',
  NOTIFICATION_API_CHECK_MESSAGE_EXIST:
    'No se pudo verificar si el mensaje existe en el servicio de centro de notificaciones',
  NOTIFICATION_API_COULD_NOT_SEND_MESSAGE:
    'No se pudo enviar el mensaje en el servicio de centro de notificaciones',
  NOTIFICATION_API_COULD_NOT_SEND_MESSAGE_WITH_ATTACHEMENTS:
    'No se pudo enviar el mensaje con adjuntos en el servicio de centro de notificaciones',
  NOTIFICATION_API_COULD_NOT_VERIFY_MESSAGE:
    'No se pudo verificar el mensaje en el servicio de notificaciones',
  NOTIFICATION_API_LOGIN_ERROR:
    'No se pudo iniciar sesión en el servicio de centro de notificaciones',
  'Nome Completo': 'Nombre Completo',
  'Nome completo': 'Nombre completo',
  'Nome da Organização': 'Nombre de la Organización',
  'Nome da mãe é obrigatório.': 'Nombre de la madre es obligatorio.',
  'Nome da mãe': 'Nombre de la madre',
  'Nome da organização': 'Nombre de la organización',
  'Nome da pessoa de contato': 'Nombre del Contacto',
  'Nome da sua mãe': 'Nombre de tu madre',
  'Nome do Profissional': 'Nombre del Profesional',
  'Nome do setor': 'Nombre del sector',
  'Nome do usuário': 'Nombre del usuario',
  Nome: 'Nombre',
  Notificações: 'Notificaciones',
  'Nova Organização': 'Nueva Organización',
  'Nova mensagem': 'Nueva mensaje',
  'Nova reunião': 'Nueva reunión',
  'Nova senha': 'Nueva contraseña',
  'Novo Administrador': 'Nuevo administrador',
  'Novo Usuário': 'Nuevo Usuario',
  'Novo agendamento': 'Nuevo servicio',
  'Novo documento clínico': 'Nuevo documento clínico',
  'Novo documento de apoio': 'Nuevo documento de apoyo',
  'Novo documento': 'Nuevo documento',
  'Novo organizador': 'Nuevo organizador',
  'Novo paciente': 'Nuevo paciente',
  'Novo profissional': 'Nuevo profesional',
  'Novo setor': 'Nuevo sector',
  'Novos usuários': 'Nuevos usuarios',
  'Não cancelar': 'No cancele',
  'Não foi possível alterar a senha': 'No se puede cambiar la contraseña',
  'Não foi possível alterar senha': 'No se puede cambiar la contraseña',
  'Não foi possível atualizar a reunião':
    'No fue posible actualizar la reunión',
  'Não foi possível atualizar as informações do usuário':
    'No se puede actualizar la información del usuario',
  'Não foi possível atualizar configurações da organização':
    'No fue posible actualizar las configuraciones de la organización',
  'Não foi possível atualizar imagem do usuário':
    'No se puede actualizar la imagen del usuario',
  'Não foi possível atualizar logo da organização':
    'No se puede actualizar el logotipo de la organización',
  'Não foi possível atualizar o setor.': 'No fue posible actualizar el sector.',
  'Não foi possível atualizar organização':
    'No se puede actualizar la organización',
  'Não foi possível buscar dados da organização do setor':
    'No fue posible buscar datos de la organización del sector',
  'Não foi possível buscar detalhes de administração do usuário':
    'No se pueden obtener los detalles de administración del usuario',
  'Não foi possível buscar usuário.': 'No se puede obtener el usuario.',
  'Não foi possível cadastrar a organização':
    'No se puede registrar la organización.',
  'Não foi possível carregar dados do usuário':
    'No se pueden cargar los datos del usuario',
  'Não foi possível carregar o arquivo': 'No puede cargar el arquivo',
  'Não foi possível confirmar conta': 'No se puede confirmar la cuenta',
  'Não foi possível criar a reunião': 'No fue posible crear la reunión',
  'Não foi possível criar o setor.': 'No fue posible crear el sector.',
  'Não foi possível criar usuário': 'No se puede crear usuario',
  'Não foi possível desassociar o usuário do setor.':
    'No fue posible desasociar el usuario del sector.',
  'Não foi possível desativar a câmera.':
    'No fue possível desativar de la cámara.',
  'Não foi possível desvincular o usuário da organização':
    'No se puede desvincular al usuario de la organización',
  'Não foi possível enviar e-mail de recuperação':
    'No se puede enviar el correo electrónico de recuperación',
  'Não foi possível enviar e-mail': 'No se puede enviar el correo electronico',
  'Não foi possível reenviar e-mail de confirmação':
    'No se puede reenviar el correo electrónico de confirmación',
  'Não foi possível remover a reunião': 'No se pudo eliminar la reunión',
  'Não foi possível remover a reunião.': 'No se pudo eliminar la reunión.',
  'Não foi possível remover setor.': 'No fue posible remover sector.',
  'Não foi possível remover usuário': 'No se puede eliminar el usuario',
  'Não gravadas': 'No grabadas',
  'Não gravado': 'No grabado',
  'Não possui reuniões': 'No tiene reuniones',
  'Não é possível deletar organização com usuários ativos':
    'No se puede eliminar la organización con usuarios activos.',
  Não: 'No',
  Nível: 'Nivel',
  'Número da identidade': 'Número de la identidad',
  'Número da residência': 'Número de la residencia',
  'Número de reuniões': 'Número de reuniones',
  'Número inválido, ultrapassou o limite':
    'Número no es válido, excedió el limite',
  Número: 'Número',
  'O administrador geral tem permissões irrestritas no sistema. Esta alteração tem implicações no gerenciamento do sistema pois remove permissões de administração deste usuário.':
    'El administrador general tiene permisos ilimitados en el sistema. Este cambio tiene implicaciones en la gestión del sistema, ya que elimina los permisos de administración para este usuario.',
  'O campo "Título de reunião" é obrigatório.':
    'El campo "Título de reunión" es obligatorio.',
  'O e-mail não está em formato válido':
    'El correo electrónico no tiene un formato válido',
  'O e-mail é obrigatório': 'correo electronico es requerido',
  'O moderador precisa entrar na reunião antes de qualquer participante?':
    '¿El moderador necesita ingresar a la reunión antes que cualquier participante?',
  'O médico está pronto': 'El médico está listo',
  'O médico foi informado que': 'El médico fue informado que',
  'O médico já está na sala de atendimento':
    'El médico ya está en la sala de servicio',
  'O nome do setor deve conter no máximo 64 caracteres.':
    'El nombre del sector debe contener no máximo 64 caracteres.',
  'O nome é obrigatório': 'Se requiere el nombre',
  'O paciente está pronto': 'El paciente está listo',
  'O paciente foi informado que': 'El paciente fue informado que',
  'O telefone é obrigatório': 'Se requiere teléfono',
  OK: 'OK',
  ORGUNIT_CANNOT_DELETE_ACTIVE_USERS:
    'No se puede eliminar la unidad de organización con usuarios activos',
  ORGUNIT_COULD_NOT_LIST: 'No se pudo listar las unidades de organización',
  ORGUNIT_EXPIRED: 'Org expirada',
  ORGUNIT_LIMIT_REACHED:
    'La unidad de organización ha alcanzado el límite de cantidad de unidades',
  ORGUNIT_NAME_ALREADY_IN_USE:
    'Ya existe una unidad de organización con este nombre',
  ORGUNIT_NOT_CREATED: 'No se pudo crear la unidad de organización',
  ORGUNIT_NOT_DELETED: 'No se pudo eliminar la unidad de organización',
  ORGUNIT_NOT_FOUND: 'No se pudo encontrar la unidad de organización',
  ORGUNIT_NOT_UPDATED: 'No se pudo actualizar la unidad de organización',
  ORGUNIT_PARENT_NOT_FOUND:
    'No se pudo encontrar la unidad de organización principal',
  ORGUNIT_SHORT_ALREADY_IN_USE:
    'Ya existe una unidad de organización con este nombre corto',
  ORGUNIT_USER_LIMIT_REACHED:
    'La unidad de organización ya ha alcanzado el límite de cantidad de usuarios',
  ORG_ADMIN_COULD_NOT_LIST:
    'No se pudo listar los administradores de la organización',
  ORG_ADMIN_NOT_CREATED: 'No se pudo crear el administrador de la organización',
  ORG_ADMIN_NOT_DELETED:
    'No se pudo eliminar el administrador de la organización',
  ORG_ADMIN_NOT_FOUND:
    'No se pudo encontrar al administrador de la organización',
  ORG_APIKEY_DECRYPT_ERROR:
    'No se pudo descifrar la clave de API: Clave de API no válida o faltante',
  ORG_CANNOT_DELETE_ACTIVE_USERS:
    'No se puede eliminar la organización con usuarios activos',
  ORG_COULD_NOT_LINK_USER: 'No se pudo enlazar al usuario con la organización',
  ORG_COULD_NOT_LIST: 'No se pudo listar las organizaciones',
  ORG_EXPIRED: 'La organización ha expirado',
  ORG_LIMIT_REACHED: 'Se ha alcanzado el límite de unidades de organización',
  ORG_NOT_CREATED: 'No se pudo crear la organización',
  ORG_NOT_DELETED: 'No se pudo eliminar la organización',
  ORG_NOT_FOUND: 'No se pudo encontrar la organización',
  ORG_NOT_UPDATED: 'No se pudo actualizar la organización',
  ORG_SHORT_ALREADY_IN_USE: 'El nombre corto de la organización ya está en uso',
  ORG_USER_COULD_NOT_LIST: 'No se pudo listar los miembros de la organización',
  ORG_USER_LIMIT_REACHED:
    'La organización ya ha alcanzado el límite de usuarios',
  ORG_USER_NOT_DELETED: 'No se pudo eliminar al usuario de la organización',
  ORG_USER_NOT_FOUND: 'No se pudo encontrar al miembro de la organización',
  'Objeto da ação': 'Objeto de la acción',
  'Ocultar notificações': 'Esconder notificaciones',
  Ontem: 'Ayer',
  'Opção inválida': 'Opção no es válido',
  Organizador: 'Organizador',
  'Organizadores cadastrados': 'Organizadores registrados',
  'Organizadores do setor': 'Organizadores del sector',
  Organizadores: 'Organizadores',
  'Organização atualizada com sucesso': 'Organización actualizada con éxito!',
  'Organização criada com sucesso': 'Organización creada con éxito!',
  'Organização expira?': '¿La organización expira?',
  'Organização habilitada até:': 'Organización habilitada hasta:',
  'Organização removida com sucesso': 'Organización eliminada con éxito!',
  Organização: 'Organización',
  'Organizações cadastradas': 'Organizaciones registradas',
  Organizações: 'Organizaciones',
  Origem: 'Origen',
  Outros: 'Otros',
  PARAMETER_COULD_NOT_LIST: 'No se pudieron listar los parámetros del sistema',
  PARAMETER_NOT_FOUND: 'No se pudo encontrar el parámetro',
  PARAMETER_VALUE_ALREADY_EXISTS: 'El valor del parámetro ya existe',
  PARAMETER_VALUE_COULD_NOT_LIST:
    'No se pudieron listar los valores del parámetro',
  PARAMETER_VALUE_NOT_FOUND: 'No se pudo encontrar el valor del parámetro',
  'Paciente em questão': 'Paciente en cuestión',
  Paciente: 'Paciente',
  'Pacientes cadastrados': 'Pacientes registrados',
  Pacientes: 'Pacientes',
  'Painel de monitoramento': 'Panel de monitoreo',
  'Painel de reuniões': 'Panel de reuniones',
  Painel: 'Tablero',
  Painéis: 'Painéis',
  Papel: 'Papel',
  'Para reagendar ou alterar dados do atendimento, você precisa editar o atendimento nos campos ao lado.':
    'Para reprogramar o cambiar datos del servicio, usted necesitas editar el servicio en los campos al lado.',
  Participante: 'Partícipe',
  'Participantes da Consulta': 'Participantes de la Consulta',
  Participantes: 'Participantes',
  'Participar do atendimento': 'Participar del servicio',
  País: 'País',
  Pendente: 'Pendiente',
  'Perfil do usuário': 'Perfil del usuario',
  Perfil: 'Perfil',
  Permissão: 'Permiso',
  'Permissões concedidas': 'Permisos concedido',
  'Permite gravação de reuniões?': '¿Permite grabar las reuniones?',
  Período: 'Período',
  'Pesquisar usuário': 'Buscar usuario',
  Pesquisar: 'Buscar',
  'Política de privacidade': 'Política de privacidad',
  Posologia: 'Dosis',
  'Prazo para confirmação': 'Fecha límite para la confirmación',
  Prescrição: 'Prescripción',
  'Profissionais cadastrados': 'Profesionales registrados',
  'Profissionais de saúde': 'Profesionales de salud',
  'Profissionais participantes': 'Profesionales participantes',
  Profissionais: 'Profesionales',
  'Profissional da saúde': 'Profesional de la salud',
  'Profissional de saúde': 'Profesional de salud',
  'Profissional inválido ou não encontrado.':
    'Profesional no es válido o no encontrado.',
  Profissional: 'Profesional',
  Profissão: 'Profesión',
  'Pronto para participar?': '¿Pronto para participar?',
  Próximo: 'Siguiente',
  'Quais das reuniões associadas a este evento você deseja editar?':
    '¿Cuáles de las reuniones asociadas a este evento desea editar?',
  'Quais das reuniões associadas a este evento você deseja excluir?':
    '¿Cuáles de las reuniones asociadas a este evento desea eliminar?',
  Qualquer: 'Cualquier',
  'Quantidade de reuniões': 'Cantidad de reuniones',
  'Quarta-Feira': 'Miércoles',
  'Quinta-Feira': 'Jueves',
  'RG inválido, existe(m) digitos faltando':
    'RG no es válido, hay dígitos faltantes',
  'RG inválido, número de digitos ultrapassou o limite':
    'RG no es válido, número de dígitos excedió el límite',
  'RG inválido.': 'RG no es válido.',
  'Ranking de organizações': 'Ranking de la organización',
  'Ranking de usuários': 'Ranking de usuarios',
  Realizadas: 'Realizadas',
  'Receita Antimicrobiano': 'Prescripción Antimicrobiano',
  'Receita Especial': 'Prescripción Especial',
  'Receita Simples': 'Prescripción Sencillo',
  'Receita simples': 'Prescripción simples',
  'Recomendamos que entre com o microfone e câmera ligados.':
    'Recomendamos que entre con el micrófono y cámara activada.',
  'Recursos Humanos': 'Recursos Humanos',
  'Redefinir senha': 'Restablecer contraseña',
  Redefinir: 'Reiniciar',
  'Redefinição de senha': 'Restablecer contraseña',
  'Reenviar confirmação': 'Reenviar confirmación',
  'Reenviar email': 'Reenviar email',
  'Registrar reunião na blockchain': 'Registrar reunión en blockchain',
  'Registrar reunião na blockchain:': 'Registrar reunión en blockchain:',
  'Registro de conselho': 'Registro del consejo',
  'Registro no conselho profissional': 'Registro en consejo profesional',
  'Remover organização': 'Eliminar organización',
  'Remover setor': 'Remover sector',
  'Remover usuário': 'Eliminar usuario',
  'Remoção de Organização': 'Eliminación de la organización',
  'Remoção de Reunião': 'Eliminación de reunión',
  'Remoção de Usuário': 'Eliminación de usuario',
  'Remoção de setor': 'Remoção de sector',
  'Repita a senha de acesso': 'Repita de la contraseña de acceso',
  'Repita nova senha': 'Repita nueva contraseña',
  'Requisição de Exames': 'Solicitud de Exámenes',
  'Requisição de exame': 'Solicitud de examen',
  Retorno: 'Regreso',
  'Reunião Agendada com sucesso!': '¡Reunión programada exitosamente!',
  'Reunião excluida com sucesso': 'Reunión eliminada exitosamente',
  'Reunião instantânea': 'Reunión instantánea',
  Reunião: 'Reunión',
  'Reuniões gravadas': 'Reuniones grabadas',
  'Reuniões não gravadas': 'Reuniones no grabadas',
  'Reuniões transcritas': 'Reuniones transcritas',
  Reuniões: 'Reuniones',
  Rg: 'Rg',
  'Rua Av. Ladeira...': 'Calle Av. Ladeira...',
  SAC_REQUEST_ALREADY_ATTENDED: 'La solicitud SAC ya está siendo atendida',
  SAC_REQUEST_ALREADY_BELONGS_TO_TOPIC:
    'La solicitud SAC ya pertenece a este tema',
  SAC_REQUEST_ALREADY_FINISHED: 'La solicitud SAC ya ha sido finalizada',
  SAC_REQUEST_COULD_NOT_LIST: 'No se pudieron listar las solicitudes SAC',
  SAC_REQUEST_NAME_ALREADY_IN_USE:
    'El nombre de la solicitud SAC ya está en uso',
  SAC_REQUEST_NOT_ATTENDING: 'La solicitud SAC aún no está siendo atendida',
  SAC_REQUEST_NOT_CREATED: 'No se pudo crear la solicitud SAC',
  SAC_REQUEST_NOT_DELETED: 'No se pudo eliminar la solicitud SAC',
  SAC_REQUEST_NOT_FOUND: 'No se encontró la solicitud SAC',
  SAC_REQUEST_NOT_IN_SCHEDULE:
    'Solicitud no puede ser transferida, tema fuera de horario',
  SAC_REQUEST_NOT_SPECIALIST: 'El usuario no es especialista en este tema',
  SAC_REQUEST_NOT_THE_SPECIALIST:
    'El usuario no es el especialista para esta solicitud',
  SAC_REQUEST_NOT_UPDATED: 'No se pudo actualizar la solicitud SAC',
  SAC_REQUEST_NO_SCHEDULE: 'SAC no tiene un horario definido',
  SAC_REQUEST_NO_SPECIALIST: 'La solicitud SAC no tiene un especialista',
  SAC_SPECIALIST_NOT_FOUND: 'Especialista SAC no encontrado',
  SAC_TOPIC_COULD_NOT_LIST: 'No se pudieron listar los temas SAC',
  SAC_TOPIC_NAME_ALREADY_IN_USE: 'El nombre del tema SAC ya está en uso',
  SAC_TOPIC_NOT_ACTIVE: 'El tema SAC no está activo',
  SAC_TOPIC_NOT_CREATED: 'No se pudo crear el tema SAC',
  SAC_TOPIC_NOT_DELETED: 'No se pudo eliminar el tema SAC',
  SAC_TOPIC_NOT_FOUND: 'No se encontró el tema SAC',
  SAC_TOPIC_NOT_UPDATED: 'No se pudo actualizar el tema SAC',
  SESSION_MANAGER_BILLING_NOT_FOUND: 'No se pudo encontrar la facturación',
  SESSION_MANAGER_COULD_NOT_CREATE_ORG:
    'No se pudo crear la organización en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_CREATE_ORGUNIT:
    'No se pudo crear la unidad organizativa en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_CREATE_ROOM:
    'No se pudo crear la sala en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_CREATE_USER:
    'No se pudo crear el usuario en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_DELETE_ORG:
    'No se pudo eliminar la organización en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_DELETE_ORGUNIT:
    'No se pudo eliminar la unidad organizativa en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_DELETE_USER:
    'No se pudo eliminar el usuario en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_DOWNLOAD_REGISTRY:
    'No se pudo encontrar la descarga en el Administrador de Sesiones',
  SESSION_MANAGER_COULD_NOT_GET_DASHBOARD:
    'No se pudo obtener información del panel de control del administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_GET_INFO: 'No se pudo obtener información',
  SESSION_MANAGER_COULD_NOT_GET_RECORDING:
    'No se pudo encontrar la grabación en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_GET_REGISTRY:
    'No se pudo encontrar el registro en el Administrador de Sesiones',
  SESSION_MANAGER_COULD_NOT_GET_USER:
    'No se pudo obtener información del usuario del administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_JOIN_ROOM:
    'No se pudo unir a la sala en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_LIST_RECORDING:
    'No se pudo recuperar las grabaciones del administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_OPEN_ROOM:
    'No se pudo abrir la sala en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_UPDATE_ORG:
    'No se pudo actualizar la organización en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_UPDATE_ORGUNIT:
    'No se pudo actualizar la unidad organizativa en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_UPDATE_USER:
    'No se pudo actualizar el usuario en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_VERIFY_REGISTRY:
    'No se pudo verificar el registro en el Administrador de Sesiones',
  SESSION_MANAGER_ORGUNIT_ALREADY_EXISTS:
    'La unidad organizativa con este nombre corto ya existe',
  SESSION_MANAGER_ORG_ALREADY_EXISTS:
    'La organización con este nombre corto ya existe',
  SESSION_MANAGER_REGISTRY_NOT_FOUND: 'Registro no encontrado',
  SESSION_MANAGER_USER_ALREADY_EXISTS:
    'El usuario con este inicio de sesión ya existe',
  SETTING_DYNAMIC_GET:
    'El sistema parece estar faltando configuraciones obligatorias.',
  SKIN_NAME_ALREADY_IN_USE: 'El nombre del estilo ya está en uso',
  SKIN_NOT_CREATED: 'No se pudo crear el estilo',
  SKIN_NOT_DELETED: 'No se pudo eliminar el estilo',
  SKIN_NOT_FOUND: 'No se pudo encontrar el estilo',
  SKIN_NOT_UPDATED: 'No se pudo actualizar el estilo',
  STORAGE_COULD_NOT_DELETE_FILE:
    'No se pudo eliminar el archivo del servicio de almacenamiento',
  STORAGE_COULD_NOT_GET_URL:
    'No se pudo obtener la URL del archivo desde el servicio de almacenamiento',
  STORAGE_COULD_NOT_SEND_FILE:
    'No se pudo enviar el archivo desde el servicio de almacenamiento',
  SYSTEM_SETTING_NAME_ALREADY_IN_USE:
    'El nombre de la configuración del sistema ya está en uso',
  SYSTEM_SETTING_NOT_CREATED: 'No se pudo crear la configuración del sistema',
  SYSTEM_SETTING_NOT_DELETED:
    'No se pudo eliminar la configuración del sistema',
  SYSTEM_SETTING_NOT_FOUND: 'No se pudo encontrar la configuración del sistema',
  SYSTEM_SETTING_NOT_RESETED:
    'No se pudo restablecer la configuración del sistema',
  SYSTEM_SETTING_NOT_UPDATED:
    'No se pudo actualizar la configuración del sistema',
  'Sair do atendimento': 'Salir del servicio',
  Sair: 'Salir',
  'Salvar alterações': 'Guardar ediciones',
  Salvar: 'Guardar',
  'Segunda-Feira': 'Lunes',
  Segurança: 'Segurança',
  'Selecione a área de atendimento': 'Seleccione de la área de servicio',
  'Selecione seu Gênero': 'Seleccione tu Género',
  'Selecione seu certificado digital e finalize a criação do documento agora.':
    'Seleccione tu certificado digital y finalize de la creación del documento ahora.',
  'Selecione sua profissão': 'Seleccione tu profesion',
  'Selecione um Setor': 'Seleccione un Sector',
  'Selecione um certificado': 'Seleccione un certificado',
  'Selecione um paciente': 'Seleccione un paciente',
  'Selecione um papel': 'Seleccione un papel',
  'Selecione um profissional': 'Seleccione un profesional',
  'Selecione um tipo de documento clínico':
    'Seleccione un tipo de documento clínico',
  'Selecione um tipo': 'Seleccione un tipo',
  'Selecione uma Organização': 'Seleccione una organización',
  'Selecione uma data disponível': 'Seleccione una fecha disponible',
  'Selecione uma data': 'Seleccione una fecha',
  'Selecione uma especialidade': 'Seleccione una especialidade',
  'Selecione uma opção': 'Seleccione una opción',
  Selecione: 'Seleccione',
  'Seleção de papeis': 'Selección de roles',
  'Sem atividade': 'Sin actividad',
  'Senha alterada com sucesso!': '¡Contraseña alterada con éxito!',
  'Senha atual': 'Contraseña atual',
  'Senha de Acesso': 'Contraseña de Acceso',
  'Senha de': 'Contraseña de',
  'Senha inválida': 'Contraseña no es válido',
  Senha: 'Contraseña',
  'Senha:': 'Contraseña:',
  'Serviços Gerais': 'Servicios generales',
  'Serviços e faturamentos': 'Servicios y facturación',
  Serviços: 'Servicios',
  'Setor atualizado com sucesso!': 'Sector actualizado con éxito!',
  'Setor criado com sucesso!': 'Sector creado con éxito!',
  'Setor excluído com sucesso!': 'Sector excluído con éxito!',
  Setor: 'Sector',
  Setores: 'Setores',
  'Seu nome completo': 'Tu nombre completo',
  'Seu primeiro nome': 'Tu primero nombre',
  'Seu segundo nome': 'Tu segundo nombre',
  'Seus agendamentos aparecerão aqui. Não há itens a serem exibidos no momento.':
    'Tus servicios aparecerán aqui. No hay itens para ser mostrado en el momento.',
  'Sexta-Feira': 'Viernes',
  Sigla: 'Acrónimo',
  'Sim, cancelar': 'Si, cancelar',
  Sim: 'Si',
  Simples: 'Sencillo',
  'Solicite a farmácia que acesse o endereço':
    'Solicite de la farmacia que acesse el endereço',
  State: 'Estado',
  'Status do atendimento': 'Estado del servicio',
  Status: 'Estado',
  'Sua relação familiar com o paciente': 'Tu relación familiar con el paciente',
  Sábado: 'Sábado',
  TEMPLATE_NOT_FOUND: 'No se pudo encontrar la plantilla de mensaje',
  Tamanho: 'Tamaño',
  'Tecnologia da Informação': 'Tecnología de la informacion',
  'Telefone 1': 'Teléfono 1',
  'Telefone 2': 'Teléfono 2',
  'Telefone alternativo': 'Teléfono alternativo',
  'Telefone celular 1': 'Teléfono celular 1',
  'Telefone celular 2': 'Teléfono celular 2',
  'Telefone celular': 'Teléfono celular',
  'Telefone inválido.': 'Teléfono no es válido.',
  Telefone: 'Teléfono',
  'Termos de uso': 'Terminos de uso',
  'Terça-Feira': 'Martes',
  'Tipo de Atendimento': 'Tipo de Servicio',
  'Tipo de documento': 'Tipo de documento',
  'Tipo de serviço': 'Tipo de servicio',
  Tipo: 'Tipo',
  'Todas as organizações': 'Todas las organizaciones',
  'Todas as reuniões': 'Todas las reuniones',
  'Todos os setores': 'Todos los setores',
  Total: 'Total',
  'Transcrever reunião': 'Transcribir reunión',
  'Título de reunião': 'Título de reunión',
  Título: 'Título',
  'UF inválido, existe(m) caracteres faltando':
    'Estado no es válido, hay caracteres faltando',
  'UF inválido, número de caracteres ultrapassados':
    'Estado no es válido, número de caracteres excedió el límite',
  'UF inválido.': 'Estado no es válido.',
  UF: 'Estado',
  UNRECOGNIZED_ORDERBY: 'Columna de orden no reconocida',
  'URL copiada para a área de transferência': 'URL copiada al portapapeles',
  USERACTION_ALREADY_EXISTS: 'Ya existe una UserAction con este nombre',
  USERACTION_COULD_NOT_LIST:
    'No se pudieron listar las UserActions en la base de datos',
  USERACTION_GROUP_ALREADY_EXISTS:
    'Ya existe un UserActionGroup con este nombre',
  USERACTION_GROUP_COULD_NOT_LIST:
    'No se pudieron listar los UserActionGroup en la base de datos',
  USERACTION_GROUP_NOT_CREATED:
    'No se pudo crear UserActionGroup en la base de datos',
  USERACTION_GROUP_NOT_FOUND: 'No se pudo encontrar UserActionGroup',
  USERACTION_LOG_COULD_NOT_LIST:
    'No se pudieron listar los registros de usuario en la base de datos',
  USERACTION_NOT_CREATED: 'No se pudo crear UserAction en la base de datos',
  USERACTION_NOT_FOUND: 'No se pudo encontrar UserAction',
  USER_ALREADY_AGREED: 'El usuario ya ha aceptado los Términos',
  USER_ALREADY_CONFIRMED: 'El usuario ya ha confirmado',
  USER_ALREADY_EXISTS:
    'El nombre de usuario o correo electrónico ya están en uso',
  USER_ALREADY_IN_ORG:
    'El usuario ya está asignado a esta organización y unidad',
  USER_COULD_NOT_FIND_ORG: 'No se pudo encontrar la organización del usuario',
  USER_COULD_NOT_LIST:
    'No se pudieron listar las conferencias en la base de datos',
  USER_NOT_CREATED: 'No se pudo crear al usuario',
  USER_NOT_DELETED: 'No se pudo eliminar al usuario',
  USER_NOT_FOUND: 'No se pudo encontrar al usuario',
  USER_NOT_IN_ORG: 'El usuario no existe en esta organización',
  USER_NOT_UPDATED: 'No se pudo actualizar al usuario',
  USER_SUSPENDED: 'El usuario está suspendido',
  'Um Link estará disponível após a confirmação do agendamento.':
    'Un Link estará disponible despues de la confirmación del servicio.',
  'Um e-mail será enviado para o usuário com instruções para alteração de senha. O usuário ainda poderá fazer login com a senha atual até a alteração.':
    'Se enviará un correo electrónico al usuario con instrucciones para cambiar la contraseña. El usuario aún podrá iniciar sesión con la contraseña actual hasta que la cambie.',
  'Um link estará disponível após confirmação do agendamento':
    'Un link estará disponible despues de confirmación del servicio',
  'Uma mensagem será enviada para o endereço de e-mail acima para verificação da autenticidade e associação do endereço de e-mail ao usuário.':
    'Se enviará un mensaje a la dirección de correo electrónico anterior para verificar la autenticidad y asociar la dirección de correo electrónico con el usuario.',
  'Usuário criado com sucesso!': '¡Usuario creado con éxito!',
  'Usuário criado com sucesso': 'Usuario creado con éxito!',
  'Usuário desassociado com sucesso': 'Usuario desasociado con éxito',
  'Usuário desassociado do setor com sucesso!':
    'Usuario desasociado del sector',
  'Usuário não é mais administrador do sistema!':
    '¡El usuario ya no es administrador del sistema!',
  'Usuário promovido com sucesso!': '¡Usuario promovido con éxito!',
  'Usuário removido com sucesso': 'Usuario eliminado con éxito!',
  Usuário: 'Usuarios',
  'Usuários Cadastrados': 'Usuarios Registrados',
  'Usuários ativos': 'Usuarios activos',
  'Usuários lotados': 'Usuarios lotados',
  'Usuários padrão': 'Usuarios padrão',
  Usuários: 'Usuarios',
  Utilizados: 'Utilizados',
  'Utilize o código e senha para acessar':
    'Utilize el código y contraseña para acessar',
  VALIDATOR_DEFAULT_ISSUE: 'Error de validación desconocido',
  VALIDATOR_REQUIRED_FIELD: 'Falta campo requerido',
  VALIDATOR_UNRECOGNIZED_FIELD: 'Campo no reconocido',
  'Validador ICP Brasil': 'Validador ICP Brasil',
  'Valor Total': 'Valor total',
  Valor: 'Valor',
  Vencimento: 'Madurez',
  'Ver QR Code': 'Ver QR Code',
  'Ver organização': 'Ver organización',
  'Ver perfil': 'Ver perfil',
  'Ver setor': 'Ver sector',
  Vigência: 'Validez',
  'Vincular usuário existente': 'Vincular usuario existente',
  Visualização: 'Visualización',
  'Você já pode iniciar o atendimento': 'Usted ya puede iniciar el servicio',
  'Você já pode iniciar seu atendimento': 'Usted ya puede iniciar tu servicio',
  'Você não pode editar o usuário sem uma organização':
    'No puede editar el usuario sin una organización',
  'Você não tem nenhum documento associado ao atendimento.':
    'Usted no tienes ninguno documento associado al servicio.',
  'Você não tem nenhuma gravação.': 'Usted no tienes ninguno grabación.',
  'Você poderá fazer o download da gravação assim que disponível.':
    'Podrá descargar la grabación tan pronto como esté disponible.',
  'Você poderá fazer o download do registro assim que estiver disponível.':
    'Puede descargar el registro tan pronto como esté disponible.',
  'Você precisa concordar com os termos de uso da':
    'Usted precisa está de acuerdo con los terminos de uso da',
  'Você saiu da reunião': 'Saliste de la reunión',
  'Você será avisado quando o': 'Usted será avisado quando o',
  Voltar: 'Volver',
  Vínculo: 'Vínculo',
  WEBSOCKET_API_ERROR: 'Problema al enviar solicitudes a la API de WebSocket',
  'agendou o atendimento': 'programado el servicio',
  anos: 'anos',
  'atendimentos neste dia': 'servicios este día',
  'atualizou um atendimento': 'actualizado un servicio',
  'cancelou um atendimento': 'canceló un servicio',
  'clicando em avançar': 'clicando en avançar',
  'confirmou o atendimento': 'confirmó el servicio',
  'criou um atendimento com profissional acompanhante':
    'creado un servicio con profesional acompañante',
  'criou um atendimento': 'creado un servicio',
  'deletou um atendimento': 'eliminado un servicio',
  'entrou na sala de espera': 'entró en la sala de espera',
  'médico estiver pronto': 'médico estiver listo',
  'nesta sala de espera': 'en ésta sala de espera',
  'não confirmado': 'no confirmado',
  'não fez login': 'sin iniciar sesión',
  'para baixar sua receita digital.': 'para baixar tu prescripción digital.',
  'para visualizar o arquivo assinado pelo médico':
    'para ver el arquivo firmado por el médico',
  'plataforma e também concordar que o atendimento poderá':
    'plataforma y también está de acuerdo que el servicio podrá',
  reunião: 'reunión',
  'saiu da sala de espera': 'salió de la sala de espera',
  segurança: 'segurança',
  'sem prazo para confirmação': 'sin fecha límite para la confirmación',
  'ser gravado mediante escolha do médico.':
    'ser grabado mediante escolha del médico.',
  'termos de uso': 'terminos de uso',
  'termos de uso.': 'terminos de uso.',
  usado: 'usado',
  usuários: 'usuarios',
  'via o QR code do documento': 'via el QR code del documento',
  'você está na sala de espera': 'usted está en la sala de espera',
  Ínicio: 'Ínicio',
  Órgão: 'Órgano',
  'Última edição': 'Última edición',
  'Últimas conversas': 'Ultimas conversaciones',
  'Último acesso': 'Último acceso',
  'Último login em': 'Último inicio de sesión el',
  'Últimos 12 meses': 'Últimos 12 meses',
  'Últimos 30 dias': 'Últimos 30 dias',
  'Últimos 7 dias': 'Últimos 7 dias',
  'Últimos 90 dias': 'Últimos 90 dias',
};

export default esES;
