/* eslint-disable react/jsx-one-expression-per-line */
import React, { useMemo } from 'react';

import { Tags } from '@wisecare-tech/design-system-web';
import { iListSacRequest } from 'domain/usecases/sacRequest/remote';
import { History } from 'main/routes';
import { makeRemoteAcceptSacRequest } from 'main/factories/usecases/sacRequest/AcceptSacRequestFactory';
import { toast } from 'react-toastify';
import { Button } from '../UI';
import {
  Body,
  DataGroupContainer,
  Container,
  Header,
  ScrollContainer,
  TextBold,
  TextBoldTitle,
  Footer,
} from './styles';
import Translator from '../i18n/Translator';
import ConsultDetailFragment from './ConsultDetailFragment';

type CardArctions = {
  onClick?: () => void;
};

// TODO: Verify types to match endpoint.
interface CardRequestDetailsProps {
  isLoading?: boolean;
  cardActions?: boolean | undefined;
  cardDetails: iListSacRequest | undefined;
}

const CardRequestDetails: React.FC<CardRequestDetailsProps> = ({
  isLoading = false,
  cardDetails,
  cardActions,
}) => {
  const statusColor = useMemo(() => {
    const mapStatusColor = {
      RESOLVED: 'green',
      TRANSFERED: 'blue',
      UNRESOLVED: 'red',
      INTERRUPTED: 'white',
      undefined: 'white',
    };

    if (cardDetails?.outcome === undefined) return 'white';

    return mapStatusColor[cardDetails.outcome as keyof typeof mapStatusColor];
  }, [cardDetails?.outcome]);

  const statusText = useMemo(() => {
    const mapStatusText: Record<string, string> = {
      RESOLVED: 'Resolvido',
      TRANSFERED: 'Transferido',
      UNRESOLVED: 'Não resolvido',
      INTERRUPTED: 'Interrompido',
      undefined: 'Interrompido',
    };

    return mapStatusText[cardDetails?.outcome ?? ''];
  }, [cardDetails?.outcome]);

  const redirect = (request: number) => {
    makeRemoteAcceptSacRequest()
      .accept({ request })
      .then(res => {
        window.open(
          `/preconf?t=${res.roomName}&sr=${request}&sessionName=${res.sessionName}`,
          '_blank',
        );
      })
      .catch(() => {
        toast.error('Erro ao aceitar solicitação.');
      });
  };

  return (
    <Container>
      {cardDetails ? (
        <>
          <Header>
            <TextBoldTitle>Detalhes da solicitação</TextBoldTitle>
          </Header>
          <Body>
            <ScrollContainer>
              <DataGroupContainer>
                <ConsultDetailFragment
                  label="ID de atendimento"
                  content={String(cardDetails?.id)}
                  isLoading={isLoading}
                />
                {cardDetails?.specialist && (
                  <ConsultDetailFragment
                    label="Atendente"
                    content={cardDetails?.specialist?.fullname}
                    isLoading={isLoading}
                  />
                )}
                <ConsultDetailFragment
                  label="Assunto"
                  content={cardDetails?.descr}
                  isLoading={isLoading}
                />
              </DataGroupContainer>
              {cardDetails && (
                <DataGroupContainer>
                  {cardDetails.outcome && (
                    <Tags text={statusText} variant={statusColor as any} />
                  )}
                  <ConsultDetailFragment
                    isLoading={isLoading}
                    content={cardDetails?.result}
                  />
                </DataGroupContainer>
              )}
              <DataGroupContainer>
                <ConsultDetailFragment
                  label="Nome"
                  content={cardDetails?.name}
                  isLoading={isLoading}
                />
                <ConsultDetailFragment
                  label="CPF"
                  content={cardDetails?.docValue}
                  isLoading={isLoading}
                />
                <ConsultDetailFragment
                  label="E-mail"
                  content={cardDetails?.email}
                  isLoading={isLoading}
                />
                <ConsultDetailFragment
                  label="Telefone"
                  content={cardDetails?.phone}
                  isLoading={isLoading}
                />
              </DataGroupContainer>
              <DataGroupContainer>
                <TextBold>Descrição</TextBold>

                <ConsultDetailFragment
                  content={cardDetails?.descr}
                  isLoading={isLoading}
                />
              </DataGroupContainer>
            </ScrollContainer>
          </Body>
          {cardActions && (
            <Footer>
              {/* TODO: Add DS Button + Icon */}
              {/* <Button onClick={() => console.log('Click')}> */}
              <Button onClick={() => redirect(cardDetails.id)}>
                {Translator('Aceitar Solicitação')}
              </Button>
            </Footer>
          )}
        </>
      ) : (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Selecione um atendimento ao lado
        </div>
      )}
    </Container>
  );
};

export default CardRequestDetails;
