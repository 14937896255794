/* eslint-disable import/no-extraneous-dependencies */
import {
  Avatar,
  Icon,
  IconButton,
  Typography,
} from '@wisecare-tech/design-system-web';
import { iStore } from 'domain/interfaces/models';
import { Select } from 'presentation/components/UI';
import { useTopicConfig } from 'presentation/hooks/sac/createTopicConfig';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  AttendantsContainer,
  AttendantsItem,
  Content,
  ContentAttendant,
  Header,
  Name,
  NoAttendants,
  NoAttendantsRadius,
  NoAttendantsText,
  Right,
  SectionsContainer,
} from './styles';

export const AttendantsStepTwo: React.FC = () => {
  const { records: users } = useSelector((store: iStore) => store.user);

  const { handleAddAttendants, handleRemoveAttendants, getAttendant } =
    useTopicConfig();

  const { control, watch } = useFormContext();

  const attendantsList: number[] = watch('attendants');

  return (
    <SectionsContainer>
      <Content>
        <Header>
          <Typography variant="b2_14semibold">Atendentes</Typography>
        </Header>
        <ContentAttendant>
          <Select
            label="Selecione os atendentes"
            placeholder="Selecione os atendentes"
            onChange={e => handleAddAttendants(Number(e.target.value))}
          >
            <option value={-1}>Selecione um atendente</option>
            {users
              .filter(
                user =>
                  !attendantsList.some(attendant => attendant === user.id),
              )
              .map(user => (
                <option value={user.id}>
                  {`${user.firstName} ${user.lastName}`}
                </option>
              ))}
          </Select>

          {attendantsList.length > 0 ? (
            <AttendantsContainer>
              {attendantsList.map(attendant => {
                const user = getAttendant(attendant);
                const fullName = `${user?.firstName} ${user?.lastName}`;
                return (
                  <AttendantsItem>
                    <Right>
                      <Avatar
                        size="sm"
                        name={fullName.toUpperCase()}
                        src={user?.avatar}
                      />
                      <Name>
                        <Typography variant="b1_16regular">
                          {fullName}
                        </Typography>
                        <Typography
                          variant="b4_14regular"
                          style={{ color: '#656A6E' }}
                        >
                          {user?.sysAdmin ? 'Administrador' : 'Usuário padrão'}
                        </Typography>
                      </Name>
                    </Right>
                    <IconButton
                      icon="delete"
                      type="button"
                      variant="destructive"
                      size="sm"
                      onClick={() => handleRemoveAttendants(Number(user?.id))}
                    />
                  </AttendantsItem>
                );
              })}
            </AttendantsContainer>
          ) : (
            <NoAttendants>
              <NoAttendantsRadius>
                <Icon name="person_off" size="h1" color="white-7" />
              </NoAttendantsRadius>
              <NoAttendantsText>
                <Typography variant="t4_16medium">
                  Nenhum atendente adicionado
                </Typography>
                <Typography variant="b1_16regular" style={{ color: '#656A6E' }}>
                  Até o momento, nenhum usuário foi cadastrado como atendente
                  desse assunto.
                </Typography>
              </NoAttendantsText>
            </NoAttendants>
          )}
        </ContentAttendant>
      </Content>
    </SectionsContainer>
  );
};
