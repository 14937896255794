import React, { useCallback, useEffect } from 'react';
import { ConfigTopicProvider } from 'presentation/hooks/sac/createTopicConfig';
import { CreateSacTopic } from 'presentation/pages/CreateSacTopic';
import { makeReduxListFilteredUser } from '../../usecases/user/GetFilteredUsers';

export const CreateSacTopicFactory: React.FC = () => {
  const loadUsers = useCallback(() => {
    const listUsers = makeReduxListFilteredUser();
    listUsers.list({
      dataControl: {
        limit: 9999,
      },
    });
  }, []);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return (
    <ConfigTopicProvider>
      <CreateSacTopic />
    </ConfigTopicProvider>
  );
};
