import { createIntl, createIntlCache } from 'react-intl';
import { getLocale } from 'utils/getLocale';
import { defaultTranslations, psiTranslations } from 'infra/i18n/locales';

const cache = createIntlCache();

const Translator = (data: string): string => {
  try {
    const systemName = localStorage.getItem('systemName');
    const locale = getLocale();

    const intl = createIntl(
      {
        locale: String(locale),
        messages:
          systemName === 'V4HPsi' || systemName === 'WisePsi'
            ? psiTranslations[locale]
            : defaultTranslations[locale],
      },
      cache,
    );

    return intl.formatMessage({ id: data });
  } catch (error) {
    // console.warn('>>> Failed to translate', error);

    return data;
  }
};

export default Translator;
