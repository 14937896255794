import React, { Suspense } from 'react';

import { MessageOptions } from 'domain/interfaces/redux/message';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ownProps } from './interface';
import { ConnectComponent } from './mapper/mapperMessage';

import AuditRecordDrawer from '../AuditRecord';
import AdvConfigModal from './advConfig';
import ConfirmModal from './confirm';
import ErrorModal from './error';
import FirstAccessModal from './firstAccess';
import InfoMarkdownModal from './infoMarkdown';
import SelectBondAdmin from './selectBondAdmin';
import SuccessModal from './success';
import InstantConference from './instantConference';
import RequestWaitingRoom from './requestWaitingRoom';
import ConferenceAccessDenied from './conferenceAccessDenied';
import RecurringConferenceModal from '../recurringConferenceModal';
import AppointmentWithdrew from '../modalsSAC/AppointmentWithdrew';
import NotesHistory from '../modalsSAC/NotesHistory';
import FinishAppointmentSAC from '../modalsSAC/FinishAppointment';
import { ChooseSubjectModal } from '../sac/ChooseSubjectModal';
import SacProfessionalReady from './sacProfessionalReady';
import { SupportInformation } from '../modalsSAC/SupportInformation/SupportInformation';
import { IncorporationCode } from '../modalsSAC/IncorporationCode/IncorporationCode';
import { HelpLine } from '../modalsSAC/HelpLine/HelpLine';

const Notifications: React.FC<ownProps> = ({
  children,
  message,
}): JSX.Element => {

      const LoadingCircularProgress = () => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#fff',
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        );
      };
  return (
    <>
      <Suspense fallback={<LoadingCircularProgress />}>
        <SelectBondAdmin message={message} />
        <SuccessModal message={message} />
        <ErrorModal message={message} />
        <ConfirmModal message={message} />
        <InfoMarkdownModal message={message} />
        <FirstAccessModal message={message} />
        <AdvConfigModal message={message} />
        <AuditRecordDrawer message={message} />
        <InstantConference message={message} />
        <ConferenceAccessDenied message={message} />
        <RecurringConferenceModal message={message} />
        <FinishAppointmentSAC message={message} />
        <AppointmentWithdrew message={message} />
        <NotesHistory message={message} />
        <ChooseSubjectModal message={message} />
        {message.active === MessageOptions.sacProfessionalReady && (
        <SacProfessionalReady message={message} />
      )}
        <SupportInformation message={message} />
        <IncorporationCode message={message} />
        <HelpLine message={message} />
        {children}
      </Suspense>
    </>
  );
};

export default ConnectComponent(Notifications);
