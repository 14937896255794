import {
  Button,
  Icon,
  theme,
  Typography,
} from '@wisecare-tech/design-system-web';
import React from 'react';
import {
  ContainerIcon,
  EmptyStateSection,
  SacEmptyStateHotlineContent,
} from './styles';

export const HotlineEmptyState: React.FC = () => {
  return (
    <EmptyStateSection>
      <SacEmptyStateHotlineContent>
        <ContainerIcon>
          <Icon name="chat_add_on" size="h1" color={theme.colors.white[7]} />
        </ContainerIcon>
        <Typography
          variant="t4_16medium"
          style={{ color: theme.colors.black[1] }}
        >
          A lista de assuntos está vazia
        </Typography>
        <Typography
          variant="b1_16regular"
          style={{ lineHeight: '18.75px', color: theme.colors.black[4] }}
        >
          Até o momento, nenhuma linha de atendimento foi adicionada.
          <span id="message-action">
            Você pode adicioná-los usando o botão abaixo:
          </span>
        </Typography>
        <Button icon="add" size="lg">
          Criar linha de atendimento
        </Button>
      </SacEmptyStateHotlineContent>
    </EmptyStateSection>
  );
};
