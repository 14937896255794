import { LeftSacSpecialist } from 'domain/usecases/sacSpecialist/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  BadGateway,
} from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteLeftSacSpecialist implements LeftSacSpecialist {
  private readonly url: string;

  private readonly httpClient: HttpClient<LeftSacSpecialist.Model>;

  constructor(url: string, httClient: HttpClient<LeftSacSpecialist.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  left = async (
    params: LeftSacSpecialist.Params,
  ): Promise<LeftSacSpecialist.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url.replace('{userId}', params.userId.toString()),
      method: 'post',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
