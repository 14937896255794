import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import WiseAPI from 'wise-api';
import IStartConferenceDTO from 'wise-api/lib/resources/session/dtos/IStartConferenceDTO';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { iStore } from 'domain/interfaces/models';
import { makeRemoteJoinConference } from 'main/factories/usecases/conference/JoinConferenceFactory';
import { ThemeContext } from 'App';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { History } from 'main/routes';
import { closeModal } from 'utils/closeModal';
import { makeRemoteLeaveConference } from 'main/factories/usecases/conference/LeaveConferenceFactory';
import RequestWaitingRoom from 'presentation/components/messages/requestWaitingRoom';
import { JoinConference } from 'domain/usecases/conference/remote';
import { Sidebar } from 'presentation/components/sidebar';
import { disconnectSocket, initializeSocket } from 'infra/socket/SackSocket';
import { request } from 'http';
import { Container } from './styles/styledConf';

export interface ParamsState {
  appointmentId: string;
  m?: boolean;
  c?: boolean;
  u?: string;
  t?: string;
}

export interface QueryState {
  appointmentId: string;
}

export const Conf: React.FC = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const { theme } = useContext(ThemeContext);

  const { search } = useLocation();
  const { push } = useHistory();

  const params = useMemo(
    () => ({
      m: new URLSearchParams(search).get('m') ?? false,
      c: new URLSearchParams(search).get('c') ?? false,
      appointmentId: new URLSearchParams(search).get('appointmentId'),
      t: new URLSearchParams(search).get('t') ?? null,
      u: new URLSearchParams(search).get('u') ?? null,
      s: new URLSearchParams(search).get('sessionName') ?? null,
      sr: new URLSearchParams(search).get('sr') ?? null,
      ak: new URLSearchParams(search).get('ak') ?? undefined,
    }),
    [search],
  );
  console.log('>>>params', params);
  const { m, c, appointmentId, t, u, s, sr, ak } = params;

  const { auth, conference } = useSelector((store: iStore) => store);
  const timeoutMeet: { current: NodeJS.Timeout | null } = useRef(null);

  useEffect(() => {
    if (sr) initializeSocket(Number(sr));

    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    const start = async (): Promise<void> => {
      try {
        const response =
          s !== null
            ? ({ sessionName: s } as JoinConference.Model)
            : await makeRemoteJoinConference().join({
                conferenceShort: String(t),
              });

        const { sessionName } = response;

        const foundConference = conference?.records?.find(
          item => item.short === params.t,
        );

        const buttons = [
          'microphone',
          'camera',
          'closedcaptions',
          'desktop',
          'fullscreen',
          'fodeviceselection',
          'hangup',
          'profile',
          'info',
          'chat',
          'sharedvideo',
          'settings',
          'raisehand',
          'videoquality',
          'filmstrip',
          'invite',
          'feedback',
          'stats',
          'shortcuts',
          'tileview',
          'videobackgroundblur',
          'download',
          'help',
          'avatar',
          'libras',
          'transcription',
        ];

        if (response?.moderator && response?.recording)
          buttons.push(...['recording', 'livestreaming', 'mute-everyone']);

        if (response?.moderator && !response?.recording)
          makeReduxActiveMessage().active({
            active: 'error',
            title: 'Permissão de gravação',
            content: 'Organização não permite gravações de conferência.',
            actionOk: () => closeModal(),
            actionCancel: () => closeModal(),
          });

        console.log(
          '>>> Debug baseURL: ',
          window.config.connection.sessionManagerUrl,
        );
        console.log('>>> Debug domain: ', window.config.connection.confUrl);

        const v4h = await WiseAPI({
          baseUrl: `${window.config.connection.sessionManagerUrl}/api`,
          domain: `${window.config.connection.confUrl}`,
        });
        await v4h.session.startConference(sessionName, {
          parentNode: document.getElementById('meet'),
          userInfo: {
            displayName: u ?? 'Convidado',
          },
          shareLink: `${window.location.origin}/${
            foundConference?.type === 'RESTRICT' ? 'waitingRoom' : 'preconf'
          }?t=${String(t)}`,
          startWithAudioMuted: !(m === 'true'),
          startWithVideoMuted: !(c === 'true'),
          buttons: buttons as unknown as IStartConferenceDTO['buttons'],
          onClose: () => {
            if (sr) {
              if (auth?.access?.token?.length) {
                makeReduxActiveMessage().active({
                  active: 'finishAppointmentSAC',
                  actionOk: () => {
                    push(`/sac/list`);
                    closeModal();
                  },
                  data: {
                    request: sr,
                  },
                  actionCancel: () => closeModal(),
                });

                return;
              }

              push(`/leave?t=${t}&u=${u}&c=${c}&m=${m}&sr=${sr}&ak=${ak}`);

              return;
            }

            push(`/leave?t=${t}&u=${u}&c=${c}&m=${m}`);
          },
          moderator: response.moderator,
          onModeratorReject: () => {
            push(`/leave?t=${t}&u=${u}&c=${c}&m=${m}${sr ? `&sr=${sr}` : ''}`);
          },
          logo: theme.images.logo.opacity,
        });
        setLoading(false);
      } catch (error) {
        console.log('>>>error', error);
        setLoading(false);
        // toast.error(
        //   'Não localizamos o agendamento desta reunião. Verifique com o organizador da reunião.',
        // );

        History.getHistory().push('/home');
      }
    };

    start();

    // timeoutMeet.current = setTimeout(() => {
    //   const meetChildNodesCount =
    //     document.getElementById('meet')?.childNodes.length;

    //   if (!meetChildNodesCount) start();
    // }, 2000);

    return () => {
      if (timeoutMeet?.current) clearTimeout(timeoutMeet.current);
      if (appointmentId) console.log('### left');
      if (t && !sr) makeRemoteLeaveConference().leave({ conferenceShort: t });
    };
  }, [appointmentId, timeoutMeet, auth.user, c, m, t, u]);

  return (
    <Container id="meet">
      {loading && <div>loading...</div>}
      {!loading && sr && <Sidebar />}
      <RequestWaitingRoom />
    </Container>
  );
};

export default Conf;
