import React, { useEffect, useState } from 'react';
import {
  Typography,
  IconButton,
  Textarea,
  Button,
  Icon,
} from '@wisecare-tech/design-system-web';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { makeRemoteGetPublicTopics } from 'main/factories/usecases/sacTopic/GetPublicTopicsFactory';
import { GetPublicTopics } from 'domain/usecases/sacTopic/remote/GetPublicTopics';
import { toast } from 'react-toastify';
import { serviceTransferSchema } from 'validation/sac/ServiceTransferValidator';
import { makeRemoteTransferSacRequest } from 'main/factories/usecases/sacRequest/TransferSacRequestFactory';
import { History } from 'main/routes';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models/Store';
import socket from 'infra/socket';
import {
  Container,
  Header,
  Subject,
  Label,
  ErrorMessage,
} from './StyledServiceTransfer';
import { Select } from '../../UI';

interface iServiceTransfer {
  onClose: () => void;
}

interface ServiceTransferForm {
  topic: string;
  reason: string;
}

export const ServiceTransfer: React.FC<iServiceTransfer> = ({ onClose }) => {
  const [publicTopics, setPublicTopics] = useState<GetPublicTopics.Model>();

  const { auth } = useSelector((store: iStore) => store);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ServiceTransferForm>({
    resolver: yupResolver(serviceTransferSchema) as any,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const requestId = new URLSearchParams(window.location.search).get('sr');

  const onSubmit = (form: ServiceTransferForm) => {
    if (!requestId) return;

    makeRemoteTransferSacRequest()
      .transfer({
        request: requestId,
        token: new URLSearchParams(window.location.search).get('token') ?? '',
        body: {
          topic: Number(form.topic),
          reason: form.reason,
        },
      })
      .then(() => {
        toast.success('Atendimento será transferido com sucesso');
        History.getHistory().push('/sac/list');
      })
      .catch(() => {
        toast.error('Erro ao transferir o atendimento');
      });
  };

  useEffect(() => {
    makeRemoteGetPublicTopics()
      .list({
        token:
          new URLSearchParams(window.location.search).get('token') ?? undefined,
      })
      .then(response => {
        setPublicTopics(response);
      })
      .catch(() => {
        toast.error('Erro ao buscar os assuntos');
      });
  }, []);

  return (
    <Container>
      <Header>
        <div />
        <Typography variant="t3_16semibold">
          Transferência de atendimento
        </Typography>

        <IconButton
          icon="close"
          variant="primary"
          size="sm"
          onClick={onClose}
        />
      </Header>

      <Subject onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="b4_14regular">
          Em caso de erro na escolha da linha do atendimento, é possível
          transferir o atendimento. Selecione um outro assunto abaixo.
        </Typography>
        <Controller
          control={control}
          name="topic"
          render={({ field }) => (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
              <Label>
                <Typography variant="b3_14medium">Assunto</Typography>
              </Label>
              <Select {...field} error={Boolean(errors?.topic)}>
                <option value="">Selecione</option>
                {publicTopics?.map(topic => (
                  <option key={topic.id} value={topic.id}>
                    {topic.title}
                  </option>
                ))}
              </Select>
              {errors?.topic && (
                <ErrorMessage>
                  <Icon name="info" color="red-7" />
                  {errors?.topic?.message}
                </ErrorMessage>
              )}
            </div>
          )}
        />
        <Controller
          control={control}
          name="reason"
          render={({ field }) => (
            <Textarea
              {...field}
              label="Motivo da transferência"
              placeholder="Escreva o motivo"
              helperText={errors?.reason?.message}
              status={errors?.reason ? 'error' : 'default'}
              required
            />
          )}
        />

        <div
          style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}
        >
          <Button
            variant="primary"
            size="lg"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid}
          >
            Encaminhar atendimento
          </Button>
        </div>
      </Subject>
    </Container>
  );
};
