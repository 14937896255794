import { DaysAbbreviation } from 'presentation/hooks/sac/types';
import { array, InferType, mixed, object, string } from 'yup';

export const createSacConfigSchema = object({
  phones: array()
    .of(string().required('O telefone é obrigatório').max(16, 'O telefone deve ter no máximo 16 caracteres'))
    .min(1, 'Adicione ao menos um telefone'),
  supportEmail: string().required('O e-mail de suporte é obrigatório'),
  scheduleType: mixed<'FLEXIBLE' | 'FIXED'>().default('FIXED'),
  schedule: array()
    .of(
      object({
        day: mixed<DaysAbbreviation>()
          .oneOf(['MO', 'TU', 'WE', 'TH', 'FR', 'ST', 'SU'])
          .required('Selecione ao menos um dia.'),
        hours: array()
          .of(
            object({
              start: string().required('O horário de início é obrigatório'),
              finish: string().required('O horário de término é obrigatório'),
            }),
          )
          .required('Adicione ao menos um horário')
          .min(1, 'Adicione ao menos um horário'),
      }),
    )
    .required('Adicione ao menos um dia')
    .min(1, 'Adicione ao menos um dia'),
});

export type CreateSacConfigSchema = InferType<typeof createSacConfigSchema>;
