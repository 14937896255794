import { Typography } from '@wisecare-tech/design-system-web';
import React from 'react';
import { StOptionItem } from './styles';

interface Props {
  active?: boolean;
  label: string;
}

export const OptionItem: React.FC<Props> = ({ label, active }) => {
  return (
    <StOptionItem active={active}>
      <Typography variant="t3_16semibold">{label}</Typography>
    </StOptionItem>
  );
};
