import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { LeftSacRequest as UsecaseRemoteLeftSacRequest } from 'domain/usecases/sacRequest/remote';
// import { LeftSacRequest as UsecaseReduxLeftSacRequest } from 'domain/usecases/sacRequest/redux';

import { RemoteLeftSacRequest } from 'data/repository/sacRequest';
// import { ReduxLeftSacRequest } from 'data/store/reducer/sacRequest/usecases';

/**
 * send request via API.
 */
export const makeRemoteLeftSacRequest = (): UsecaseRemoteLeftSacRequest =>
  new RemoteLeftSacRequest(
    makeApiUrl('/sac/requests/{request}/LEFT'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxLeftSacRequest =
  (): UsecaseReduxLeftSacRequest =>
    new ReduxLeftSacRequest(); */
