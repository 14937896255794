import { Translations } from '.';

const enUS: Record<Translations, string> = {
  ' de Abril': ' April',
  ' de Agosto': ' August',
  ' de Dezembro': ' December',
  ' de Fevereiro': ' February',
  ' de Janeiro': ' January',
  ' de Julho': ' July',
  ' de Junho': ' June',
  ' de Maio': ' May',
  ' de Março': ' March',
  ' de Novembro': ' November',
  ' de Outubro': ' October',
  ' de Setembro': ' September',
  '+Adicionar': '+Add',
  'A sigla deve conter no máximo 32 caracteres.': 'Maximum 32 characters',
  ADDRESS_INCOMPLETE: 'Address is incomplete',
  ADDRESS_NOT_CREATED: 'Could not create address',
  ADDRESS_NOT_UPDATED: 'Could not update address',
  APPOINTMENT_CONTROL_API_CREATE_ERROR:
    'Problem to send requests to create appointment control api',
  APPOINTMENT_CONTROL_API_DELETE_ERROR:
    'Problem to send requests to delete rappointment control api',
  APPOINTMENT_CONTROL_API_LIST_ERROR:
    'Problem to send requests to list rappointment control api',
  AUTHORIZATION_API_COULD_NOT_ACTION_EXISTS:
    'Could not check if action exists in authorization system',
  AUTHORIZATION_API_COULD_NOT_CREATE_ACTION:
    'Could not create action in authorization system',
  AUTHORIZATION_API_COULD_NOT_CREATE_ACTION_TO_ROLE:
    'Could not create action to role in authorization system',
  AUTHORIZATION_API_COULD_NOT_CREATE_RESOURCE:
    'Could not create resource in authorization system',
  AUTHORIZATION_API_COULD_NOT_CREATE_ROLE:
    'Could not create role in authorization system',
  AUTHORIZATION_API_COULD_NOT_CREATE_USER:
    'Could not create user in authorization system',
  AUTHORIZATION_API_COULD_NOT_GET_USER_ACL:
    'Could not get user ACL in authorization system',
  AUTHORIZATION_API_COULD_NOT_GIVE_PERMISSION_TO_ROLE:
    'Could not give permission to role in authorization system',
  AUTHORIZATION_API_COULD_NOT_GIVE_PERMISSION_TO_USER:
    'Could not give permission to user in authorization system',
  AUTHORIZATION_API_COULD_NOT_GIVE_ROLE_TO_USER:
    'Could not give role to user in authorization system',
  AUTHORIZATION_API_COULD_NOT_LIST_ACTIONS:
    'Could not list authorization actions',
  AUTHORIZATION_API_COULD_NOT_LIST_RESOURCES:
    'Could not list authorization resources',
  AUTHORIZATION_API_COULD_NOT_LIST_ROLES: 'Could not list authorization roles',
  AUTHORIZATION_API_COULD_NOT_REMOVE_PERMISSION_TO_USER:
    'Could not remove permission from user in authorization system',
  AUTHORIZATION_API_COULD_NOT_REMOVE_ROLE_PERMISSION:
    'Could not remove role permission in authorization system',
  AUTHORIZATION_API_COULD_NOT_REMOVE_ROLE_TO_USER:
    'Could not remove role from user in authorization system',
  AUTHORIZATION_API_COULD_NOT_REMOVE_USER:
    'Could not remove user in authorization system',
  AUTHORIZATION_API_COULD_NOT_RESOURCE_EXISTS:
    'Could not check if resource exists in authorization system',
  AUTHORIZATION_API_COULD_NOT_ROLE_ACTION_EXISTS:
    'Could not check if role action exists in authorization system',
  AUTHORIZATION_API_COULD_NOT_ROLE_EXISTS: 'Could not check if role exists',
  AUTHORIZATION_API_COULD_NOT_ROLE_PERMISSIONS:
    'Could not list role permissions',
  AUTHORIZATION_API_COULD_NOT_UPDATE_RESOURCE_TO_RESOURCE:
    'Could not update resource to resource in authorization system',
  AUTHORIZATION_API_COULD_NOT_UPDATE_ROLE:
    'Could not update role in authorization system',
  AUTHORIZATION_API_COULD_NOT_UPDATE_USER:
    'Could not update user in authorization system',
  AUTHORIZATION_API_LOGIN_ERROR: 'Could not login into authorization system',
  AUTH_ACL_NOT_PROVIDED: "User\\'s ACL must be provided for this action",
  AUTH_CONFIRMATION_TOKEN_INCORRECT: 'Confirmation token is incorrect',
  AUTH_COULD_NOT_LIST_USER:
    'Could not list user password in authentication service',
  AUTH_COULD_NOT_RESET_PASSWORD:
    'Could not reset user password in authentication service',
  AUTH_EXTERNAL_TOKEN_INVALID: 'Invalid external token',
  AUTH_FAILED: 'Authentication failed',
  AUTH_FORBIDDEN: 'You have not permission to execute action',
  AUTH_MISSING_AUTHORIZATION_HEADER: 'Missing authorization header',
  AUTH_MULTIPLES_ORG_AVAILABLE: 'Auth multiples org available',
  AUTH_NOT_CREATED_USER: 'Could not create user in authentication service',
  AUTH_NOT_DELETED_USER: 'Could not delete user in authentication service',
  AUTH_NOT_UPDATED_USER: 'Could not update user in authentication service',
  AUTH_PWD_RECOVERY_TOKEN_INCORRECT: 'Password recovery token is incorrect',
  AUTH_REFRESH_FAILED: 'Could not refresh login',
  AUTH_REFRESH_INVALID_TOKEN: 'Invalid refresh token',
  AUTH_USER_DOES_NOT_MATCH_WITH_TOKEN: 'The user id does not match with token',
  'Aceite os termos de uso e de gravação para iniciar.':
    'Accept the terms of use and recording to start.',
  'Aceito os': 'I accept the',
  'Aceito que o atendimento poderá ser gravado':
    'I accept that the call may be recorded.',
  'Aceito que o atendimento poderá ser gravado.':
    'I accept that the service can be recorded.',
  Acessar: 'Acess',
  'Acessas farmácia': 'Accesss drugstore',
  'Acesse o documento': 'Access the document',
  'Acesso para farmácia': 'Pharmacy access',
  'Acesso à Receita': 'Access to Prescription',
  Add: 'Add',
  'Adicionar novo usuário': 'Add new user',
  'Adicione participantes ou digite um novo e-mail':
    'Add participants or enter a new email',
  'Adicione um título': 'Add a title',
  'Adicione uma descrição': 'Add a description',
  'Administrador Geral': 'General Administrator',
  Administrador: 'Administrador',
  'Administradores de sistema': 'System administrators',
  Administradores: 'Administrators',
  Administração: 'Administration',
  'Agenda de atendimentos': 'Appointment schedule',
  Agenda: 'Schedule',
  Agendadas: 'Scheduled',
  Agendado: 'Scheduled',
  Agendamentos: 'Appointments',
  'Agendar novo atendimento': 'Schedule new appointment',
  'Agendar reunião': 'Schedule meeting',
  Agendar: 'Schedule',
  Agora: 'Now',
  'Aguarde nesta tela.': 'Wait on this screen.',
  'Aguarde o início do atendimento': 'Wait for the service to start',
  'Altere algum campo para atualizar o setor.':
    'Change any field to update the sector.',
  Anotações: 'Notes',
  Anterior: 'Previous',
  'Antes de entrar na sala de espera': 'Before entering the waiting room',
  'Antes de iniciar': 'Before you start',
  'Ao excluir um usuário, as informações pertinentes a eles como dados de atendimentos, documentos clínicos e de apoio como também as conversas em chat, serão mantidas de acordo com as politicas de privacidade e termos de uso da plataforma V4H atendimento. ':
    "When removing the user, some of the information associated with it (medical appointment data, clinical and support documents, chat conversations), will be maintained in accordance with the service's privacy policies and terms of use.",
  'Ao remover o usuário, algumas das informações associadas a ele (dados de consultas médicas, documentos clínicos e de suporte, conversas por chat) serão mantidas de acordo com as políticas de privacidade e termos de uso do serviço.':
    "When removing the user, some of the information associated with it (medical appointment data, clinical and support documents, chat conversations) will be kept in accordance with the service's privacy policies and terms of use.",
  'Apenas esta reunião': 'Only this meeting',
  'Armazenamento total': 'Total storage',
  Armazenamento: 'Storage',
  'Assessoria de Imprensa': 'Press office',
  Assessoria: 'Advice',
  Assinado: 'Signed Document',
  'Assinar Documento': 'Assinar Documento',
  'Assinar agora': 'Signed Now',
  'Assinar depois': 'Signed After',
  'Assinatura do documento': 'Document signature',
  Assinatura: 'Patient',
  'Atendimento cancelado': 'Service was canceled',
  Atendimento: 'Attendance',
  'Atestado Médico': 'Medical Certificate',
  Ativado: 'Activated',
  'Atualizar imagem do usuário': 'Update user image',
  'Atualizar logo da organização': 'Update organization logo',
  'Atualizar organização': 'Update organization',
  'Atualizar setor': 'Update sector',
  Avançado: 'Advanced',
  Avançar: 'Advance',
  'Aviso importante': 'Important warning',
  Avulso: 'Detached',
  'Ações do atendimento': 'Service actions',
  Ações: 'Actions',
  Bairro: 'Neighborhood',
  'Baixar PDF': 'Download PDF',
  'Baixar conferência': 'Download conference',
  'Baixar documento': 'Download document',
  Blockchain: 'Blockchain',
  'Buscar por...': 'Search for...',
  'CEP inválido, existe(m) digitos faltando':
    'Invalid zip code, missing digit(s)',
  'CEP inválido, número de digitos ultrapassou o limite':
    'Invalid zip code, number of digits exceeded limit',
  'CEP inválido.': 'Invalid CEP.',
  CEP: 'Zip code',
  CONFERENCE_ALREADY_STARTED: 'Conference has already started',
  CONFERENCE_BAD_RRULE: 'Could not create recurrence for given rrule',
  CONFERENCE_COULD_NOT_LIST: 'Could not list conferences in the database',
  CONFERENCE_FILE_COULD_NOT_DELETE: 'Could not delete conference file',
  CONFERENCE_FILE_NOT_CREATED: 'Could not create conference file',
  CONFERENCE_FILE_NOT_FOUND: 'Could not find conference file',
  CONFERENCE_IS_CANCELED: 'Conference is cancelled or has ended',
  CONFERENCE_LIMIT_REACHED: 'Limit reached. Payment is required',
  CONFERENCE_MEMBERS_USER_NOT_FOUND: "Could not find paticipant\\'s user",
  CONFERENCE_MEMBER_ALREADY: 'User is already a participant',
  CONFERENCE_MEMBER_ALREADY_WITH_ROLE:
    'Participant is already assign to this role',
  CONFERENCE_MEMBER_COULD_NOT_LIST:
    'Could not list participants in the database',
  CONFERENCE_MEMBER_NOT_CREATED:
    'Could not create conference member in database',
  CONFERENCE_MEMBER_NOT_DELETED:
    "Could not delete conference\\'s participant in database",
  CONFERENCE_MEMBER_NOT_FOUND: 'Could not find conference',
  CONFERENCE_NOTE_COULD_NOT_LIST:
    'Could not list conference notes in the database',
  CONFERENCE_NOTE_CREATE_ERROR: 'Could not create conference note',
  CONFERENCE_NOTE_NOT_FOUND: 'Could not find conference note',
  CONFERENCE_NOT_CREATED: 'Could not create conference in database',
  CONFERENCE_NOT_DELETED: 'Could not delete conference in database',
  CONFERENCE_NOT_FOUND: 'Could not find conference',
  CONFERENCE_NOT_STARTED: "Conference hasn\\'t been started by the owner",
  CONFERENCE_OWNER_CANNOT_QUIT: 'Owner cannot quit his own conference',
  CONFERENCE_OWNER_NOT_IN_ORG: 'Owner is not a member of this organization',
  CONFERENCE_RECORDINGS_NOT_FOUND: 'No recordings found for this conference',
  CONFERENCE_RECURRENCE_NOT_DELETED:
    "Could not delete conference's recurrences in database",
  CONFERENCE_RECURRENCE_NOT_FOUND:
    "Could not find conference's recurrences in database",
  CONFERENCE_RECURRENCE_NOT_UPDATED:
    "Could not update conference's recurrence in database",
  CONFERENCE_RECURRENCE_NO_NEW:
    'RRule provided does not create new recurrences. Please provide a valid rrule.',
  CONFERENCE_REGISTRY_NOT_FOUND: 'No dlt registry found for this conference',
  CONFERENCE_SUMMARY_NOT_FOUND: 'Could not find conference summary information',
  CONFERENCE_TRANSCRIPTION_NOT_FOUND:
    'No transcriptions found for this conference',
  CONFERENCE_WAITING_ROOM_DISABLED:
    'Conference does not have waiting room enabled',
  'CPF inválido.': 'Invalid CPF.',
  CPF: 'Hora',
  'Cadastrado em': 'Registered in',
  Cadastrado: 'Registered',
  Cadastrados: 'Registered',
  'Cadastrar novo documento clínico': 'Register new clinical doc',
  'Cadastrar novo organizador': 'Register new organizer',
  'Cadastrar novo participante': 'Register new patient',
  'Cadastrar novo profissional': 'Register new professional',
  'Cadastro de Pessoa Física': 'Individual registration',
  'Cadastro de conferência': 'Conference registration',
  Cadastro: 'Register',
  'Campo obrigatório': 'Required field',
  Cancelado: 'Canceled',
  'Cancelar atendimento': 'Cancel appointment',
  'Cancelar atendimento?': 'Cancel appointment?',
  Cancelar: 'Cancel',
  'Carregando...': 'Loading...',
  'Centro de Atendimento ao cliente': 'Customer Support Center',
  Cep: 'Cep',
  'Certificado Digital': 'Digital Certificate',
  Chat: 'Chat',
  Cidade: 'City',
  'Classificação de organizações': 'Organization ranking',
  'Classificação de usuários': 'User ranking',
  'Clique para ver as notas de release': 'Click to view the release notes',
  Cod: 'Date',
  'Compartilhar por email': 'Share by email',
  Complemento: 'Complement',
  Concluir: 'Finish',
  'Conferência atualizada com sucesso': 'Conference updated successfully',
  'Configurações da organização atualizadas com sucesso!':
    'Organization settings updated successfully!',
  Configurações: 'Settings',
  'Confime sua senha': 'Confirm your password',
  Confirmado: 'Confirmed',
  'Confirmar nova senha': 'Confirm new password',
  Confirmar: 'Confirm',
  'Confirmação de conta': 'Account Confirmation',
  'Confirmação de e-mail': 'Email confirmation',
  'Confirmação do cadastro de usuário': 'Confirmation of user registration',
  'Confirme a senha': 'Confirm your password',
  'Consultante inválido ou não encontrado.': 'Invalid or missing query.',
  'Consumo por dia do mês': 'Consumption by day of the month',
  'Consumo por serviço': 'Consumption per service',
  'Consumo por setor': 'Consumption by sector',
  'Consumo por usuário': 'Consumption per user',
  Consumo: 'Consumption',
  'Conta confirmada com sucesso!': 'Account confirmed successfully!',
  Contato: 'Contact',
  Contatos: 'Contacts',
  Convidado: 'Guest',
  Convidados: 'Guests',
  'Convidar paciente para participar': 'Invite a patient to participate',
  'Convidar profissional externo': 'Invite an extern professional',
  'Copiar link de atendimento': 'Copy service link',
  'Copiar link': 'Copy link',
  Cpf: 'Cpf',
  'Criado em': 'Created at',
  'Criação de Reunião Instantânea': 'Instant Meeting Creation',
  'Criação de organização': 'Organization creation',
  'Criação de reunião': 'Meeting creation',
  'Criação de setor': 'Sector creation',
  'Criação de usuário': 'User creation',
  'Código e Senha': 'Code and Password',
  Código: 'Code',
  'Código:': 'Code:',
  DASHBOARD_COULD_NOT_GET: 'Could not get dashboard',
  'DD/MM/AAAA': 'MM/DD/YYYY',
  DESATIVADA: 'DISABLED',
  DOCUMENT_CREATOR_CREATE:
    'Could not create document in document creator service',
  DOCUMENT_CREATOR_DOWNLOAD:
    'Could not download document in document creator service',
  DOCUMENT_CREATOR_LOGIN_ERROR: 'Could not login in document creator service',
  DOCUMENT_CREATOR_VERIFY:
    'Could not verify document in document creator service',
  'Dados Gerais': 'General Data',
  'Dados Pessoais': 'Personal data',
  'Dados Profissionais': 'Professional data',
  'Dados do paciente': 'Consultant data',
  'Dados do perfil': 'Profile data',
  'Dados do profissional de saúde': 'Health professional data',
  'Dados gerais': 'General data',
  'Dados perfil': 'Profile data',
  'Dados profissionais': 'Professional data',
  'Data de Nascimento': 'Birth date',
  'Data de emissão': 'Issue Date',
  'Data de inclusão': 'Inclusion date',
  'Data de nascimento é obrigatório.': 'Birth date is required.',
  'Data de nascimento': 'Date of birth',
  'Data do Atendimento': 'Date of Service',
  'Data do atendimento': 'Date of service',
  'Data e Hora': 'Date and Time',
  'Data e hora': 'Date and time',
  'Data inválida': 'Invalid date',
  'Data inválida.': 'Invalid Date.',
  Data: 'Status',
  'Deixar a sala': 'Leave the room',
  'Deixar sala': 'Leave room',
  Dentista: 'Dentist',
  Desativado: 'Deactivated',
  'Descrição da reunião': 'Meeting description',
  Descrição: 'Description',
  'Deseja gerar um documento com a transcrição da reunião?':
    'Do you want to generate a document with the meeting transcript?',
  'Deseja realmente cancelar o atendimento: ':
    'Do you wish to cancel the schedule: ',
  'Deseja realmente desvincular esse usuário da organização?':
    'Do you really want to unlink this user from the organization?',
  'Deseja realmente desvincular esse usuário do setor?':
    'Do you really want to unlink this user from the sector?',
  'Deseja realmente excluir a organização':
    'Do you really want to delete the organization?',
  'Deseja realmente excluir a reunião?':
    'Do you really want to delete the meeting?',
  'Deseja realmente excluir o setor?':
    'Do you really want to delete the sector?',
  'Deseja realmente excluir o usuário':
    'Do you really want to delete the user?',
  Desligada: 'Off',
  Desligado: 'Switched of',
  'Desvincular Organizador do Setor': 'Unlink Sector Organizer',
  'Desvinculo de Usuário da organização':
    'Unlinking User from the organization',
  'Desvinculo de usuário do setor': 'Unlink user from sector',
  'Detalhes da auditoria': 'Audit details',
  'Detalhes da conferência': 'Conference details',
  'Detalhes da operação': 'Operation details',
  'Detalhes de organização': 'Organization Details',
  'Detalhes do atendimento': 'Appointment detail',
  'Detalhes do setor': 'Sector details',
  'Detalhes do usuário': 'User details',
  Detalhes: 'Details',
  Dia: 'Day',
  'Digite a confirmação da nova senha': 'Enter new password confirmation',
  'Digite a senha atual': 'Enter current password',
  'Digite a senha da receita': 'Enter the password for the Prescription',
  'Digite apenas letras maiusculas e minúsculas e espaço.':
    'Enter only uppercase and lowercase letters and space.',
  'Digite nova senha': 'Type new password',
  'Digite novamente a nova senha': 'Retype new password',
  'Digite o CPF do administrador': 'Enter an administrator CPF',
  'Digite o CPF do organizador': 'Enter an organizer CPF',
  'Digite o CPF do paciente': 'Enter an patient CPF',
  'Digite o código da receita': 'Enter the prescription code',
  'Digite o código do atendimento': 'Type the service code',
  'Digite o código do paciente': 'Enter an patient code',
  'Digite o e-mail do profissional': "Enter professional's e-mail",
  'Digite o nome de um médico': 'Digite o nome de um médico',
  'Digite o nome de um organizador': 'Enter the organizer name',
  'Digite o nome do administrador': 'Enter the administrator name',
  'Digite o nome do organizador': 'Enter the name of the organizer',
  'Digite o nome do paciente': 'Enter the patient name',
  'Digite o nome do participante': 'Enter the name of the participant',
  'Digite o nome do profissinal': "Enter professional's name",
  'Digite o nome do profissional': 'Enter the professional name',
  'Digite o nome ou e-mail de um participante...':
    'Enter a participant name or email...',
  'Digite o número do seu celular': 'Enter your mobile number',
  'Digite o seu email': 'Type your e-mail',
  'Digite o(s) e-mail(s) para convidar': 'Enter email(s) to invite',
  'Digite o(s) email(s) para convidar': 'Enter email(s) to invite',
  'Digite seu CEP': 'Enter your CEP',
  'Digite seu bairro': 'Enter your neighborhood',
  'Digite seu e-mail': 'Enter your email',
  'Digite seu estado': 'Enter your state',
  'Digite seu nome': 'Insert your name',
  'Digite seu país': 'Enter your country',
  'Digite seu sobrenome': 'Insert your surname',
  'Digite sua Senha': 'Type your password',
  'Digite sua cidade': 'Enter your city',
  'Digite um complemento': 'Enter a complement',
  'Digite uma senha pessoal': 'Enter a password',
  Disponíveis: 'Available',
  Disponível: 'Available',
  'Documento clínico': 'Clinical doc',
  'Documento não enviado': 'Document not sent',
  'Documentos Clínicos': 'Clinical Docs',
  'Documentos clínicos': 'Clinical docs',
  'Documentos de apoio': 'Supporting Documents',
  Domingo: 'Sunday,',
  Dosagem: 'Dosage',
  Droga: 'Drug',
  'Duração média': 'Average duration',
  'Duração total': 'Total duration',
  Duração: 'Duration',
  'E-mail confirmado em': 'Email confirmed at',
  'E-mail do profissional': "Professional's e-mail",
  'E-mail enviado com sucesso!': 'Email successfully sent!',
  'E-mail já cadastrado': 'E-mail already registered',
  'E-mail reenviado com sucesso!': 'Email resent successfully!',
  'E-mail': 'E-mail',
  'Editar especialidade': 'Edit specialty',
  'Editar organização': 'Edit organization',
  'Editar reunião recorrente': 'Edit recurring meeting',
  'Editar reunião': 'Edit meeting',
  'Editar setor': 'Edit sector',
  'Editar usuário': 'Edit user',
  'Edição de conferência': 'Conference editing',
  'Em andamento': 'In progress',
  'Em progresso': 'In progresss',
  'Email inválido.': 'Invalid Email.',
  Email: 'Email',
  Emergency: 'Emergency',
  Endereço: 'Address',
  'Entrar com:': 'Login with:',
  'Entrar na consulta': 'Join the appointment',
  'Entrar na reunião': 'Join the meeting',
  Entrar: 'Enter',
  'Enviar ao Paciente': 'Send To Patient',
  'Enviar convite por Whatsapp': 'Send invite for Whatsapp',
  'Enviar documento por e-mail': 'Send document by e-mail',
  'Enviar e concluir': 'Send and conclude',
  Enviar: 'Send',
  'Erro ao buscar dados da organização': 'Error fetching organization data',
  'Erro ao buscar reunião': 'Error fetching meeting',
  'Especialidade inválida ou não encontrada': 'Invalid or not found specialty',
  Especialidade: 'Specialty',
  'Esqueceu sua senha?': 'Forgot your password?',
  'Esta e todas as seguintes': 'This and all following',
  Estado: 'State',
  Estatísticas: 'Statistics',
  'Este documento clinico será listado como Documento pendente e não será enviado ao paciente até que a assinatura seja finalizada.':
    'This medical document will be listed as a Pending Document and will not be sent to the patient until the signature is finalized.',
  'Este número é whatsapp': 'This number is whatsapp',
  Evento: 'Event',
  'Eventos do atendimento': 'Service events',
  'Excluir Receita': 'Delete Prescription',
  'Excluir conta': 'Delete account',
  'Excluir documento': 'Exclude document',
  'Excluir especialidade': 'Exclude specialty',
  'Excluir reunião recorrente': 'Delete recurring meeting',
  'Excluir usuario': 'Exclude user',
  'Excluir usuário': 'Delete user',
  'Extrato de consumo detalhado': 'Detailed consumption statement',
  FACE_RECOGNITION_API_COULD_NOT_ADD_FILE_TO_FORM: 'Error reading file',
  FACE_RECOGNITION_API_COULD_NOT_CREATE_SUBJECT: 'Could not create subject',
  FACE_RECOGNITION_API_COULD_NOT_GET_SUBJECT: 'Could not get subject',
  FACE_RECOGNITION_API_COULD_NOT_SEND_PICTURE: 'Could not send picture',
  FACE_RECOGNITION_API_COULD_NOT_UPDT_PICTURE: 'Could not update user picture',
  FACE_RECOGNITION_API_COULD_NOT_VERIFY_FACE: "Could not verify user's face",
  FACE_RECOGNITION_API_COULD_NOT_VERIFY_SUBJECT: 'Could not verify subject',
  FACE_RECOGNITION_API_NO_FACE_RECORD:
    'User does not have a facial record attached to the profile',
  FILE_REQUIRED: 'File is required to this action',
  'Falha no servidor, tente novamente mais tarde.':
    'Server failed, please try again later.',
  Faturamento: 'Billing',
  Fechar: 'Close',
  Feminino: 'Woman',
  Fim: 'Type',
  Finalizado: 'Finished',
  'Finalizar atendimento': 'Finish appointment',
  'Formas de convite': 'Invitation methods',
  Forte: 'Strong',
  Fraca: 'Weak',
  Geral: 'All',
  'Gerar documento com a transcrição da reunião': 'Generate meeting transcript',
  Gravadas: 'Recorded',
  Gravado: 'Recorded',
  Gravados: 'Recorded',
  Gravação: 'Recordings',
  'Gravações do atendimento': 'Call recordings',
  Gravações: 'Recordings',
  Grupo: 'Group',
  Gênero: 'Gender',
  HEALTHCHECK_FAILED: 'Health check failed',
  Hoje: 'Today',
  'Hora de início': 'Start time',
  'Hora de ínicio incorreta': 'Incorrect start time',
  'Hora do fim': 'End time',
  'Hora fim': 'End time',
  'Hora final incorreta': 'Incorrect end time',
  'Hora início': 'Start time',
  Hora: 'Full name',
  'Horas de reuniões': 'Hours of meetings',
  'Horas gravadas': 'Recorded hours',
  Horas: 'Hours',
  ID: 'ID',
  Idade: 'Age',
  'Imagem do usuário alterada com sucesso!': 'User image changed successfully!',
  'Informações da reunião': 'Meeting information',
  'Informações do usuário atualizadas com sucesso!':
    'User information successfully updated!',
  'Informações do usuário atualizadas com sucesso':
    'User information successfully updated',
  'Informações gerais': 'General information',
  'Informações históricas': 'Historical information',
  'Iniciar atendimento': 'Start appointment',
  'Insira a UF do seu documento profissional':
    'Enter your professional document state',
  'Insira a data de emissão do seu documento profissional':
    'Enter the emission date of your professional document',
  'Insira a dosagem': 'Enter the dosage',
  'Insira a posologia da medicação': 'Enter the dosage of the medication',
  'Insira o CÓDIGO e SENHA disponíveis na receita do paciente':
    'Insert the CODE and PASSWORD available in the patients prescription',
  'Insira o nome da droga': 'Enter the name of the drug ',
  'Insira o nome da sua cidade.': 'Enter your city name.',
  'Insira o nome do seu bairro.': 'Enter your neighborhood name.',
  'Insira o número de sua residência': 'Enter your home number',
  'Insira o número do seu documento profissional':
    'Enter your professional document number',
  'Insira o seu endereço.': 'Enter your address.',
  'Insira o tempo de afastamento': 'Enter the withdrawal time',
  'Insira seu nome completo.': 'Enter your full name.',
  'Insira uma indicação clínica.': 'Enter a clinical indication.',
  Instituição: 'Institution',
  Início: 'End',
  'Ir para sala de espera': 'Go to waiting room',
  'Já existe um setor com este nome.':
    'There is already a sector with this name.',
  'Já existe uma organização com este nome':
    'There is already an organization with this name',
  LINK_GEN_API_COULD_NOT_CREATE_LINK: 'Could not create link',
  LINK_GEN_API_LOGIN_ERROR: 'Could not login into LinkGenerator system',
  Ligada: 'On',
  Ligado: 'Switched on',
  'Limite de gravações mensais (horas)': 'Monthly recording limit (hours)',
  'Limite de reuniões mensais (horas)': 'Monthly meeting limit (hours)',
  'Limpar todas': 'Clear all',
  'Lista de Documentos clínicos': 'Clinical documents',
  'Lista de Documentos de apoio': 'Supporting documents',
  'Lista de organizações': 'Organization List',
  'Lista de profissionais participantes': 'Pofessionals participating',
  'Lista de usuários': 'User list',
  'Log de atividade': 'Activity log',
  Logados: 'Logged',
  'Login desabilitado com sucesso!': 'Login disabled successfully!',
  'Login habilitado com sucesso!': 'Login enabled successfully!',
  'Login habilitado': 'Login enabled',
  Login: 'Login',
  'Logo da organização alterado com sucesso!':
    'Organization logo changed successfully!',
  Logradouro: 'Street',
  Manutenção: 'Maintenance',
  Masculino: 'Man',
  'Minha conta': 'My account',
  'Minhas reuniões': 'My meetings',
  'Mudança de senha': 'Password change',
  'Mudar senha': 'Change password',
  'Média de consumo diário': 'Average daily consumption',
  'Média de participantes': 'Average participants',
  Médico: 'Medic',
  Médio: 'Medium',
  Mês: 'Month',
  NOTIFICATION_API_CHECK_MESSAGE_EXIST:
    'Could not check if message exist in notification center service',
  NOTIFICATION_API_COULD_NOT_SEND_MESSAGE:
    'Could not send message in notification center service',
  NOTIFICATION_API_COULD_NOT_SEND_MESSAGE_WITH_ATTACHEMENTS:
    'Could not send message with attachments in notification center service',
  NOTIFICATION_API_COULD_NOT_VERIFY_MESSAGE:
    'Could not verify message in notification center service',
  NOTIFICATION_API_LOGIN_ERROR:
    'Could not login in notification center service',
  'Nome Completo': 'Full name',
  'Nome completo': 'Full name',
  'Nome da Organização': 'Organization name',
  'Nome da mãe é obrigatório.': 'Mother name is required.',
  'Nome da mãe': "Mother's name",
  'Nome da organização': 'Organization name',
  'Nome da pessoa de contato': 'Contact name',
  'Nome da sua mãe': 'Name of your mother',
  'Nome do Profissional': "Professional's name",
  'Nome do setor': 'Sector name',
  'Nome do usuário': "User's name",
  Nome: 'Name',
  Notificações: 'Notifications',
  'Nova Organização': 'New Organization',
  'Nova mensagem': 'New message',
  'Nova reunião': 'New meeting',
  'Nova senha': 'New password',
  'Novo Administrador': 'New Admin',
  'Novo Usuário': 'New User',
  'Novo agendamento': 'New appointment',
  'Novo documento clínico': 'New clinical document',
  'Novo documento de apoio': 'New supporting document',
  'Novo documento': 'New document',
  'Novo organizador': 'New organizer',
  'Novo paciente': 'New patient',
  'Novo profissional': 'new professional',
  'Novo setor': 'New sector',
  'Novos usuários': 'New users',
  'Não cancelar': 'Don`t cancel',
  'Não foi possível alterar a senha': 'Unable to change password',
  'Não foi possível alterar senha': 'Unable to change password',
  'Não foi possível atualizar a reunião': 'Failed to update the meeting',
  'Não foi possível atualizar as informações do usuário':
    'Unable to update user information',
  'Não foi possível atualizar configurações da organização':
    'Failed to update organization settings',
  'Não foi possível atualizar imagem do usuário': 'Unable to update user image',
  'Não foi possível atualizar logo da organização':
    'Unable to update organization logo',
  'Não foi possível atualizar o setor.': 'Unable to update sector',
  'Não foi possível atualizar organização': 'Unable to update organization',
  'Não foi possível buscar dados da organização do setor':
    'Unable to get sector organization data',
  'Não foi possível buscar detalhes de administração do usuário':
    'Unable to fetch user administration details',
  'Não foi possível buscar usuário.': 'Could not find user.',
  'Não foi possível cadastrar a organização': 'Unable to register organization',
  'Não foi possível carregar dados do usuário': 'Unable to load user data',
  'Não foi possível carregar o arquivo': 'The file could not be loaded',
  'Não foi possível confirmar conta': 'Unable to confirm account',
  'Não foi possível criar a reunião': 'Failed to create the meeting',
  'Não foi possível criar o setor.': 'Unable to create sector',
  'Não foi possível criar usuário': 'Unable to create user',
  'Não foi possível desassociar o usuário do setor.': 'Unable to unlink user',
  'Não foi possível desativar a câmera.':
    'The camera could not be deactivated.',
  'Não foi possível desvincular o usuário da organização':
    'Unable to unlink user from organization',
  'Não foi possível enviar e-mail de recuperação':
    'Unable to send recovery email',
  'Não foi possível enviar e-mail': 'Unable to send email',
  'Não foi possível reenviar e-mail de confirmação':
    'Unable to resend confirmation email',
  'Não foi possível remover a reunião': 'Failed to remove the meeting',
  'Não foi possível remover a reunião.': 'Could not remove the meeting.',
  'Não foi possível remover setor.': 'Unable to remove sector',
  'Não foi possível remover usuário': 'Unable to remove user',
  'Não gravadas': 'Not recorded',
  'Não gravado': 'Not recorded',
  'Não possui reuniões': 'No meetings',
  'Não é possível deletar organização com usuários ativos':
    'Unable to delete organization with active users',
  Não: 'No',
  Nível: 'Level',
  'Número da identidade': 'Enter your identity number',
  'Número da residência': 'Street number',
  'Número de reuniões': 'Number of meetings',
  'Número inválido, ultrapassou o limite': 'Invalid number, exceeded the limit',
  Número: 'Number',
  'O administrador geral tem permissões irrestritas no sistema. Esta alteração tem implicações no gerenciamento do sistema pois remove permissões de administração deste usuário.':
    'The general administrator has unrestricted permissions on the system. This change has system management implications as it removes administration permissions for this user.',
  'O campo "Título de reunião" é obrigatório.':
    'The "Meeting title" field is required.',
  'O e-mail não está em formato válido': 'Invalid email format',
  'O e-mail é obrigatório': 'Email is required',
  'O moderador precisa entrar na reunião antes de qualquer participante?':
    'Does the moderator need to enter the meeting before any participant?',
  'O médico está pronto': 'The doctor is ready',
  'O médico foi informado que': 'The doctor was informed that',
  'O médico já está na sala de atendimento':
    'The doctor is already in the care room',
  'O nome do setor deve conter no máximo 64 caracteres.':
    'Maximum 64 characters',
  'O nome é obrigatório': 'Name is required',
  'O paciente está pronto': 'The patient is ready',
  'O paciente foi informado que': 'The patient was informed that',
  'O telefone é obrigatório': 'Phone is required',
  OK: 'OK',
  ORGUNIT_CANNOT_DELETE_ACTIVE_USERS:
    'Cannot delete org unit with active users',
  ORGUNIT_COULD_NOT_LIST: 'Could not list org units',
  ORGUNIT_EXPIRED: 'Organization expired',
  ORGUNIT_LIMIT_REACHED: 'Org unit has already reached the units amount limit',
  ORGUNIT_NAME_ALREADY_IN_USE: 'Org unit with this name already exists',
  ORGUNIT_NOT_CREATED: 'Could not create org unit',
  ORGUNIT_NOT_DELETED: 'Could not update org unit',
  ORGUNIT_NOT_FOUND: 'Could not find org unit',
  ORGUNIT_NOT_UPDATED: 'Could not update org unit',
  ORGUNIT_PARENT_NOT_FOUND: "Could not find orgUnit's parent",
  ORGUNIT_SHORT_ALREADY_IN_USE: 'Org unit with this shortname already exists',
  ORGUNIT_USER_LIMIT_REACHED:
    'Org unit has already reached the users amount limit',
  ORG_ADMIN_COULD_NOT_LIST: 'Could not list administrators',
  ORG_ADMIN_NOT_CREATED: 'Could not create org administrator',
  ORG_ADMIN_NOT_DELETED: 'Could not remove org administrator',
  ORG_ADMIN_NOT_FOUND: 'Could not find org administrator',
  ORG_APIKEY_DECRYPT_ERROR:
    'Could not decrypt API key: Missing or invalid API key',
  ORG_CANNOT_DELETE_ACTIVE_USERS: 'Cannot delete org with active users',
  ORG_COULD_NOT_LINK_USER: 'Could not link user to org',
  ORG_COULD_NOT_LIST: 'Could not list orgs',
  ORG_EXPIRED: 'Org expired',
  ORG_LIMIT_REACHED: 'OrgUnit limit reached',
  ORG_NOT_CREATED: 'Could not create org',
  ORG_NOT_DELETED: 'Could not update org',
  ORG_NOT_FOUND: 'Could not find org',
  ORG_NOT_UPDATED: 'Could not update org',
  ORG_SHORT_ALREADY_IN_USE: 'Org shortname already in use',
  ORG_USER_COULD_NOT_LIST: 'Could not list org members',
  ORG_USER_LIMIT_REACHED: 'Org has already reached the users amount limit',
  ORG_USER_NOT_DELETED: 'Could not remove org administrator',
  ORG_USER_NOT_FOUND: 'Could not find org member',
  'Objeto da ação': 'Action object',
  'Ocultar notificações': 'Hide notification',
  Ontem: 'Yesterday',
  'Opção inválida': 'Invalid option',
  Organizador: 'Organizer',
  'Organizadores cadastrados': 'Registered organizers',
  'Organizadores do setor': 'Sector organizers',
  Organizadores: 'Organizers',
  'Organização atualizada com sucesso': 'Organization successfully updated',
  'Organização criada com sucesso': 'Organization successfully created',
  'Organização expira?': 'Does the organization expire?',
  'Organização habilitada até:': 'Organization enabled until:',
  'Organização removida com sucesso': 'Organization successfully removed',
  Organização: 'Organization',
  'Organizações cadastradas': 'Registered organizations',
  Organizações: 'Organizations',
  Origem: 'Specialty',
  Outros: 'Others',
  PARAMETER_COULD_NOT_LIST: 'Could not list system parameters',
  PARAMETER_NOT_FOUND: 'Could not find parameter',
  PARAMETER_VALUE_ALREADY_EXISTS: 'Parameter value already exists',
  PARAMETER_VALUE_COULD_NOT_LIST: 'Could not list parameter values',
  PARAMETER_VALUE_NOT_FOUND: 'Could not find parameter value',
  'Paciente em questão': 'Patient',
  Paciente: 'Patient',
  'Pacientes cadastrados': 'Registered patients',
  Pacientes: 'Patients',
  'Painel de monitoramento': 'Monitoring panel',
  'Painel de reuniões': 'Meeting panel',
  Painel: 'Dashboard',
  Painéis: 'Panels',
  Papel: 'Role',
  'Para reagendar ou alterar dados do atendimento, você precisa editar o atendimento nos campos ao lado.':
    'To reschedule or change appointment data, please edit the information in the fields beside.',
  Participante: 'Participant',
  'Participantes da Consulta': 'Consultation participants',
  Participantes: 'Participants',
  'Participar do atendimento': 'Participate in attendance',
  País: 'Country',
  Pendente: 'Pending',
  'Perfil do usuário': 'User profile',
  Perfil: 'Profile',
  Permissão: 'Professional',
  'Permissões concedidas': 'Permissions Granted',
  'Permite gravação de reuniões?': 'Is meeting recording allowed?',
  Período: 'Period',
  'Pesquisar usuário': 'Search user',
  Pesquisar: 'Search',
  'Política de privacidade': 'Privacy policy',
  Posologia: 'Posology',
  'Prazo para confirmação': 'Deadline for confirmation',
  Prescrição: 'Prescription',
  'Profissionais cadastrados': 'Registered professionals',
  'Profissionais de saúde': 'Health professionals',
  'Profissionais participantes': 'Professionals participanting',
  Profissionais: 'Professionals',
  'Profissional da saúde': 'Health professional',
  'Profissional de saúde': 'Healthcare Professional',
  'Profissional inválido ou não encontrado.':
    'Invalid or not found professional.',
  Profissional: 'Professional',
  Profissão: 'Profession',
  'Pronto para participar?': 'Ready to join?',
  Próximo: 'Next',
  'Quais das reuniões associadas a este evento você deseja editar?':
    'Which of the meetings associated with this event do you want to edit?',
  'Quais das reuniões associadas a este evento você deseja excluir?':
    'Which of the meetings associated with this event do you want to delete?',
  Qualquer: 'Any',
  'Quantidade de reuniões': 'Number of meetings',
  'Quarta-Feira': 'Wednesday,',
  'Quinta-Feira': 'Thursday,',
  'RG inválido, existe(m) digitos faltando':
    'Invalid RG, there are missing digits',
  'RG inválido, número de digitos ultrapassou o limite':
    'Invalid RG, number of digits exceeded the limit',
  'RG inválido.': 'Invalid RG.',
  'Ranking de organizações': 'Organization Ranking',
  'Ranking de usuários': 'User ranking',
  Realizadas: 'Realized',
  'Receita Antimicrobiano': 'Antimicrobial Prescription',
  'Receita Especial': 'Special Prescription',
  'Receita Simples': 'Simple Prescription',
  'Receita simples': 'Simple recipe',
  'Recomendamos que entre com o microfone e câmera ligados.':
    'We recommend that you enter with the microphone and camera turned on.',
  'Recursos Humanos': 'Human resources',
  'Redefinir senha': 'Reset password',
  Redefinir: 'Reset',
  'Redefinição de senha': 'Password reset',
  'Reenviar confirmação': 'Resend confirmation',
  'Reenviar email': 'Resend email',
  'Registrar reunião na blockchain': 'Register meeting in blockchain',
  'Registrar reunião na blockchain:': 'Register meeting in blockchain:',
  'Registro de conselho': 'Origin',
  'Registro no conselho profissional':
    'Registration with the professional council',
  'Remover organização': 'Remove organization',
  'Remover setor': 'Remove sector',
  'Remover usuário': 'Remove user',
  'Remoção de Organização': 'Organization removal',
  'Remoção de Reunião': 'Meeting removal',
  'Remoção de Usuário': 'User removal',
  'Remoção de setor': 'Sector removal',
  'Repita a senha de acesso': 'Repeat password',
  'Repita nova senha': 'Repeat new password',
  'Requisição de Exames': 'Exam requisition',
  'Requisição de exame': 'Exam Request',
  Retorno: 'Return',
  'Reunião Agendada com sucesso!': 'Meeting scheduled successfully!',
  'Reunião excluida com sucesso': 'Meeting deleted successfully',
  'Reunião instantânea': 'Instant meeting',
  Reunião: 'Meeting',
  'Reuniões gravadas': 'Recorded meetings',
  'Reuniões não gravadas': 'Unrecorded meetings',
  'Reuniões transcritas': 'Transcribed meetings',
  Reuniões: 'Meetings',
  Rg: 'Rg',
  'Rua Av. Ladeira...': 'Enter your address',
  SAC_REQUEST_ALREADY_ATTENDED: 'SAC request is already being attended',
  SAC_REQUEST_ALREADY_BELONGS_TO_TOPIC:
    'SAC request already belongs to this topic',
  SAC_REQUEST_ALREADY_FINISHED: 'SAC request already finished',
  SAC_REQUEST_COULD_NOT_LIST: 'Could not list SAC requests',
  SAC_REQUEST_NAME_ALREADY_IN_USE: 'SAC request name already in use',
  SAC_REQUEST_NOT_ATTENDING: 'SAC request is not being attended yet',
  SAC_REQUEST_NOT_CREATED: 'Could not create SAC request',
  SAC_REQUEST_NOT_DELETED: 'Could not delete SAC request',
  SAC_REQUEST_NOT_FOUND: 'SAC request not found',
  SAC_REQUEST_NOT_IN_SCHEDULE:
    'Request cannot be transferred, topic out of schedule',
  SAC_REQUEST_NOT_SPECIALIST: 'User is not a specialist for this topic',
  SAC_REQUEST_NOT_THE_SPECIALIST: 'User is not the specialist for this request',
  SAC_REQUEST_NOT_UPDATED: 'Could not update SAC request',
  SAC_REQUEST_NO_SCHEDULE: 'SAC has no schedule defined',
  SAC_REQUEST_NO_SPECIALIST: 'Sac request does not have specialist',
  SAC_SPECIALIST_NOT_FOUND: 'Could not find sac specialist',
  SAC_TOPIC_COULD_NOT_LIST: 'Could not list SAC topics',
  SAC_TOPIC_NAME_ALREADY_IN_USE: 'SAC topic name already in use',
  SAC_TOPIC_NOT_ACTIVE: 'SAC topic is not active',
  SAC_TOPIC_NOT_CREATED: 'Could not create SAC topic',
  SAC_TOPIC_NOT_DELETED: 'Could not delete SAC topic',
  SAC_TOPIC_NOT_FOUND: 'SAC topic not found',
  SAC_TOPIC_NOT_UPDATED: 'Could not update SAC topic',
  SESSION_MANAGER_BILLING_NOT_FOUND: 'Could not find billing',
  SESSION_MANAGER_COULD_NOT_CREATE_ORG: 'Could not create org',
  SESSION_MANAGER_COULD_NOT_CREATE_ORGUNIT:
    'Could not create org unit in Session Manager',
  SESSION_MANAGER_COULD_NOT_CREATE_ROOM:
    'Could not create room in Session Manager',
  SESSION_MANAGER_COULD_NOT_CREATE_USER:
    'Could not create user in Session Manager',
  SESSION_MANAGER_COULD_NOT_DELETE_ORG: 'Could not delete organization',
  SESSION_MANAGER_COULD_NOT_DELETE_ORGUNIT: 'Could not delete org unit',
  SESSION_MANAGER_COULD_NOT_DELETE_USER: 'Could not delete user',
  SESSION_MANAGER_COULD_NOT_DOWNLOAD_REGISTRY:
    'Could not find download in Session Manager',
  SESSION_MANAGER_COULD_NOT_GET_DASHBOARD:
    'Could not get dashboard information from Session Manager',
  SESSION_MANAGER_COULD_NOT_GET_INFO: 'Could not get information',
  SESSION_MANAGER_COULD_NOT_GET_RECORDING:
    'Could not find recording in Session Manager',
  SESSION_MANAGER_COULD_NOT_GET_REGISTRY:
    'Could not find registry in Session Manager',
  SESSION_MANAGER_COULD_NOT_GET_USER:
    'Could not get user information from Session Manager',
  SESSION_MANAGER_COULD_NOT_JOIN_ROOM: 'Could not join room in Session Manager',
  SESSION_MANAGER_COULD_NOT_LIST_RECORDING:
    'Could not retrieve recordings from Session Manager',
  SESSION_MANAGER_COULD_NOT_OPEN_ROOM: 'Could not open room in Session Manager',
  SESSION_MANAGER_COULD_NOT_UPDATE_ORG: 'Could not update organization',
  SESSION_MANAGER_COULD_NOT_UPDATE_ORGUNIT: 'Could not update org unit',
  SESSION_MANAGER_COULD_NOT_UPDATE_USER: 'Could not update org unit',
  SESSION_MANAGER_COULD_NOT_VERIFY_REGISTRY:
    'Could not verify registry in Session Manager',
  SESSION_MANAGER_ORGUNIT_ALREADY_EXISTS:
    'Org unit with this shortname already exists',
  SESSION_MANAGER_ORG_ALREADY_EXISTS: 'Org with this shortname already exists',
  SESSION_MANAGER_REGISTRY_NOT_FOUND: 'Could not find registry',
  SESSION_MANAGER_USER_ALREADY_EXISTS: 'User with this login already exists',
  SETTING_DYNAMIC_GET: 'The System appears to be missing mandatory settings.',
  SKIN_NAME_ALREADY_IN_USE: 'Skin name already in use',
  SKIN_NOT_CREATED: 'Could not create skin',
  SKIN_NOT_DELETED: 'Could not update skin',
  SKIN_NOT_FOUND: 'Could not find skin',
  SKIN_NOT_UPDATED: 'Could not update skin',
  STORAGE_COULD_NOT_DELETE_FILE: 'Could not delete file from storage service',
  STORAGE_COULD_NOT_GET_URL: 'Could not get file url from storage service',
  STORAGE_COULD_NOT_SEND_FILE: 'Could not send file from storage service',
  SYSTEM_SETTING_NAME_ALREADY_IN_USE: 'SystemSetting name already in use',
  SYSTEM_SETTING_NOT_CREATED: 'Could not create systemSetting',
  SYSTEM_SETTING_NOT_DELETED: 'Could not update systemSetting',
  SYSTEM_SETTING_NOT_FOUND: 'Could not find systemSetting',
  SYSTEM_SETTING_NOT_RESETED: 'Could not reset system settings',
  SYSTEM_SETTING_NOT_UPDATED: 'Could not update systemSetting',
  'Sair do atendimento': 'Exit care',
  Sair: 'Exit',
  'Salvar alterações': 'Save changes',
  Salvar: 'Save',
  'Segunda-Feira': 'Monday,',
  Segurança: 'Security',
  'Selecione a área de atendimento': 'Select the service area',
  'Selecione seu Gênero': 'Choose your gender',
  'Selecione seu certificado digital e finalize a criação do documento agora.':
    'Select your digital certificate and finish creating the document now.',
  'Selecione sua profissão': 'Select your profession',
  'Selecione um Setor': 'Select a Sector',
  'Selecione um certificado': 'Select a certificate',
  'Selecione um paciente': 'Select a patient',
  'Selecione um papel': 'Select a role',
  'Selecione um profissional': 'Select a professional',
  'Selecione um tipo de documento clínico':
    'Select a type of clinical document',
  'Selecione um tipo': 'Select a type',
  'Selecione uma Organização': 'Select an Organization',
  'Selecione uma data disponível': 'Select an available date',
  'Selecione uma data': 'Select a date',
  'Selecione uma especialidade': 'Select a speciality',
  'Selecione uma opção': 'Choose an option',
  Selecione: 'Select',
  'Seleção de papeis': 'Role selection',
  'Sem atividade': 'No activity',
  'Senha alterada com sucesso!': 'Password changed successfully!',
  'Senha atual': 'Current password',
  'Senha de Acesso': 'Access password',
  'Senha de': 'Password of',
  'Senha inválida': 'Invalid password',
  Senha: 'Password',
  'Senha:': 'Password:',
  'Serviços Gerais': 'General Services',
  'Serviços e faturamentos': 'Services and billing',
  Serviços: 'Services',
  'Setor atualizado com sucesso!': 'Sector updated successfully!',
  'Setor criado com sucesso!': 'Sector created successfully',
  'Setor excluído com sucesso!': 'Sector deleted successfully!',
  Setor: 'Sector',
  Setores: 'Sectors',
  'Seu nome completo': 'Enter your full name',
  'Seu primeiro nome': 'Your first name',
  'Seu segundo nome': 'Your second name',
  'Seus agendamentos aparecerão aqui. Não há itens a serem exibidos no momento.':
    'Your appointments will appear here. You do not have any appointment for this date.',
  'Sexta-Feira': 'Friday,',
  Sigla: 'Initials',
  'Sim, cancelar': 'Yes, cancel',
  Sim: 'Yes',
  Simples: 'Basic',
  'Solicite a farmácia que acesse o endereço':
    'Request the pharmacy to access the address',
  State: 'State',
  'Status do atendimento': 'Appointment status',
  Status: 'Status',
  'Sua relação familiar com o paciente':
    'Your parental relationship with the patient',
  Sábado: 'Saturday,',
  TEMPLATE_NOT_FOUND: 'Could not find message template',
  Tamanho: 'size',
  'Tecnologia da Informação': 'Information Technology',
  'Telefone 1': 'Phone 1',
  'Telefone 2': 'Phone 2',
  'Telefone alternativo': 'Backup phone',
  'Telefone celular 1': 'Mobile phone 1',
  'Telefone celular 2': 'Mobile phone 2',
  'Telefone celular': 'Cell phone',
  'Telefone inválido.': 'Invalid Phone.',
  Telefone: 'Phone',
  'Termos de uso': 'Terms of use',
  'Terça-Feira': 'Tuesday,',
  'Tipo de Atendimento': 'Type of service',
  'Tipo de documento': 'Document Type',
  'Tipo de serviço': 'Type of service',
  Tipo: 'Type',
  'Todas as organizações': 'All organizations',
  'Todas as reuniões': 'All meetings',
  'Todos os setores': 'All sectors',
  Total: 'Total',
  'Transcrever reunião': 'Transcribe meeting',
  'Título de reunião': 'Meeting title',
  Título: 'Title',
  'UF inválido, existe(m) caracteres faltando':
    'Invalid UF, there are characters missing',
  'UF inválido, número de caracteres ultrapassados':
    'Invalid UF, number of characters exceeded',
  'UF inválido.': 'Invalid UF.',
  UF: 'UF',
  UNRECOGNIZED_ORDERBY: 'Unrecognized order column',
  'URL copiada para a área de transferência': 'URL copied to clipboard',
  USERACTION_ALREADY_EXISTS: 'UserAction with this name already exists',
  USERACTION_COULD_NOT_LIST: 'Could not list UserActions in the database',
  USERACTION_GROUP_ALREADY_EXISTS:
    'UserActionGroup with this name already exists',
  USERACTION_GROUP_COULD_NOT_LIST:
    'Could not list userActionGroup in the database',
  USERACTION_GROUP_NOT_CREATED: 'Could not create UserActionGroup in database',
  USERACTION_GROUP_NOT_FOUND: 'Could not find UserActionGroup',
  USERACTION_LOG_COULD_NOT_LIST: 'Could not list user logs in the database',
  USERACTION_NOT_CREATED: 'Could not create UserAction in database',
  USERACTION_NOT_FOUND: 'Could not find UserAction',
  USER_ALREADY_AGREED: 'User has already agreed to Terms',
  USER_ALREADY_CONFIRMED: 'Could not list conferences in the database',
  USER_ALREADY_EXISTS: 'User login or email already in use',
  USER_ALREADY_IN_ORG: 'User is already assigned to this org & unit',
  USER_COULD_NOT_FIND_ORG: "Could not find user's organization",
  USER_COULD_NOT_LIST: 'Could not list conferences in the database',
  USER_NOT_CREATED: 'Could not create user',
  USER_NOT_DELETED: 'Could not update user',
  USER_NOT_FOUND: 'Could not find user',
  USER_NOT_IN_ORG: 'User does not exist in this organization',
  USER_NOT_UPDATED: 'Could not update user',
  USER_SUSPENDED: 'User is suspended',
  'Um Link estará disponível após a confirmação do agendamento.':
    'A link will be available after confirming the appointment.',
  'Um e-mail será enviado para o usuário com instruções para alteração de senha. O usuário ainda poderá fazer login com a senha atual até a alteração.':
    'An email will be sent to the user with instructions for changing the password. The user will still be able to log in with the current password until changed.',
  'Um link estará disponível após confirmação do agendamento':
    'A link will be available after booking confirmation',
  'Uma mensagem será enviada para o endereço de e-mail acima para verificação da autenticidade e associação do endereço de e-mail ao usuário.':
    'A message will be sent to the above email address to verify authenticity and associate the email address with the user.',
  'Usuário criado com sucesso!': 'User successfully created!',
  'Usuário criado com sucesso': 'User successfully created',
  'Usuário desassociado com sucesso': 'User disassociated successfully',
  'Usuário desassociado do setor com sucesso!': 'User unlinked from sector!',
  'Usuário não é mais administrador do sistema!':
    'User is no longer a system administrator!',
  'Usuário promovido com sucesso!': 'User promoted with success!',
  'Usuário removido com sucesso': 'User successfully removed',
  Usuário: 'Users',
  'Usuários Cadastrados': 'Registered users',
  'Usuários ativos': 'Active users',
  'Usuários lotados': 'Users assigned',
  'Usuários padrão': 'Default users',
  Usuários: 'Users',
  Utilizados: 'Used',
  'Utilize o código e senha para acessar':
    'Use the code and password to access',
  VALIDATOR_DEFAULT_ISSUE: 'Unknown validation error',
  VALIDATOR_REQUIRED_FIELD: 'Required field is missing',
  VALIDATOR_UNRECOGNIZED_FIELD: 'Unrecognized field',
  'Validador ICP Brasil': 'ICP Brasil Validator',
  'Valor Total': 'Amount',
  Valor: 'Value',
  Vencimento: 'Maturity',
  'Ver QR Code': 'See QR Code',
  'Ver organização': 'View organization',
  'Ver perfil': 'See profile',
  'Ver setor': 'View sector',
  Vigência: 'Validity',
  'Vincular usuário existente': 'Link existing user',
  Visualização: 'Visualization',
  'Você já pode iniciar o atendimento': 'You can now start the service',
  'Você já pode iniciar seu atendimento': 'You can now start your service',
  'Você não pode editar o usuário sem uma organização':
    'You cannot edit the user without an organization',
  'Você não tem nenhum documento associado ao atendimento.':
    'There is no docs associated with the appointment.',
  'Você não tem nenhuma gravação.': 'You do not have any recording.',
  'Você poderá fazer o download da gravação assim que disponível.':
    'You will be able to download the recording as soon as it becomes available.',
  'Você poderá fazer o download do registro assim que estiver disponível.':
    'You will be able to download the record as soon as it is available.',
  'Você precisa concordar com os termos de uso da':
    'You must agree to the terms of use for',
  'Você saiu da reunião': 'You left the meeting',
  'Você será avisado quando o': 'You will be notified when the',
  Voltar: 'Back',
  Vínculo: 'Link',
  WEBSOCKET_API_ERROR: 'Problem to send requests to websocket api',
  'agendou o atendimento': 'appointment scheduled',
  anos: 'years',
  'atendimentos neste dia': 'appointments on this day',
  'atualizou um atendimento': 'updated the appointment',
  'cancelou um atendimento': 'canceled a appointment',
  'clicando em avançar': 'clicking next',
  'confirmou o atendimento': 'confirmed the appointment',
  'criou um atendimento com profissional acompanhante':
    'appointment created with a professional companion',
  'criou um atendimento': 'created a new appointment',
  'deletou um atendimento': 'appointment deleted',
  'entrou na sala de espera': 'join the waiting room',
  'médico estiver pronto': 'doctor is ready',
  'nesta sala de espera': 'in this waiting room',
  'não confirmado': 'not confirmed',
  'não fez login': 'not logged in',
  'para baixar sua receita digital.': 'to download your digital prescription.',
  'para visualizar o arquivo assinado pelo médico':
    'to view the file signed by the doctor',
  'plataforma e também concordar que o atendimento poderá':
    'platform and also agree that the service may',
  reunião: 'meeting',
  'saiu da sala de espera': 'left the waiting room',
  segurança: 'security',
  'sem prazo para confirmação': 'no deadline for confirmation',
  'ser gravado mediante escolha do médico.':
    "`be recorded upon the doctor's choice.`",
  'termos de uso': 'terms of use',
  'termos de uso.': 'terms of use.',
  usado: 'used',
  usuários: 'users',
  'via o QR code do documento': "via the document's QR code",
  'você está na sala de espera': 'you are in the waiting room',
  Ínicio: 'Start',
  Órgão: 'Organ',
  'Última edição': 'Last edition',
  'Últimas conversas': 'Last conversations',
  'Último acesso': 'Last access',
  'Último login em': 'Last login on',
  'Últimos 12 meses': 'Last 12 months',
  'Últimos 30 dias': 'Last 30 days',
  'Últimos 7 dias': 'Last 7 days',
  'Últimos 90 dias': 'Last 90 days',
};

export default enUS;
