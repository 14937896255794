import { DeleteTopic as UsecaseRemoteDeleteTopic } from 'domain/usecases/sacTopic/remote';
import { makeApiUrl, makeHttpClient } from 'main/factories/http';

import { RemoteDeleteTopic } from 'data/repository/sacTopic';

/**
 * send request via API.
 */
export const makeRemoteDeleteTopic = (): UsecaseRemoteDeleteTopic =>
  new RemoteDeleteTopic(makeApiUrl('/sac/topics'), makeHttpClient());
