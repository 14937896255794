/* eslint-disable spaced-comment */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { availableAttendantSchema } from 'validation/sac/AvailableAttendantFormValidator';
import { NotAttendant } from 'presentation/base/icons';
import {
  Input,
  Textarea,
  Button,
  Typography,
} from '@wisecare-tech/design-system-web';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { closeModal } from 'utils/closeModal';
import { GetPublicTopicById } from 'domain/usecases/sacTopic/remote';
import { toast } from 'react-toastify';
import { makeRemoteGetPublicTopicById } from 'main/factories/usecases/sacTopic/GetPublicTopicsByIdFactory';
import { makeRemoteCreateSacRequest } from 'main/factories/usecases/sacRequest/CreateSacRequestFactory';
import { addedMaskToPhones } from 'utils/addedMaskToPhones';
import { format } from 'date-fns';
import { History } from 'main/routes';
import { ScheduleList } from './ScheduleList';
import {
  Container,
  Subject,
  SubjectAndHour,
  Title,
  SubTitle,
  Form,
  InlineForms,
  ContainerForm,
  FormButton,
  NoAttenddantContainer,
  EmptyAttendant,
  IconNoAttendant,
  NotAttendantInfo,
  PhonesContainer,
  Contacts,
  ContactTitle,
  PhoneNumber,
  EmailsContainer,
  Email,
} from './styles/StyledAvailableAttendantForm';

type iForm = {
  name: string;
  docValue: string;
  email: string;
  phone: string;
  descr: string;
};

export const AvailableAttendantForm: React.FC = () => {
  const [isAvailable, setIsAvailable] = useState(false);
  const [publicTopics, setPublicTopics] = useState<GetPublicTopicById.Model>();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm<iForm>({
    resolver: yupResolver(availableAttendantSchema) as any,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const onSubmit = (data: iForm) => {
    makeRemoteCreateSacRequest()
      .create({
        descr: data?.descr,
        docValue: data?.docValue,
        email: data?.email,
        name: data?.name,
        phone: `+55${data?.phone}`,
        docType: 'CPF',
        topic: Number(publicTopics?.id),
        token:
          new URLSearchParams(window.location.search).get('token') ?? undefined,
      })
      .then(response => {
        toast.success('Solicitação enviada com sucesso.');
        reset();
        resetFields();
        localStorage.setItem('sacToken', response.websocketToken);
        History.getHistory().push(
          `/sac/waitingRoom/${response.id}?token=${new URLSearchParams(
            window.location.search,
          ).get('token')}&u=${data?.name}`,
          {
            sacToken: response.websocketToken,
            request: response.id,
          },
        );
      })
      .catch(() => {
        toast.error('Erro ao enviar solicitação.');
      });
  };

  const resetFields = () => {
    setValue('name', '');
    setValue('docValue', '');
    setValue('email', '');
    setValue('phone', '');
    setValue('descr', '');
  };

  const handleChooseSubject = () => {
    makeReduxActiveMessage().active({
      active: 'chooseSubject',
      actionCancel: () => closeModal(),
      actionOk: () => closeModal(),
    });
  };

  const topicId = useParams<{ id: string }>();

  const fetchTopicById = async () => {
    try {
      const response = await makeRemoteGetPublicTopicById().getById({
        topic: Number(topicId.id),
        token:
          new URLSearchParams(window.location.search).get('token') ?? undefined,
      });
      setPublicTopics(response);
    } catch {
      toast.error('Erro ao carregar o tópico.');
    }
  };

  const verifyHourAttendant = (
    schedule: GetPublicTopicById.Model['schedule'],
  ) => {
    const dayOfWeek = format(new Date(), 'EEEEEE').toUpperCase();
    const hour = format(new Date(), 'HH:mm');

    const compareDayOfWeek = schedule?.find(item => item.day === dayOfWeek);

    if (compareDayOfWeek) {
      const time = compareDayOfWeek.hours?.find(
        item => item.start <= hour && item.finish >= hour,
      );

      if (time) {
        return true;
      }
      return false;
    }

    return false;
  };

  useEffect(() => {
    fetchTopicById();
  }, [topicId]);

  useEffect(() => {
    //TODO: Olhar depois porque não renderiza no horário

    const checkAvailability = () => {
      const available = verifyHourAttendant(publicTopics?.schedule ?? []);
      setIsAvailable(available);
    };

    checkAvailability();

    const intervalId = setInterval(checkAvailability, 60000);

    return () => clearInterval(intervalId);
  }, [publicTopics?.schedule]);

  return (
    <Container>
      <Subject>
        <SubjectAndHour>
          <Title>{publicTopics?.title}</Title>
          <SubTitle>
            <ScheduleList schedule={publicTopics?.schedule ?? []} />
          </SubTitle>
        </SubjectAndHour>
        <Button variant="text" onClick={handleChooseSubject}>
          Mudar assunto
        </Button>
      </Subject>
      <ContainerForm>
        {isAvailable ? (
          <>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <InlineForms>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <Input
                      label="Nome completo"
                      placeholder="Digite o nome completo"
                      status={errors?.name ? 'error' : 'default'}
                      helperText={errors?.name?.message}
                      style={{ width: '526px' }}
                      required
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="docValue"
                  render={({ field }) => (
                    <Input
                      placeholder="
                        Digite apenas números"
                      label="CPF"
                      status={errors?.docValue ? 'error' : 'default'}
                      helperText={errors?.docValue?.message}
                      required
                      {...field}
                    />
                  )}
                />
              </InlineForms>
              <InlineForms>
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <Input
                      label="E-mail"
                      required
                      status={errors?.email ? 'error' : 'default'}
                      helperText={errors?.email?.message}
                      placeholder="usuario@email.com"
                      style={{ width: '526px' }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="phone"
                  render={({ field }) => (
                    <Input
                      label="Telefone"
                      required
                      status={errors?.phone ? 'error' : 'default'}
                      helperText={errors?.phone?.message}
                      placeholder="Digite apenas números"
                      {...field}
                    />
                  )}
                />
              </InlineForms>
              <InlineForms>
                <Controller
                  control={control}
                  name="descr"
                  render={({ field }) => (
                    <Textarea
                      placeholder="Escreva aqui."
                      {...field}
                      label="Descrição da situação"
                      helperText={errors?.descr?.message}
                      status={errors?.descr ? 'error' : 'default'}
                      required
                    />
                  )}
                />
              </InlineForms>
            </Form>
            <FormButton>
              <Button
                variant="primary"
                size="md"
                type="submit"
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid || !isDirty}
              >
                <Typography variant="t4_16medium">
                  Enviar solicitação
                </Typography>
              </Button>
            </FormButton>
          </>
        ) : (
          <NoAttenddantContainer>
            <EmptyAttendant>
              <IconNoAttendant>
                <NotAttendant />
              </IconNoAttendant>
              <NotAttendantInfo>
                <Title style={{ fontSize: '16px' }}>
                  Não há atendentes disponíveis no momento
                </Title>
                <SubTitle style={{ fontSize: '16px' }}>
                  Verifique o horário de atendimento do setor.
                </SubTitle>
              </NotAttendantInfo>
            </EmptyAttendant>
            <PhonesContainer>
              <Contacts>
                <ContactTitle>
                  Entre em contato pelos telefones abaixo:
                </ContactTitle>
                <PhoneNumber>
                  {addedMaskToPhones(publicTopics?.contactPhones)}
                </PhoneNumber>
              </Contacts>
              <EmailsContainer>
                <ContactTitle>E-mail de suporte disponível:</ContactTitle>
                <Email>{publicTopics?.contactEmail}</Email>
              </EmailsContainer>
            </PhonesContainer>
          </NoAttenddantContainer>
        )}
      </ContainerForm>
    </Container>
  );
};
