import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import { BadRequestError, Forbidden, UnexpectedError } from 'domain/errors';
import { DeleteTopic } from 'domain/usecases/sacTopic/remote';
import 'infra/global/variables';

export class RemoteDeleteTopic implements DeleteTopic {
  private readonly url: string;

  private readonly httpClient: HttpClient<DeleteTopic.Model>;

  constructor(url: string, httClient: HttpClient<DeleteTopic.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  delete = async (params: DeleteTopic.Params): Promise<DeleteTopic.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.id}`,
      method: 'delete',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
