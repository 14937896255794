import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { JoinSacSpecialist as UsecaseRemoteJoinSacSpecialist } from 'domain/usecases/sacSpecialist/remote';
// import { JoinSacSpecialist as UsecaseReduxJoinSacSpecialist } from 'domain/usecases/sacSpecialist/redux';

import { RemoteJoinSacSpecialist } from 'data/repository/sacSpecialist';
// import { ReduxJoinSacSpecialist } from 'data/store/reducer/sacSpecialist/usecases';

/**
 * send request via API.
 */
export const makeRemoteJoinSacSpecialist = (): UsecaseRemoteJoinSacSpecialist =>
  new RemoteJoinSacSpecialist(
    makeApiUrl('/sac/specialists/{userId}/JOIN'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxJoinSacSpecialist =
  (): UsecaseReduxJoinSacSpecialist =>
    new ReduxJoinSacSpecialist(); */
