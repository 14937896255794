import { MakeAdminFactory, MakeUsersFactory } from 'main/factories/pages/admin';
import {
  MakeAppointmentFactory,
  MakeEditAppointmentFactory,
  MakeNewAppointmentFactory,
} from 'main/factories/pages/appointment';
import {
  MakeDashboardFactory,
  MakeDashboardUserFactory,
} from 'main/factories/pages/dashboard';
import React from 'react';
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';

import {
  MakeForgotPasswordFactory,
  MakeRecoveryPasswordFactory,
} from 'main/factories/pages/password';

import { MakeConfFactory, MakePreConfFactory } from 'main/factories/pages/conf';
import { HelpLine } from 'presentation/components/modalsSAC/HelpLine/HelpLine';
import { AttendantFormPage, SelectSubjectPage } from 'presentation/pages';
import SectorDetails from 'presentation/pages/Admin/Sectors/SectorDetails';
import EndConf from 'presentation/pages/EndConf';
import { MakeConfirmMeetFactory } from '../factories/pages/confirm/ConfirmMeetFactory';
import { MakeCreateOrgFactory } from '../factories/pages/createOrg/CreateOrgFactory';
import { MakeCreateOrgUnitFactory } from '../factories/pages/createOrgUnit/CreateOrgUnitFactory';
import { MakeCreateUserFactory } from '../factories/pages/createUser/CreateUserFactory';
import { MakeJoinConferenceFactory } from '../factories/pages/join/JoinConferenceFactory';

import { History } from '.';
import { MakeAuditFactory } from '../factories/pages/admin/AuditFactory';
import { MakeAtendeFacilFactory } from '../factories/pages/AtendeFacil';
import { MakeMyConferencesFactory } from '../factories/pages/conf/MyConferencesFactory';
import { MakeLoginFactory } from '../factories/pages/login';
import { MakeRankingFactory } from '../factories/pages/ranking/rankingOrg';
import { MakeRegisterFactory } from '../factories/pages/register';
import { MakeSacWaitingRoomFactory } from '../factories/pages/sac';
import { CreateSacTopicFactory } from '../factories/pages/sac/createSacTopic';
import { SacListHotlinesFactory } from '../factories/pages/sac/sacListHotlines';
import { SacConfigFactory } from '../factories/pages/sacConfig';
import { MakeModalTestFactory } from '../factories/pages/test';
import { MakeWaitingRoomFactory } from '../factories/pages/waitingRoom';
import PrivateRoute from './PrivateRoute';
import MakeSacLeaveFactory from '../factories/pages/sac/SacLeaveFactory';

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Router history={History.getHistory()}>
        <Switch>
          <Route exact path="/" component={MakeLoginFactory} />
          <Route exact path="/register" component={MakeRegisterFactory} />
          <Route exact path="/leave" component={EndConf} />

          <PrivateRoute exact path="/home" component={MakeAppointmentFactory} />

          <Route exact path="/waitingRoom" component={MakeWaitingRoomFactory} />

          <PrivateRoute
            exact
            path="/sectors/details/:id"
            component={SectorDetails}
          />

          <PrivateRoute
            exact
            path="/appointment/create"
            component={MakeNewAppointmentFactory}
          />

          <PrivateRoute
            exact
            path="/appointment/edit/:id"
            component={MakeEditAppointmentFactory}
          />

          <PrivateRoute
            exact
            path="/dashboard/monitoring"
            component={MakeDashboardFactory}
          />

          <PrivateRoute
            exact
            path="/dashboard/org"
            component={MakeRankingFactory}
          />
          <PrivateRoute
            exact
            path="/dashboard/user"
            component={MakeDashboardUserFactory}
          />

          <PrivateRoute
            exact
            path="/dashboard/org"
            component={MakeRankingFactory}
          />

          <PrivateRoute exact path="/admin/orgs" component={MakeAdminFactory} />

          <PrivateRoute
            exact
            path="/admin/orgs/:id"
            component={MakeAdminFactory}
          />

          <PrivateRoute
            exact
            path="/admin/orgs/:id/unit/:orgUnitId"
            component={MakeAdminFactory}
          />

          <PrivateRoute
            exact
            path="/admin/users/:id"
            component={MakeUsersFactory}
          />

          <PrivateRoute
            exact
            path="/admin/users"
            component={MakeUsersFactory}
          />

          <PrivateRoute
            exact
            path="/admin/audit"
            component={MakeAuditFactory}
          />

          <PrivateRoute
            exact
            path="/admin/user/create"
            component={MakeCreateUserFactory}
          />

          <PrivateRoute
            exact
            path="/admin/orgs/create/new"
            component={MakeCreateOrgFactory}
          />

          <PrivateRoute
            exact
            path="/admin/orgs/:id/unit/create/new"
            component={MakeCreateOrgUnitFactory}
          />

          <Route
            exact
            path="/pwd/reset"
            component={MakeRecoveryPasswordFactory}
          />

          <Route
            exact
            path="/pwd/forgot"
            component={MakeForgotPasswordFactory}
          />

          <Route
            exact
            path="/confirm/account"
            component={MakeRecoveryPasswordFactory}
          />

          <Route
            exact
            path="/confirm/meet"
            component={MakeConfirmMeetFactory}
          />

          <Route exact path="/preconf" component={MakePreConfFactory} />

          <Route exact path="/appointment/conf" component={MakeConfFactory} />

          <Route exact path="/join" component={MakeJoinConferenceFactory} />

          <Route exact path="/modals/:id" component={MakeModalTestFactory} />

          <Route path="/sac/subject" component={SelectSubjectPage} />
          <Route path="/sac/attendant/:id" component={AttendantFormPage} />

          <Route path="/support" component={HelpLine} />

          <PrivateRoute
            exact
            path="/appointment/me"
            component={MakeMyConferencesFactory}
          />

          <Route exact path="/sac" component={SacListHotlinesFactory} />
          <Route exact path="/sac/:id/settings" component={SacConfigFactory} />
          <Route
            exact
            path="/sac/:id/create/topic"
            component={CreateSacTopicFactory}
          />

          <Route
            exact
            path="/sac/waitingRoom/:id"
            component={MakeSacWaitingRoomFactory}
          />

          <Route exact path="/sac/leave" component={MakeSacLeaveFactory} />

          <PrivateRoute
            exact
            path="/sac/list"
            component={MakeAtendeFacilFactory}
          />

          <Route
            exact
            path="/Error"
            component={() => <div>Erro no login</div>}
          />

          <Route exact path="/:language" component={MakeAppointmentFactory} />
        </Switch>
      </Router>
    </BrowserRouter>
  );
};

export default Routes;
