import React from 'react';
import { makeRemoteLeftSacRequest } from 'main/factories/usecases/sacRequest/LeftSacRequestFactory';
import { History } from 'main/routes';
import { toast } from 'react-toastify';
import { disconnectSocket } from 'infra/socket/SackSocket';
import {
  Container,
  Description,
  DescriptionDiv,
  Header,
  Message,
  MessageDiv,
  Subtitle,
  Title,
  UserNameDiv,
} from './styles/StyledSacWaitingRoomInfoCard';
import { Button } from '../UI';
import { format } from 'date-fns';

interface ownProps {
  request: number;
}

const SacWaitingRoomInfoCard: React.FC<ownProps> = ({ request }) => {
  const topicTitle = localStorage.getItem('@agenda2/sacTopic');

  const now = new Date();

  return (
    <Container>
      <Header>
        <Title>{topicTitle ?? 'Licenciamento'}</Title>
      </Header>
      <DescriptionDiv>
        <MessageDiv>
          <Message>
            <div className="message">
              Seja bem-vindo à sala de espera. Seu atendimento começará em breve
              e você será notificado quando chegar a sua vez.
            </div>
            <div className="info">
              Mensagem automática - {format(now, 'HH:mm')}
            </div>
          </Message>
          <Message>
            <div className="message">
              Estamos aguardando um atendente disponível atender a sua
              solicitação. Fique atento.
            </div>
            <div className="info">
              Mensagem automática - {format(now, 'HH:mm')}
            </div>
          </Message>
        </MessageDiv>
      </DescriptionDiv>
      <UserNameDiv>
        <Button
          variant="primary"
          type="button"
          onClick={() => {
            makeRemoteLeftSacRequest()
              .left({
                request,
                token:
                  new URLSearchParams(window.location.search).get('token') ||
                  '',
              })
              .then(() => {
                toast.success('Cancelamento efetuado com sucesso.');
                History.getHistory().push(
                  `/sac/subject?token=${new URLSearchParams(
                    window.location.search,
                  ).get('token')}`,
                );
              })
              .catch(() => {
                toast.error('Erro ao cancelar solicitação.');
              })
              .finally(() => {
                disconnectSocket();
              });
          }}
        >
          Cancelar solicitação
        </Button>
      </UserNameDiv>
    </Container>
  );
};

export default SacWaitingRoomInfoCard;
