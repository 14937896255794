import { CreateSacConfig as UsecaseRemoteCreateSacConfig } from 'domain/usecases/sacConfig/remote';
import { makeApiUrl, makeHttpClient } from 'main/factories/http';

import { RemoteCreateSacConfig } from 'data/repository/sacConfig';

/**
 * send request via API.
 */
export const makeRemoteCreateSacConfig = (): UsecaseRemoteCreateSacConfig =>
  new RemoteCreateSacConfig(makeApiUrl('/sac'), makeHttpClient());
