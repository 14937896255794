import { GetTopics as UsecaseRemoteGetTopics } from 'domain/usecases/sacTopic/remote';
import { makeApiUrl, makeHttpClient } from 'main/factories/http';

import { RemoteGetTopics } from 'data/repository/sacTopic';

/**
 * send request via API.
 */
export const makeRemoteGetTopics = (): UsecaseRemoteGetTopics =>
  new RemoteGetTopics(makeApiUrl('/sac/topics/list'), makeHttpClient());
