/* eslint-disable react/no-array-index-key */
import React from 'react';

import {
  Button,
  Chips,
  IconButton,
  Input,
  RadioGroup,
  RadioGroupItem,
  Typography,
} from '@wisecare-tech/design-system-web';
import {
  daysMap,
  daysOfWeek,
  useTopicConfig,
} from 'presentation/hooks/sac/createTopicConfig';
import { DaysAbbreviation } from 'presentation/hooks/sac/types';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateTopicConfigSchema } from 'validation/sac/createTopConfig';
import {
  Box,
  ChipsContainer,
  Content,
  CustomScheduleContainer,
  PhoneNumbersContainer,
  Row,
  ScheduleContainer,
  Section,
  SectionColumn,
  SectionRow,
  SectionsContainer,
} from './styles';

export const CreateTopicConfigStepOne: React.FC = () => {
  const {
    scheduleType,
    scheduleDays,
    removePhone,
    handleScheduleType,
    handleSelectDay,
    setStartFinish,
    createNewHour,
    removeHour,
    createNewPhone,
  } = useTopicConfig();

  const { register, watch, control } =
    useFormContext<CreateTopicConfigSchema>();

  const phones = watch('contactPhones') ?? [];

  return (
    <SectionsContainer>
      <Content>
        <Section>
          <Typography variant="t3_16semibold">Dados gerais</Typography>
          <Box>
            <Input
              label="Assunto"
              placeholder="Digite o assunto"
              {...register('title')}
            />
            <Input
              label="E-mail"
              placeholder="Digite aqui o e-mail do suporte"
              {...register('contactEmail')}
            />
          </Box>
          <PhoneNumbersContainer>
            <SectionRow>
              {phones.map((phone, index) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <Input
                    id={`phone-${index}`}
                    key={`phone-${index}`}
                    label="Telefone"
                    placeholder="(xx) xxxxx-xxxx"
                    {...register(`contactPhones.${index}`, {
                      pattern: {
                        value: /^\(\d{2}\)\s\d{5}-\d{4}$/,
                        message: 'Telefone inválido',
                      },
                    })}
                  />
                  {phones.length > 1 && (
                    <IconButton
                      variant="primary"
                      icon="delete"
                      type="button"
                      onClick={() => removePhone(index)}
                    />
                  )}
                </div>
              ))}
            </SectionRow>
            <Button
              type="button"
              variant="text"
              size="md"
              icon="add"
              onClick={createNewPhone}
            >
              Adicionar telefone
            </Button>
          </PhoneNumbersContainer>
        </Section>
        <Section>
          <Typography variant="t3_16semibold">Horário</Typography>
          <SectionColumn>
            <Typography variant="t4_16medium">
              Selecione os dias disponíveis para atendimento
            </Typography>
            <ChipsContainer>
              {daysOfWeek.map(day => (
                <Controller
                  control={control as any}
                  name="schedule"
                  render={({ field }) => (
                    <Chips
                      type="button"
                      text={daysMap[day.label].abbreviate}
                      onClick={() => handleSelectDay(day)}
                      variant={
                        scheduleDays.filter(
                          scheduleDay => scheduleDay.day === day.label,
                        )?.length > 0
                          ? 'blue'
                          : 'white'
                      }
                      {...field}
                    />
                  )}
                />
              ))}
            </ChipsContainer>
          </SectionColumn>
          <RadioGroup
            {...register('config.scheduleType')}
            defaultValue={scheduleType}
            onValueChange={value =>
              handleScheduleType(value as 'FIXED' | 'FLEXIBLE')
            }
          >
            <RadioGroupItem value="FIXED" disabled={scheduleDays?.length === 0}>
              <Typography variant="b1_16regular">
                Definir horário padrão para os dias da semana
              </Typography>
            </RadioGroupItem>
            {scheduleType === 'FIXED' && scheduleDays?.length > 0 && (
              <ScheduleContainer>
                <CustomScheduleContainer>
                  <Typography variant="b3_14medium">Horário</Typography>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '0.5rem',
                      alignItems: 'flex-start',
                      padding: '0.5rem 0',
                    }}
                  >
                    {scheduleDays &&
                      scheduleDays?.[0].hours?.map((_, indexHour) => (
                        <>
                          <Row>
                            <Input
                              about="Horário de atendimento padrão para os dias da semana"
                              type="time"
                              value={_.start}
                              onChange={e =>
                                setStartFinish(
                                  e.target.value,
                                  scheduleDays?.[0]?.day as DaysAbbreviation,
                                  'start',
                                  indexHour,
                                )
                              }
                            />
                            <Typography variant="b4_14regular">até</Typography>
                            <Input
                              about="Horário de atendimento padrão para os dias da semana"
                              type="time"
                              value={_.finish}
                              onChange={e =>
                                setStartFinish(
                                  e.target.value,
                                  scheduleDays?.[0]?.day as DaysAbbreviation,
                                  'finish',
                                  indexHour,
                                )
                              }
                            />
                            {scheduleDays?.[0]?.hours?.length > 1 && (
                              <IconButton
                                variant="primary"
                                icon="delete"
                                type="button"
                                onClick={() => removeHour('MO', indexHour)}
                              />
                            )}
                          </Row>
                        </>
                      ))}
                    {scheduleDays?.[0]?.hours?.length < 3 && (
                      <Button
                        type="button"
                        variant="text"
                        icon="add"
                        onClick={() => createNewHour('MO')}
                      >
                        Adicionar mais horários
                      </Button>
                    )}
                  </div>
                </CustomScheduleContainer>
              </ScheduleContainer>
            )}
            <RadioGroupItem
              value="FLEXIBLE"
              disabled={scheduleDays?.length === 0}
            >
              <Typography variant="b1_16regular">
                Definir horários personalizados
              </Typography>
            </RadioGroupItem>
          </RadioGroup>

          {scheduleType === 'FLEXIBLE' &&
            scheduleDays
              .sort(
                (a, b) =>
                  daysMap[a.day as DaysAbbreviation].value -
                  daysMap[b.day as DaysAbbreviation].value,
              )
              .map(day => (
                <ScheduleContainer>
                  <Typography variant="t4_16medium">
                    {daysMap[day.day as DaysAbbreviation].fullLabel}
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '0.5rem',
                      alignItems: 'flex-start',
                      padding: '0.5rem 0',
                    }}
                  >
                    {day?.hours?.map((_, index) => (
                      <CustomScheduleContainer>
                        <Row>
                          <Input
                            about="Horário de atendimento padrão para os dias da semana"
                            type="time"
                            value={_.start}
                            onChange={e =>
                              setStartFinish(
                                e.target.value,
                                day.day as DaysAbbreviation,
                                'start',
                                index,
                              )
                            }
                          />
                          <Typography variant="b4_14regular">até</Typography>
                          <Input
                            about="Horário de atendimento padrão para os dias da semana"
                            type="time"
                            value={_.finish}
                            onChange={e =>
                              setStartFinish(
                                e.target.value,
                                day.day as DaysAbbreviation,
                                'finish',
                                index,
                              )
                            }
                          />
                          {day.hours?.length > 1 && (
                            <IconButton
                              variant="primary"
                              icon="delete"
                              type="button"
                              onClick={() =>
                                removeHour(day.day as DaysAbbreviation, index)
                              }
                            />
                          )}
                        </Row>
                      </CustomScheduleContainer>
                    ))}
                    {day.hours?.length < 3 && (
                      <Button
                        type="button"
                        variant="text"
                        icon="add"
                        onClick={() =>
                          createNewHour(day.day as DaysAbbreviation)
                        }
                      >
                        Adicionar mais horários
                      </Button>
                    )}
                  </div>
                </ScheduleContainer>
              ))}
        </Section>
      </Content>
    </SectionsContainer>
  );
};
