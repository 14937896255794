import React, { useEffect } from 'react';
import SacWaitingRoom from 'presentation/pages/SacWaitingRoom';
import io from 'socket.io-client';
import { useLocation } from 'react-router';
import { disconnectSocket, initializeSocket } from 'infra/socket/SackSocket';
import { makeReduxActiveMessage } from '../../usecases/message/Update';

interface iState {
  sacToken: string;
  request: number;
}

const MakeSacWaitingRoomFactory: React.FC = () => {
  const { sacToken, request } = useLocation<iState>().state;

  useEffect(() => {
    initializeSocket(request);
  }, [sacToken, request]);
  return <SacWaitingRoom />;
};

export default MakeSacWaitingRoomFactory;
