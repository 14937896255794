import { theme } from '@wisecare-tech/design-system-web';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;

  background-color: ${theme.colors.white.DEFAULT};

  @media screen and (min-width: 1366px) {
    max-width: 886px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media screen and (min-width: 1366px) {
    flex-direction: row;
  }
`;

export const SacForm = styled.form`
  height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SectionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  flex-direction: column;
  padding-inline: 1.5rem;
  padding-bottom: 8rem;

  background-color: ${theme.colors.white[3]};

  @media screen and (min-width: 1366px) {
    align-items: center;
  }
`;

export const SectionRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;

  @media screen and (min-width: 1366px) {
    & > * {
      max-width: 212px;
    }
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const SectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

export const ChipsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 1.5rem;
  padding: 1.5rem 1rem;
  background-color: ${theme.colors.white.DEFAULT};

  border: 1px solid ${theme.colors.white[6]};

  &:only-child {
    border-radius: 0.5rem;
  }

  &:not(:is(:last-child, :first-child)) {
    border-top: none;
  }

  &:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  &:last-child {
    border-end-start-radius: 0.5rem;
    border-end-end-radius: 0.5rem;
    border-top: none;
  }
`;

export const PhoneNumbersContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.25rem;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  position: fixed;
  bottom: 0;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem 1.5rem;
  background-color: ${theme.colors.white.DEFAULT};
  border-top: 1px solid ${theme.colors.white[6]};
`;

export const FormButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
`;

export const CustomScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;

  &:not(:last-child) {
    margin-right: 1.5rem;
  }
`;

export const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  padding-bottom: 1.5rem;

  @media screen and (min-width: 1366px) {
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;

    border-bottom: 1px solid ${theme.colors.white[6]};
    margin-bottom: 1.5rem;
    &:is(:last-child, :only-child, :only-of-type) {
      border-bottom: none;
      margin-bottom: 0;
    }

    & > text {
      flex: 1;
      max-width: 5rem;
    }
  }
`;

export const StepContainer = styled(SectionsContainer)`
  display: flex;
  height: auto;
  width: 100%;
  gap: 1.5rem;

  padding-bottom: 1.5rem;

  & > div {
    flex: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dedede;
  background-color: ${theme.colors.white.DEFAULT};
`;

export const Header = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid #dedede;

  @media (max-width: 412px) {
    padding: 16px;
  }
`;

export const ContentAttendant = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 16px;
  padding: 24px;

  background-color: ${theme.colors.white.DEFAULT};

  @media (max-width: 412px) {
    padding: 24px 16px 13px 16px;
  }
`;

export const NoAttendants = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 3rem;

  @media (max-width: 412px) {
    height: 320px;
    max-height: 320px;
  }
`;

export const NoAttendantsRadius = styled.div`
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 104px;
  height: 104px;
  border: 1px solid #dedede;
  margin-bottom: 24px;

  @media (max-width: 412px) {
    width: 64px;
    height: 64px;
  }
`;

export const NoAttendantsText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 8px;

  @media (max-width: 412px) {
    width: 100%;
  }
`;

export const AttendantsContainer = styled.ul`
  border: 1px solid #dedede;
  border-radius: 8px;
  height: 336px;
  max-height: 336px;
  overflow: auto;
  list-style: none;
`;

export const AttendantsItem = styled.li`
  width: 100%;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dedede;
`;

export const Name = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Right = styled.div`
  gap: 16px;
  display: flex;
`;
